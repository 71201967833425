import React, { useEffect, useRef, useState } from 'react'
import Select from "react-select"
import PlacementHeader from './Components/TopBar'
import Sidebar from './Components/Sidebar4'
import './PlacementPortal.scss'
import momentjs from 'moment-timezone'
import '../../Components/style/assignmentDeadline.css'
import { saveAs } from 'file-saver';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Autocomplete,
  TextField,
  ToggleButton, ToggleButtonGroup
} from '@mui/material'
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormGroup,
} from 'reactstrap'
import { placementSidebarMap, JOB_STATUS, STATIC_ROUNDS, OFFER_STATUS } from "../../constants/constant";
import PDFReader from "../../Components/PDFReader/PDFReader";

import {
  fetchPlacementCourse,
  changeLearnerStatus,
  sendJobApplyReminderToStudents,
  updateOfferStatus,
  uploadUserOfferLetter
} from '../../actions/jobAction.js'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'
import { LOCATION_CHANGE } from '../../actions/actiontypes.js'

const configs = require('../../config/configs.js')

const SingleJobApplication = () => {
  let fileInput = useRef(null);
  const [togglePDFDoc, setTogglePDFDoc] = useState(false);
  const [resumeURL, setResumeURL] = useState("");
  const [openResumeView, setOpenResumeView] = useState(false)
  const [openChangeRound, setOpenChangeRound] = useState(false)
  const [openPrelimChange, setOpenPrelimChange] = useState(false)
  const [openSendReminder, setOpenSendReminder] = useState(false)
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false)
  const [openCTCUpdate, setOpenCTCUpdate] = useState(false)
  const [selectedLearnerResume, setSelectedLearnerResume] = useState([])
  const [selectedLearner, setSelectedLearner] = useState({})
  const [requests, setRequests] = useState([])
  const [coursepages, setCoursepages] = useState([])
  const [selectedCourse, setSelectedCourse] = useState({})
  const [selectedBatch, setSelectedBatch] = useState({})
  const [selectedJobAppID, setSelectedJobAppID] = useState('')
  const [batches, setBatches] = useState([])
  const [locations, setLocations] = useState([])
  const [courses, setCourses] = useState([])
  const [validFrom, setValidFrom] = useState('')
  const [validTill, setValidTill] = useState('')
  const [searchJobKey, setSearchJobKey] = useState('')
  const [locationName, setLocationName] = useState('')
  const [locationId, setLocationId] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [numOpenings, setNumOpenings] = useState(0)
  const [jobExpTime, setJobExpTime] = useState(Date.now())
  const [numEligible, setNumEligible] = useState(0)
  const [numApplied, setNumApplied] = useState(0)
  const [numRejected, setNumRejected] = useState(0)
  const [numSelected, setNumSelected] = useState(0)

  const [eligibleApplicants, setEligibleApplicants] = useState(0)
  const [appliedApplicants, setAppliedApplicants] = useState(0)
  const [internalRejectApplicants, setInternalRejectApplicants] = useState(0)
  const [externalRejectApplicants, setExternalRejectApplicants] = useState(0)
  const [selectedApplicants, setSelectedApplicants] = useState(0)
  const [rejectedApplicants, setRejectedApplicants] = useState(0)
  const [dynamicApplicants, setDynamicApplicants] = useState([])

  const [chosenLetter, setChosenLetter] = useState();
  const [selectedScreeningStatus, setSelectedScreeningStatus] = useState(0)
  const [selectedStaticStatus, setSelectedStaticStatus] = useState(1)
  const [selectedDynamicStatus, setSelectedDynamicStatus] = useState(0)
  const [roundsDropdown, setRoundsDropdown] = useState([])
  const [selectedRound, setSelectedRound] = useState({})
  const [selectedStudent, setSelectedStudent] = useState('')
  const [remarks, setRemarks] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [subToUnsubArray, setSubToUnsubArray] = useState([])
  const [searchOptions, setSearchOptions] = useState([{
    value: 'uid',
    label: 'Search By UID'
  }, { value: 'ep', label: 'Search By Bulk Email' }])
  const [offerOptions, setOfferOptions] = useState([{ value: 1, label: 'Placed' }, { value: 2, label: 'Not Accepted' }])
  const [selectedOfferStatus, setSelectedOfferStatus] = useState({})
  const [ctcOffered, setCtcOffered] = useState(0)
  const [selectedSearchOptions, setSelectedSearchOptions] = useState('')
  const [staticRounds, setStaticRounds] = useState([])
  const [dynamicRounds, setDynamicRounds] = useState([])
  const [rejectRounds, setRejectRounds] = useState([{ _id: -1, name: "All", type: 0 }])
  const [selectedRejectRound, setSelectedRejectRound] = useState({ _id: -1, name: "All", type: 0 })
  const [selectedCompany, setSelectedCompany] = useState('')
  const [data, setData] = useState([])
  const [placementStatus, setPlacementStatus] = useState([{
    _id: '',
    name: 'All'
  }, { _id: 1, name: 'Not Placed' }, { _id: 2, name: 'Placed' }, { _id: 3, name: 'Self Placed' }])
  const [jobStatus, setJobStatus] = useState([{
    _id: '',
    name: 'All'
  }, { _id: 1, name: 'Applied' }, { _id: 2, name: 'Not Applied' }, { _id: 3, name: 'Missed' }])
  const pathname = window.location.pathname
  const jobid = pathname.split('/').slice(-1)[0]

  const courseChanged = (course) => {
    if (String(selectedCourse?._id) !== String(course._id)) {
      setSelectedCourse(course)
      setSelectedBatch('')
      setBatches([])
      setLocationName('')
      setData([])
      // console.log({batches})
    }
  }

  const rejectedRoundChanged = (status) => {
    console.log({ status })
    setSelectedRejectRound(status)
  }

  const exportData = () => {
    if (!selectedBatch || !selectedBatch._id) {
      alert('Selecting a Batch is mandatory to export Gradebook');
      return;
    }

    const cookies = new Cookies()
    let isGrade = 1
    // let crPage = props.currentPage
    // let tbSize = props.tableSize
    let url = `${configs.routes.BASE_URL_DEVELOPMENT
      }/cfpanelPlacement/exp_to_xlsx`

    var btch_data = {
      url: url,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id: selectedCourse._id,
        crs_id: selectedBatch._id,
        subUsersArray: subToUnsubArray,
        isGrade: 1,
      },
    }
    Axios(btch_data)
      .then((res) => {
        downloadFile(res)
      })
      .catch((err) => {
        alert('Some error occured while downloading the file!')
        console.error(err)
      })
  }

  const downloadFile = (res) => {
    const contentDisposition = res.headers['content-disposition']
    let fileName = contentDisposition.split(';')[1].split('=')[1]

    console.log('File name : ', fileName)
    fileName = fileName.replaceAll('"', '')
    console.log('File name : ', fileName)

    const url = window.URL.createObjectURL(new Blob([res.data]))
    const link = document.createElement('a')
    link.href = url

    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  }

  const handleBulkDownload = (aid) => {
    subToUnsubArray.forEach((aid) => {
      console.log({ aid })
      console.log(subToUnsubArray)
      const learner = data.find((learnerData) => learnerData.aid === aid);
      console.log({ learner })
      if (learner && learner.learner_resume) {
        console.log("here")
        learner.learnerResume.forEach((resume) => {
          if (resume.resume_type === 2) {
            fetch(resume.resume_url)
              .then((response) => response.blob())
              .then((blob) => {
                saveAs(blob, `${learner.aid}_${resume._id}.pdf`);
              })
              .catch((error) => console.error('Error downloading resume:', error));
          }
        });
      }
    });
  };

  const exportLearnerResume = () => {
    if (subToUnsubArray.length < 1) {
      window.alert("Please select learners first")
    }
    const cookies = new Cookies()
    let isGrade = 1
    // let crPage = props.currentPage
    // let tbSize = props.tableSize
    let url = `${configs.routes.BASE_URL_DEVELOPMENT
      }/cfpanelPlacement/exp_learner_resume`

    var btch_data = {
      url: url,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id: selectedCourse._id,
        subUsersArray: subToUnsubArray,
      },
    }
    Axios(btch_data)
      .then((res) => {
        downloadFile(res)
        window.alert('File Downloaded successfully')
      })
      .catch((err) => {
        alert('Some error occured while downloading the file!')
        console.error(err)
      })
  }

  const exportLearnerResponse = () => {

    if (subToUnsubArray.length < 1) {
      window.alert("Please select learners first")
    }
    const cookies = new Cookies()
    let isGrade = 1
    // let crPage = props.currentPage
    // let tbSize = props.tableSize
    let url = `${configs.routes.BASE_URL_DEVELOPMENT
      }/cfpanelPlacement/exp_learner_response`

    var btch_data = {
      url: url,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'x-access-token': cookies.get('at'),
      },
      data: {
        // crs_pg_id: selectedCourse._id,
        job_id: jobid,
        subUsersArray: subToUnsubArray,
      },
    }
    Axios(btch_data)
      .then((res) => {
        downloadFile(res)
        window.alert('File Downloaded successfully')
      })
      .catch((err) => {
        alert('Some error occured while downloading the file!')
        console.error(err)
      })
  }

  const batchChanged = (batch) => {
    // setData([])
    setSelectedBatch(batch)
  }
  const previewResumeClick = (url) => {
    setResumeURL(url);
    setTogglePDFDoc(true);
  };
  const handleModalClose = () => {
    setOpenChangeRound(false)
    setOpenPrelimChange(false)
    setOpenSendReminder(false)
    setOpenUpdateStatus(false)
    setOpenCTCUpdate(false)
    setRemarks('')
  }

  const singleStudentSelect = async (aid) => {
    const checkboxes = document.querySelectorAll('.btch-dt-inp') // Select all checkboxes with the specified className name
    // setTotalCheckboxes(props.totalStudents - props.totalUnsubStudents)
    checkboxes.forEach((checkbox) => {
      if (checkbox.id == aid) {
        if (checkbox.checked) {
          // setCheckedCheckboxes((pre) => pre + 1)
          if (subToUnsubArray.indexOf(aid) === -1) {
            setSubToUnsubArray((prev) => {
              prev.push(aid)
              return [...prev]
            })
          }
        } else {
          // setCheckedCheckboxes((pre) => pre - 1)
          setSubToUnsubArray((prev) => prev.filter((item) => item !== aid))
        }
      }
    })
  }
  const handleResumeModalClose = () => {
    setOpenResumeView(false)
  }

  const handleStatusUpdate = (aid) => {
    const selectedLearner = data.find((item) => item.aid === aid);
    console.log(selectedLearner)
    if (selectedLearner) {
      setSelectedLearner(selectedLearner)
      setOpenUpdateStatus(true)
    }
  }

  const handleCTCUpdate = (aid) => {
    const selectedLearner = data.find((item) => item.aid === aid);
    console.log(selectedLearner)
    if (selectedLearner) {
      setSelectedLearner(selectedLearner)
      setOpenCTCUpdate(true)
    }
  }

  const handleOfferLetter = (crs_pg_id, job_id, aid) => {


  }
  const handleResumeModalOpen = (aid) => {

    const selectedResume = data.find((item) => item.aid === aid);

    if (selectedResume) {
      setSelectedLearner(selectedResume)
      if (selectedStaticStatus > 1) {
        const filteredResumes = selectedResume.learner_resume.filter((resume) =>
          String(selectedResume.resume_id) === String(resume._id)
        );
        setSelectedLearnerResume(filteredResumes);
      } else {
        setSelectedLearnerResume(selectedResume.learner_resume ? selectedResume.learner_resume : []);
      }
      console.log('Opening Resume Modal');
      console.log('Selected Resume:', selectedResume.learner_resume);
    }
    setOpenResumeView(true)
  };
  let serialNum = 1
  useEffect(() => {
    // if(selectedCourse && Object.keys(selectedCourse).length > 0){
    getSingleApplicationStatusData(selectedCourse._id, selectedBatch._id, locationId, validFrom, validTill, page, rowsPerPage)
    // }
  }, [selectedCourse, selectedBatch, locationId, validTill, selectedStaticStatus, selectedDynamicStatus, refresh, page, rowsPerPage])

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)

    fetchPlacementCourse().then((res) => {
      if (res && res.data) {
        // if ('courses' in res.data) {
        //   setCourses(res.data.courses)
        // }

        let tmp_data =
          res.data.courses && res.data.courses
            ? res.data.courses
            : []
        let result = []
        for (let d = 0; d < tmp_data.length; d++) {
          if (tmp_data[d].name) {
            result.push({
              name: tmp_data[d].name,
              _id: tmp_data[d]._id,
            })
          }
        }
        // console.log(res.data.courses)
        // console.log({coursessssss: tmp_data})
        setCourses(tmp_data)
      }
      // setLoading(false)
    })
    const getAllLocations = () => {
      return new Promise((resolve, reject) => {
        var getData = {
          url: `${configs.routes.BASE_URL_DEVELOPMENT
            }/cfpanelGeneric/get_all_course_locations?aid=${cookies.get(
              'aid'
            )}&pid=${cookies.get('pid')}`,
          method: 'GET',
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
        Axios(getData)
          .then((res) => {
            let tmp_data =
              res.data.data && res.data.data.allLocations
                ? res.data.data.allLocations
                : []
            let result = []
            for (let d = 0; d < tmp_data.length; d++) {
              if (tmp_data[d].name) {
                result.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                })
              }
            }
            result.unshift({ name: 'All', _id: null })
            setLocations(result)
            setIsLoading(false)
            resolve(result)
          })
          .catch((err) => {
            setIsLoading(false)
            console.error(err)
            reject(err)
          })
      })
    }
    getAllLocations()

  }, [refresh])

  const getAllBatches = (crs_pg_id, location_id) => {
    const cookies = new Cookies()
    return new Promise((resolve, reject) => {
      let data = {
        crs_pg_id: crs_pg_id,
        a_id: cookies.get('aid'),
      }
      if (location_id != 'ALL') {
        data['location_id'] = location_id
      }
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT}/cfpanelCourse/ls_btch`,
        method: 'POST',
        data: data,
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      Axios(getData)
        .then((res) => {
          let tmp_data =
            res.data.data && res.data.data.courses ? res.data.data.courses : []
          let result = []
          for (let d = 0; d < tmp_data.length; d++) {
            if (tmp_data[d].btch_name) {
              result.push({
                name: tmp_data[d].btch_name,
                _id: tmp_data[d]._id,
              })
            }
          }
          // if (!result.length) {
          //   alert('No batches found at this location')
          // }
          setBatches(result)
          setIsLoading(false)
          resolve(result)
        })
        .catch((err) => {
          console.log('inside get data')
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  let chooseOfferLetter = (ev, crs_pg_id, job_id, aid) => {
    const file = ev.target.files[0]
    // const file = selectedFile;
    let res = validResume(file);

    if (!res.valid) {
      window.alert(res.errorString);
    } else {
      setChosenLetter(file);
      uploadUserOfferLetter(file, crs_pg_id, job_id, aid)
        .then(({ success, data, message }) => {
          console.log({ success: success });
          if (!success) {
            if (message) {
              window.alert(message);
            } else {
              window.alert("Something went wrong. Please try again.");
            }
          } else {
            window.alert("Successfully uploaded Offer Letter");
            setRefresh(!refresh)
            setChosenLetter({
              url: data.img_url,
            });
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    }
  };

  let validResume = (file) => {
    const supportedFileTypes = ["application/pdf"];

    let errorList = [];

    if (file.size > 500 * 1024) {
      errorList.push("File Size exceeds limit");
    }

    if (!supportedFileTypes.includes(file.type)) {
      errorList.push("File type is unsupported");
    }

    let errorString = errorList.join(" and ");
    if (errorList.length > 0) {
      return {
        valid: false,
        errorString,
      };
    } else {
      return {
        valid: true,
      };
    }
  };

  const getSingleApplicationStatusData = (crs_pg_id, cid, loc_id, from, to, page, rowsPerPage) => {

    if (selectedSearchOptions && selectedSearchOptions.value === "uid") {
      if (searchJobKey) {
        if (isNaN(searchJobKey)) {
          window.alert("Please enter a valid number for UID.");
          return;
        }
      }
    }
    setIsLoading(true)

    const cookies = new Cookies()
    return new Promise((resolve, reject) => {
      let data = {
        job_id: jobid,
        crs_pg_id: crs_pg_id,
        cid: cid,
        loc_id: loc_id,
        validFrom: from,
        validTill: to,
        searchField: selectedSearchOptions.value,
        searchVal: searchJobKey,
        staticStatus: selectedStaticStatus,
        dynamicStatus: selectedDynamicStatus,
        a_id: cookies.get('aid'),
        page: page,
        rowsPerPage: rowsPerPage
      }
      // if (location_id) {
      //   data['location_id'] = location_id
      // }
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT}/cfpanelPlacement/get_single_appstatus_data`,
        method: 'POST',
        data: data,
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      Axios(getData)
        .then((res) => {
          let result = []
          console.log(res.data.data)
          let tmp_data =
            res.data.data && res.data.data.appData ? res.data.data.appData : []
          // result.unshift({ name: '', _id: null })
          let tmp_job_data =
            res.data.data && res.data.data.jobData ? res.data.data.jobData : {}
          let total_count = res.data.data && res.data.data.totalApplicants ? res.data.data.totalApplicants : 0
          if (tmp_data.length == 0) {
            // window.alert("No Applications found for selection")
            setData([])
          }
          setData(tmp_data)


          setTotalCount(total_count)
          if (tmp_job_data) {
            setEligibleApplicants(tmp_job_data.eligibleApplicants)
            setAppliedApplicants(tmp_job_data.appliedApplicants)
            setInternalRejectApplicants(tmp_job_data.internalRejectApplicants)
            setExternalRejectApplicants(tmp_job_data.externalRejectApplicants)
            setRejectedApplicants(tmp_job_data.rejectedApplicants)
            setSelectedApplicants(tmp_job_data.selectedApplicants)
            setDynamicApplicants(tmp_job_data.dynamicApplicants)

            let numEligible = tmp_job_data.eligibleApplicants + tmp_job_data.appliedApplicants + tmp_job_data.internalRejectApplicants + tmp_job_data.externalRejectApplicants + tmp_job_data.selectedApplicants + tmp_job_data.rejectedApplicants + tmp_job_data.inprogressApplicants
            setNumEligible(numEligible);
            let numApplied = tmp_job_data.appliedApplicants + tmp_job_data.internalRejectApplicants + tmp_job_data.externalRejectApplicants + tmp_job_data.selectedApplicants + tmp_job_data.rejectedApplicants + tmp_job_data.inprogressApplicants
            setNumApplied(numApplied);
            let numRejected = tmp_job_data.internalRejectApplicants + tmp_job_data.externalRejectApplicants + tmp_job_data.rejectedApplicants
            setNumRejected(numRejected);
            let numSelected = tmp_job_data.selectedApplicants
            setNumSelected(numSelected);



            console.log({ dyn: tmp_job_data.rounds_order })
            setDynamicRounds(tmp_job_data.rounds_order)
            setNumOpenings(tmp_job_data.num_openings)
            setJobExpTime(tmp_job_data.app_end_date)
            setRejectRounds((prevRejectRounds) => {
              const newRejectRounds = tmp_job_data.rounds_order.map((round, index) => ({
                _id: index,
                type: 1,
                name: round.label
              }));

              const uniqueNewRounds = newRejectRounds.filter(
                (newRound) => !prevRejectRounds.some((existingRound) => existingRound.name === newRound.name)
              );

              return [...prevRejectRounds, ...uniqueNewRounds];
            });
          }
          resolve(result)
          setIsLoading(false)
        })
        .catch((err) => {
          console.log('inside get data')
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  const exportJobApplicationReport = (crs_pg_id, cid, loc_id, from, to) => {
    setIsLoading(true)

    const cookies = new Cookies()
    return new Promise((resolve, reject) => {
      let data = {
        job_id: jobid,
        crs_pg_id: crs_pg_id,
        cid: cid,
        loc_id: loc_id,
        validFrom: from,
        validTill: to,
        searchField: selectedSearchOptions.value,
        searchVal: searchJobKey,
        staticStatus: selectedStaticStatus,
        dynamicStatus: selectedDynamicStatus,
        a_id: cookies.get('aid'),
      }
      // if (location_id) {
      //   data['location_id'] = location_id
      // }
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT}/cfpanelPlacement/export_job_application_report`,
        method: 'POST',
        data: data,
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      Axios(getData)
        .then((res) => {
          let result = []
          // console.log(res.data.data)
          downloadFile(res)
          window.alert("Data Successfully Exported")
          resolve(result)
          setIsLoading(false)
        })
        .catch((err) => {
          console.log('inside get data')
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  useEffect(() => {
    const cookies = new Cookies()

    if (selectedCourse && Object.keys(selectedCourse).length > 0) {
      getAllBatches(selectedCourse._id, locationId)
    }

  }, [selectedCourse, locationId, refresh])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  const locationChanged = (locationName, locationId) => {
    setLocationName(locationName)
    setLocationId(locationId)
  }
  // const jobStatusChanged = (jobStatus) => {
  //   setJobStatus(jobStatus)
  // }

  const jobTitleChanged = (jobTitle) => {
    // setSelectedJobTitle(jobTitle)
  }

  const companyNameChanged = (compName) => {
    setSelectedCompany(compName)
  }

  const handleScreeningStatusChange = (screeningStatus) => {

    setSelectedScreeningStatus(screeningStatus)

    if (screeningStatus == 0) {
      setSelectedStaticStatus(1)
    } else {
      setSelectedStaticStatus(3)
      setSelectedDynamicStatus(0)
    }
    const checkboxes = document.querySelectorAll('.btch-dt-inp')
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    })
    setSubToUnsubArray([])
  }

  const handleJobRoundTypeChange = (roundType, roundKey) => {

    if (roundType == 0) {
      setSelectedStaticStatus(roundKey)
    } else {
      setSelectedStaticStatus(3)
      setSelectedDynamicStatus(roundKey)
    }
    console.log("Round is ", roundKey)
    const checkboxes = document.querySelectorAll('.btch-dt-inp')
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    })
    setSubToUnsubArray([])
  }

  const handleSelectedOfferChange = (e) => {
    const selectedOffer = e
    console.log({ selectedOffer })
    setSelectedOfferStatus(selectedOffer)
  }

  const changePrelimStatus = (aid) => {
    const selected = data.find((item) => item.aid === aid);
    setSelectedJobAppID(selected._id)
    setSelectedStudent(aid)
    let roundsDropdown = [];
    const indexNameForAssessment = dynamicRounds.length > 0 ? dynamicRounds[0].label : '-';

    roundsDropdown.push({ type: 0, _id: parseInt(7), name: 'Mark as Internally Rejected', index_name: 'Internal Reject' });
    roundsDropdown.push({ type: 0, _id: parseInt(8), name: 'Mark as Externally Rejected', index_name: 'External Reject' });
    roundsDropdown.push({ type: 1, _id: parseInt(0), name: 'Move to Assessment Rounds', index_name: indexNameForAssessment });
    console.log({ roundsDropdown })
    setRoundsDropdown(roundsDropdown)
    setOpenPrelimChange(true)
  }

  const changeRoundStatus = (aid) => {
    const selected = data.find((item) => item.aid === aid);
    setSelectedJobAppID(selected._id)
    setSelectedStudent(aid)
    let roundsDropdown = [];

    Object.entries(STATIC_ROUNDS).forEach(([key, value]) => {
      if (parseInt(key) > selectedStaticStatus && parseInt(key) != 3 && parseInt(key) != 7 && parseInt(key) != 8) {
        roundsDropdown.push({ type: 0, _id: parseInt(key), name: value, index_name: value });
      }
      // if (parseInt(key) === 7 && selectedStaticStatus === 2) {
      //   roundsDropdown.push({ type: 0, _id: parseInt(key), name: value });
      // }
    })

    if (selectedStaticStatus < 4) {
      dynamicRounds.forEach((item, index) => {
        const roundNumber = parseInt(index) + 1;
        if (selectedStaticStatus === 3 && index > selectedDynamicStatus) {
          roundsDropdown.push({ type: 1, _id: index, name: 'Round-' + roundNumber + ' ' + item.label, index_name: item.label });
        } else if (selectedStaticStatus !== 3) {
          roundsDropdown.push({ type: 1, _id: index, name: 'Round-' + roundNumber + ' ' + item.label, index_name: item.label });
        }
      });
    }
    console.log({ roundsDropdown })
    setRoundsDropdown(roundsDropdown)
    setOpenChangeRound(true)
  }

  const selectedRoundChanged = (selectedRound) => {
    setSelectedRound(selectedRound)
  }

  const handleDateInputChnage = (e) => {
    const [key, value] = [e.target.name, e.target.value]

    if (e.target.name == 'validFrom') {
      setValidFrom(e.target.value)
    }
    if (e.target.name == 'validTill') {
      setValidTill(e.target.value)
    }

  }

  const changeLearnerRoundStatus = async (
  ) => {
    setIsLoading(true)
    let resp = await changeLearnerStatus(
      selectedStudent,
      jobid,
      selectedJobAppID,
      selectedRound,
      remarks
    )
    if (resp.success) {
      window.alert('Placement Status updated successfully for selected users')
      setIsLoading(false)
      setRefresh(!refresh)
      setOpenChangeRound(false)
      setOpenPrelimChange(false)
    } else {
      window.alert(resp.message)
      setIsLoading(false)
    }
  }

  const sendJobApplyReminder = async (
  ) => {
    setIsLoading(true)
    let resp = await sendJobApplyReminderToStudents(
      jobid,
    )
    if (resp.success) {
      window.alert('Successfully sent reminders')
      setIsLoading(false)
      setOpenSendReminder(false)
      setRefresh(!refresh)
    } else {
      window.alert(resp.message)
      setIsLoading(false)
    }
  }

  const updateLearnerOfferStatus = async () => {
    setIsLoading(true)
    let resp = await updateOfferStatus(
      jobid,
      selectedLearner.aid,
      true,
      ctcOffered,
      selectedOfferStatus.value,
    )
    if (resp.success) {
      window.alert('Successfully updated learner offer')
      setIsLoading(false)
      setRefresh(!refresh)
      setSelectedLearner({})
      setOpenUpdateStatus(false)
    } else {
      window.alert(resp.message)
      setIsLoading(false)
    }
  }

  const updateLearnerCTC = async () => {
    setIsLoading(true)
    let resp = await updateOfferStatus(
      jobid,
      selectedLearner.aid,
      false,
      ctcOffered,
      0
    )
    if (resp.success) {
      window.alert('Successfully updated learner CTC')
      setIsLoading(false)
      setRefresh(!refresh)
      setSelectedLearner({})
      setOpenCTCUpdate(false)
    } else {
      window.alert(resp.message)
      setIsLoading(false)
    }
  }

  const minToTime = (time) => {
    const result =
      Math.floor(time / 60) +
      ':' +
      (time - Math.floor(time / 60) * 60 < 10
        ? '0' + (time - Math.floor(time / 60) * 60)
        : time - Math.floor(time / 60) * 60)

    return result
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <div className='placement-portal' style={{ overflow: "hidden" }}>
      <>
        <Sidebar />
        <div className="labs-main">
          <PlacementHeader breadCrumbs={[
            { name: "Career Services", link: "/" },
            { name: "Application Status", link: "/career-services/application-status" },
          ]}
            active={2} />
          <div className="labs-content">
            <div style={{ borderBottom: '1px solid grey', paddingBottom: '16px' }}>
              <Row>
                <Col md={2} className="labs-table-header-2">
                  <p style={{ fontSize: '10px', margin: "0px", paddingBottom: "4px" }}>Select Course Name</p>
                  <Autocomplete
                    // style={{ width: '200px' }}
                    disableClearable
                    value={selectedCourse && selectedCourse.name}
                    onChange={(event, course) => {
                      courseChanged(course)
                    }}
                    getOptionLabel={(batch) => {
                      return batch.name || batch
                    }}
                    getOptionSelected={(option, test) => option.name == test}
                    id="batchSelector"
                    options={courses}
                    disabled={courses.length === 0 ? true : false}
                    renderInput={(params) => {
                      return (
                        <TextField
                          // label="Select Course Name"
                          {...params}
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                </Col>
                {/* Courses */}
                <Col md={2} className="labs-table-header-2">
                  <p style={{ fontSize: '10px', margin: "0px", paddingBottom: "4px" }}>Select Training Location</p>
                  <Autocomplete
                    // style={{ width: '200px' }}
                    disableClearable
                    value={locationName}
                    onChange={(event, location) => {
                      locationChanged(location.name, location._id)
                    }}
                    getOptionLabel={(batch) => {
                      return batch.name || batch
                    }}
                    getOptionSelected={(option, test) => option.name == test}
                    id="batchSelector"
                    options={locations}
                    disabled={locations.length === 0 ? true : false}
                    renderInput={(params) => {
                      return (
                        <TextField
                          // label="Select Training Location"
                          {...params}
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                </Col>
                <Col md={2} className="labs-table-header-2">
                  <p style={{ fontSize: '10px', margin: "0px", paddingBottom: "4px" }}>Select Batch</p>
                  <Autocomplete
                    // style={{ width: '200px' }}
                    disableClearable
                    value={selectedBatch && selectedBatch.name}
                    onChange={(event, batch) => {
                      batchChanged(batch)
                    }}
                    getOptionLabel={(batch) => {
                      return batch.name || batch
                    }}
                    getOptionSelected={(option, test) => option.name == test}
                    id="batchSelector"
                    options={batches}
                    disabled={batches.length === 0 ? true : false}
                    renderInput={(params) => {
                      return (
                        <TextField
                          // label="Select Batch"
                          {...params}
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                </Col>

                <Col md={2} >
                  <div className="row" style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ fontSize: '10px', margin: "0px", marginLeft: "10px", paddingBottom: "4px" }}>From</p>
                    <input
                      type="date"
                      className="labs-create-new-form-date_input"
                      name="validFrom"
                      value={validFrom}
                      required={true}
                      onChange={(event) => {
                        handleDateInputChnage(event)
                      }}
                    // min={this.state.currentFormattedDate}
                    // max={this.state.finalFormattedDate}
                    />
                  </div>

                </Col>
                <Col md={2} >
                  <div className="row" style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ fontSize: '10px', margin: "0px", marginLeft: "10px", paddingBottom: "4px" }}>To</p>
                    <input
                      type="date"
                      className="labs-create-new-form-date_input"
                      name="validTill"
                      value={validTill}
                      required={true}
                      onChange={(event) => {
                        handleDateInputChnage(event)
                      }}
                      min={validFrom}
                      disabled={validFrom == '' ? true : false}
                    />
                  </div>
                </Col>
                <Col md={2} >
                  <div className="row" style={{ top: "25%", display: "flex", position: "relative" }}>
                    <p style={{ fontSize: '10px', margin: "0px", marginLeft: "10px", paddingBottom: "4px" }}></p>
                    <button
                      onClick={() => (exportJobApplicationReport())}
                      className="jobs-table-btn-6"
                    >
                      <img
                        src="https://webcdn.imarticus.org/Placement/tabler_file-export1.svg"
                      />
                      Export Report
                    </button>
                  </div>
                </Col>
              </Row>
              <br></br>
              <Row>
                {/* <Col md={2} className="labs-table-header">
            </Col>
            <Col md={2} className="labs-table-header">
            </Col>
            <Col md={2} className="labs-table-header">
            </Col> */}

                <div className="revenuemaincontainer">
                  <div className="revenuecontainer selected-jobtype">
                    <h4>Total Open Positions</h4>
                    <h2> {numOpenings ? numOpenings : 0}</h2>
                  </div>

                  <div className="revenuecontainer">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <h4>Total Eligible Learners</h4>
                      <h4 className="tooltipl"></h4>
                    </div>
                    <h2> {numEligible ? numEligible : 0}</h2>
                  </div>
                  <div className="revenuecontainer">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <h4>Total Learners Applied</h4>
                      <h4 className="tooltipl">
                      </h4>
                    </div>
                    <h2> {numApplied ? numApplied : 0}</h2>
                  </div>
                  <div className="revenuecontainer">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <h4>Total Learners Not Applied</h4>
                      <h4 className="tooltipl">
                      </h4>
                    </div>
                    <h2 style={{ color: "#DC3545" }}> {numEligible ? numEligible - numApplied : 0}</h2>
                  </div>
                  <div className="revenuecontainer">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <h4>Total Learners Rejected</h4>
                      <h4 className="tooltipl">
                      </h4>
                    </div>
                    <h2 style={{ color: "#DC3545" }}> {numRejected ? numRejected : 0}</h2>
                  </div>
                  <div className="revenuecontainer selected-jobtype">
                    <h4>Total Learners Selected</h4>
                    <h2> {numSelected ? numSelected : 0}</h2>
                  </div>
                </div>
              </Row>
              <Row>
                <Col md={12} className="" >
                  <div className="toggle-container2 app-round-dsply">
                    <button
                      className={`job-screen-dt-tog-btn3 subscribed ${selectedScreeningStatus === 0 ? 'btch-toggle-3' : 'btch-toggle-unchecked-3'}`}
                      onClick={() => handleScreeningStatusChange(0)}
                    >
                      <span>
                        Preliminary Screening
                      </span>
                    </button>
                    <button
                      className={`job-screen-dt-tog-btn3 ${selectedScreeningStatus === 1 ? 'btch-toggle-3' : 'btch-toggle-unchecked-3'
                        }`}
                      onClick={() => handleScreeningStatusChange(1)}
                    >
                      <span>
                        Assessment Rounds
                      </span>
                    </button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="" >
                  {selectedScreeningStatus != 1 ? (
                    <div className="toggle-container2 app-round-dsply">
                      <button
                        className={`job-dt-tog-btn3 subscribed ${selectedStaticStatus === 1 ? 'btch-toggle-3' : 'btch-toggle-unchecked-3'
                          }`}
                        onClick={() => handleJobRoundTypeChange(0, 1)}
                      >
                        <span
                          className={` `}
                        >
                          Eligible Learners
                          ({eligibleApplicants})
                        </span>
                      </button>
                      <button
                        className={`job-dt-tog-btn3 ${selectedStaticStatus === 2 ? 'btch-toggle-3' : 'btch-toggle-unchecked-3'
                          }`}
                        onClick={() => handleJobRoundTypeChange(0, 2)}
                      >
                        <span
                          className={``}
                        >
                          Applied Learners
                          ({appliedApplicants})
                        </span>
                      </button>
                      {/* {dynamicRounds.map((round, index) => (
                <button
                  key={index}
                  className="job-dt-tog-btn3"
                  onClick={() => handleJobRoundTypeChange(1, index)}
                >
                  <span className={`${ selectedStaticStatus === 3 &&  selectedDynamicStatus == index ? 'btch-toggle-3' : 'btch-toggle-unchecked-3'
                    }`}>
                    {round.label}
                    ({data.length > 0 && data.filter(dt => dt.job_app_static_status === 3 && dt.job_app_dynamic_status === index).length})
                  </span>
                </button>
              ))} */}
                      <button
                        className={`job-dt-tog-btn3 ${selectedStaticStatus === 7 ? 'btch-toggle-3' : 'btch-toggle-unchecked-3'
                          }`}
                        onClick={() => handleJobRoundTypeChange(0, 7)}
                      >
                        <span
                        >
                          Internal Rejected
                          ({internalRejectApplicants})
                        </span>
                      </button>
                      <button
                        className={`job-dt-tog-btn3 ${selectedStaticStatus === 8 ? 'btch-toggle-3' : 'btch-toggle-unchecked-3'
                          }`}
                        onClick={() => handleJobRoundTypeChange(0, 8)}
                      >
                        <span
                        >
                          External Rejected  ({externalRejectApplicants})
                        </span>
                      </button>
                    </div>
                  ) : (
                    <div className="toggle-container2 app-round-dsply-2">
                      {dynamicRounds.map((round, index) => (
                        <button
                          key={index}
                          className={` job-dt-tog-btn3 ${selectedStaticStatus === 3 && selectedDynamicStatus == index ? 'btch-toggle-3' : 'btch-toggle-unchecked-3'
                            }`}
                          onClick={() => handleJobRoundTypeChange(1, index)}
                        >
                          <span >
                            {round.label}
                            ({dynamicApplicants[index]})
                          </span>
                        </button>
                      ))}
                      <button
                        className={`job-dt-tog-btn3 ${selectedStaticStatus === 4 || selectedStaticStatus === 5 ? 'btch-toggle-3' : 'btch-toggle-unchecked-3'
                          }`}
                        onClick={() => handleJobRoundTypeChange(0, 4)}
                      >
                        <span
                          className={``}
                        >
                          Selected
                          ({selectedApplicants})
                        </span>
                      </button>
                      <button
                        className={`job-dt-tog-btn3 ${selectedStaticStatus === 6 ? 'btch-toggle-3' : 'btch-toggle-unchecked-3'
                          }`}
                        onClick={() => handleJobRoundTypeChange(0, 6)}
                      >
                        <span
                          className={``}
                        >
                          Rejected  ({rejectedApplicants})
                        </span>
                      </button>
                    </div>
                  )}

                </Col>
              </Row>
              <Row style={{ marginTop: '2rem' }}>
                <Col md={8} className="labs-table-header">
                  <p>All Applicants</p>
                </Col>
                <Col md={1}>
                </Col>
                <Col md={3} className="labs-table-header">
                  {selectedStaticStatus === 1 && jobExpTime >= Date.now() && (
                    <button
                      className="jobs-table-btn-11"
                      onClick={() => setOpenSendReminder(true)}
                      disabled={jobExpTime <= Date.now()}
                    >
                      <img
                        src="https://webcdn.imarticus.org/Placement/ic_baseline-schedule.svg"
                      />
                      Send Reminders
                    </button>
                  )}
                  {selectedStaticStatus === 6 && (
                    <Autocomplete
                      style={{ minWidth: "150px" }}
                      disableClearable
                      value={selectedRejectRound.name}
                      onChange={(event, status) => {
                        rejectedRoundChanged(status)
                      }}
                      getOptionLabel={(batch) => {
                        return batch.name || batch
                      }}
                      getOptionSelected={(option, test) => option.name == test}
                      id="batchSelector"
                      options={rejectRounds}
                      disabled={rejectRounds.length === 0 ? true : false}
                      renderInput={(params) => {
                        return (
                          <TextField
                            label="Select Round Rejected In"
                            {...params}
                            variant="outlined"
                            fullWidth
                          />
                        )
                      }}
                    />
                  )}
                </Col>
              </Row>
            </div>
            <Row style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-between', marginLeft: "0px" }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', zIndex: '999' }}>
                <div style={{ display: 'flex' }}>
                  <div className="btch-search2" style={{ marginRight: '1rem', width: '200px' }}>
                    <Select
                      options={searchOptions}
                      placeholder="Select Search By "
                      value={selectedSearchOptions}
                      onChange={(event) => {
                        setSelectedSearchOptions(event)
                      }}
                      isSearchable={true}
                    />

                  </div>
                  <div className="btch-search">
                    <input
                      placeholder="Search Application"
                      value={searchJobKey}
                      onChange={(e) => setSearchJobKey(e.target.value)}
                      className="form-control as-dl-search"
                      ref={(input) =>
                        input && searchJobKey && input.focus()
                      }
                      style={{
                        height: "100% !important"
                      }}
                    />
                  </div>
                  <button
                    onClick={() => (refresh ? setRefresh(false) : setRefresh(true))}
                    className="labs-table-btn-35"
                  >
                    Search
                  </button>
                </div>
                <div className="btch-search" style={{ marginRight: '1rem', width: '250px' }}>
                  {selectedStaticStatus === 1 && (
                    // <Autocomplete
                    //   style={{ width: '200px' }}
                    //   disableClearable
                    //   // value={locationName}
                    //   // onChange={(event, location) => {
                    //   //   locationChanged(location.name,location._id)
                    //   // }}
                    //   getOptionLabel={(batch) => {
                    //     return batch.name || batch
                    //   }}
                    //   getOptionSelected={(option, test) => option.name == test}
                    //   id="batchSelector"
                    //   options={jobStatus}
                    //   disabled={jobStatus.length === 0 ? true : false}
                    //   renderInput={(params) => {
                    //     return (
                    //       <TextField
                    //         label="Select Job Status"
                    //         {...params}
                    //         variant="outlined"
                    //         fullWidth
                    //       />
                    //     )
                    //   }}
                    // />
                    <></>
                  )}

                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>


              </div>
            </Row>
            {data && data.length > 0 ? (
              <>
                {selectedScreeningStatus != 1 ? (
                  <Row style={{ marginTop: '1rem' }}>
                    <TableContainer component={Paper} style={{ height: '600px' }}>
                      <Table aria-label="custom pagination table" id="batch-data-table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className='sticky-header-both-zero btch-dt-chk '
                              scope="col">
                            </TableCell>
                            <TableCell
                              className=" btch-dt-th sticky-header-both-first"
                              scope="col"
                            >
                              S No.
                            </TableCell>
                            <TableCell
                              className="btch-dt-th sticky-header-both-second"
                              scope="col"
                            >
                              Name
                            </TableCell>
                            <TableCell
                              className="btch-dt-th sticky-header-both-third"
                              scope="col"
                              style={{ cursor: 'pointer' }}
                            >
                              Email ID
                              <span
                                style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                              >
                              </span>
                            </TableCell>
                            <TableCell
                              className="btch-dt-th sticky-header"
                              scope="col"
                              style={{ cursor: 'pointer' }}
                            >
                              Mobile No.
                              <span
                                style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                              >
                              </span>
                            </TableCell>
                            <TableCell
                              className="btch-dt-th sticky-header"
                              scope="col"
                              style={{ cursor: 'pointer' }}
                            >
                              Course Name
                              <span
                                style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                              >
                              </span>
                            </TableCell>
                            <TableCell className="btch-dt-th sticky-header" scope="col">
                              Batch Name
                            </TableCell>
                            <TableCell className="btch-dt-th sticky-header" scope="col">
                              Training Center
                            </TableCell>
                            <TableCell className="btch-dt-th sticky-header" scope="col">
                              Resume
                            </TableCell>
                            <TableCell className="btch-dt-th sticky-header" scope="col">
                              Job Status
                            </TableCell>
                            <TableCell className="btch-dt-th sticky-header" scope="col">
                              Application End Date
                            </TableCell>
                            {selectedStaticStatus > 1 && selectedStaticStatus < 4 && (
                              <TableCell className="btch-dt-th sticky-header" scope="col">
                                Action
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data &&
                            data.filter(dt => {
                              if (selectedStaticStatus === 3) {
                                return dt.job_app_static_status === 3;
                              } else if (selectedStaticStatus === 7) {
                                return dt.job_app_static_status === 7;
                              } else if (selectedStaticStatus === 8) {
                                return dt.job_app_static_status === 8;
                              }
                              return dt.job_app_static_status === selectedStaticStatus;
                            })
                              .map((dt, key) => {
                                return (
                                  <TableRow>
                                    <TableCell className="sticky-first btch-dt-chk"
                                      scope="row">
                                      {dt.job_app_static_status <= 3 && (
                                        <Input
                                          type="checkbox"
                                          className="btch-dt-inp"
                                          id={dt.aid}
                                          onClick={() => {
                                            singleStudentSelect(dt.aid);
                                          }}
                                          style={{ bottom: "25%", right: "25%" }}
                                        />
                                      )}
                                    </TableCell>
                                    <TableCell
                                      className="btch-dt-td-app  sticky-second"
                                      scope="row"
                                    >{serialNum++}</TableCell>
                                    <TableCell
                                      className="btch-dt-td-app  sticky-third"
                                      scope="row"
                                    >
                                      {dt.name}
                                    </TableCell>
                                    <TableCell
                                      className="btch-dt-td-app  sticky-fourth"
                                      scope="row"
                                    >
                                      {dt.ep}
                                    </TableCell>
                                    <TableCell
                                      className="btch-dt-td-app "
                                      scope="row"
                                    >
                                      {dt.m || "-"}
                                    </TableCell>
                                    <TableCell className="btch-dt-td-app " scope="row">

                                      {dt.crs_name || "-"}
                                    </TableCell>
                                    <TableCell className="btch-dt-td-app " scope="row">

                                      {dt.btch_name}
                                    </TableCell>
                                    <TableCell className="btch-dt-td-app " scope="row">

                                      {dt.loc_name}
                                    </TableCell>
                                    <TableCell className="btch-dt-td-app " scope="row">
                                      <a href="/head" className="resume-link" onClick={(e) => {
                                        e.preventDefault();
                                        handleResumeModalOpen(dt.aid);
                                      }}>
                                        View Resume
                                      </a>
                                    </TableCell>
                                    <TableCell className="btch-dt-td-app " scope="row">
                                      {STATIC_ROUNDS[dt.job_app_static_status]}
                                    </TableCell>
                                    <TableCell className="btch-dt-td-app " scope="row">
                                      {moment(dt.app_end_date).format("DD-MM-YYYY HH:mm")}
                                    </TableCell>
                                    {selectedStaticStatus > 1 && selectedStaticStatus < 4 && (
                                      <TableCell className="btch-dt-td-app " scope="row">
                                        <button
                                          onClick={() => changePrelimStatus(dt.aid)}
                                          className="labs-table-btn-1"
                                        >
                                          Change Status
                                        </button>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                )
                              })}
                        </TableBody>
                      </Table>

                    </TableContainer>
                    <div style={{ marginRight: '256px' }}>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </Row>) : (
                  <Row style={{ marginTop: '1rem' }}>
                    <TableContainer component={Paper} style={{ height: '600px' }}>
                      <Table aria-label="custom pagination table" id="batch-data-table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className='sticky-header-both-zero btch-dt-chk '
                              scope="col">
                            </TableCell>
                            <TableCell
                              className=" btch-dt-th sticky-header-both-first"
                              scope="col"
                            >
                              S No.
                            </TableCell>
                            <TableCell
                              className="btch-dt-th sticky-header-both-second"
                              scope="col"
                            >
                              Name
                            </TableCell>
                            <TableCell
                              className="btch-dt-th sticky-header-both-third"
                              scope="col"
                              style={{ cursor: 'pointer' }}
                            >
                              Email ID
                              <span
                                style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                              >
                              </span>
                            </TableCell>
                            <TableCell
                              className="btch-dt-th sticky-header"
                              scope="col"
                              style={{ cursor: 'pointer' }}
                            >
                              Mobile No.
                              <span
                                style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                              >
                              </span>
                            </TableCell>
                            <TableCell
                              className="btch-dt-th sticky-header"
                              scope="col"
                              style={{ cursor: 'pointer' }}
                            >
                              Course Name
                              <span
                                style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                              >
                              </span>
                            </TableCell>
                            <TableCell className="btch-dt-th sticky-header" scope="col">
                              Batch Name
                            </TableCell>
                            <TableCell className="btch-dt-th sticky-header" scope="col">
                              Training Center
                            </TableCell>
                            <TableCell className="btch-dt-th sticky-header" scope="col">
                              Resume
                            </TableCell>
                            <TableCell className="btch-dt-th sticky-header" scope="col">
                              Job Status
                            </TableCell>
                            <TableCell className="btch-dt-th sticky-header" scope="col">
                              Application End Date
                            </TableCell>
                            {selectedStaticStatus > 1 && selectedStaticStatus < 4 && (
                              <TableCell className="btch-dt-th sticky-header" scope="col">
                                Action
                              </TableCell>
                            )}
                            {selectedStaticStatus >= 6 && (
                              <>
                                <TableCell className="btch-dt-th sticky-header" scope="col">
                                  Round Rejected In
                                </TableCell>
                                <TableCell className="btch-dt-th sticky-header" scope="col">
                                  Remarks
                                </TableCell>
                              </>

                            )}
                            {selectedStaticStatus === 4 && (
                              <>
                                <TableCell className="btch-dt-th sticky-header" scope="col">
                                  Offer Status
                                </TableCell>
                                <TableCell className="btch-dt-th sticky-header" scope="col">
                                  CTC Offered
                                </TableCell>
                                <TableCell className="btch-dt-th sticky-header" scope="col">
                                  Remarks
                                </TableCell>
                                {selectedStaticStatus === 4 && (
                                  <>
                                    <TableCell className="btch-dt-th sticky-header" scope="col">
                                      Offer Letter
                                    </TableCell>
                                    <TableCell className="btch-dt-th sticky-header" scope="col">
                                      Action
                                    </TableCell>
                                  </>
                                )}


                              </>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data &&
                            data.filter(dt => {
                              if (selectedStaticStatus === 3) {
                                return dt.job_app_static_status === 3 && dt.job_app_dynamic_status === selectedDynamicStatus;
                              } else if (selectedStaticStatus === 4) {
                                return dt.job_app_static_status === 4 || dt.job_app_static_status === 5;
                              } else if (selectedStaticStatus === 6) {
                                // return dt.job_app_static_status === 6 || dt.job_app_static_status === 7;
                                if (selectedRejectRound.type === 0) {
                                  if (selectedRejectRound._id < 0) {
                                    return dt.job_app_static_status === 6
                                  }
                                } else {
                                  return dt.job_app_static_status === 6 && dt.job_app_dynamic_status === selectedRejectRound._id;
                                }
                              }
                              return dt.job_app_static_status === selectedStaticStatus;
                            })
                              .map((dt, key) => {
                                return (
                                  <TableRow>
                                    <TableCell className="sticky-first btch-dt-chk"
                                      scope="row">
                                      {dt.job_app_static_status <= 3 && (
                                        <Input
                                          type="checkbox"
                                          className="btch-dt-inp"
                                          id={dt.aid}
                                          onClick={() => {
                                            singleStudentSelect(dt.aid);
                                          }}
                                        />
                                      )}
                                    </TableCell>
                                    <TableCell
                                      className="btch-dt-td-app  sticky-second"
                                      scope="row"
                                    >{serialNum++}</TableCell>
                                    <TableCell
                                      className="btch-dt-td-app  sticky-third"
                                      scope="row"
                                    >
                                      {dt.name}
                                    </TableCell>
                                    <TableCell
                                      className="btch-dt-td-app  sticky-fourth"
                                      scope="row"
                                    >
                                      {dt.ep}
                                    </TableCell>
                                    <TableCell
                                      className="btch-dt-td-app "
                                      scope="row"
                                    >
                                      {dt.m || "-"}
                                    </TableCell>
                                    <TableCell className="btch-dt-td-app " scope="row">

                                      {dt.crs_name || "-"}
                                    </TableCell>
                                    <TableCell className="btch-dt-td-app " scope="row">

                                      {dt.btch_name}
                                    </TableCell>
                                    <TableCell className="btch-dt-td-app " scope="row">

                                      {dt.loc_name}
                                    </TableCell>
                                    <TableCell className="btch-dt-td-app " scope="row">
                                      <a href="/head" className="resume-link" onClick={(e) => {
                                        e.preventDefault();
                                        handleResumeModalOpen(dt.aid);
                                      }}>
                                        View Resume
                                      </a>
                                    </TableCell>
                                    <TableCell className="btch-dt-td-app " scope="row">
                                      {STATIC_ROUNDS[dt.job_app_static_status]}
                                    </TableCell>
                                    <TableCell className="btch-dt-td-app " scope="row">
                                      {moment(dt.app_end_date).format("DD-MM-YYYY HH:mm")}
                                    </TableCell>
                                    {selectedStaticStatus > 1 && selectedStaticStatus < 4 && (
                                      <TableCell className="btch-dt-td-app " scope="row">
                                        <button
                                          onClick={() => changeRoundStatus(dt.aid)}
                                          className="labs-table-btn-1"
                                        >
                                          Update Round Status
                                        </button>
                                      </TableCell>
                                    )}
                                    {selectedStaticStatus === 6 && (
                                      <>
                                        <TableCell className="btch-dt-td-app " scope="row">
                                          {dt.job_app_static_status === 7 ? "Shortlist Reject" : (dynamicRounds?.[dt.job_app_dynamic_status]?.label ?? '-')}
                                        </TableCell>
                                        <TableCell className="btch-dt-td-app " scope="row">
                                          {dt.remarks}
                                        </TableCell>
                                      </>
                                    )}
                                    {selectedStaticStatus === 4 && (
                                      <>
                                        <TableCell className="btch-dt-td-app " scope="row">
                                          {OFFER_STATUS[dt.offer_status]}
                                        </TableCell>
                                        <TableCell className="btch-dt-td-app " scope="row">
                                          {dt.ctc_offered}
                                        </TableCell>
                                        <TableCell className="btch-dt-td-app " scope="row">
                                          {dt.learner_remarks || '-'}
                                        </TableCell>
                                        <TableCell className="btch-dt-td-app " scope="row">
                                          {dt.offer_letter === "" ? (
                                            <>
                                              <button className="labs-table-btn-1"
                                                // onClick={() => handleOfferLetter(dt.crs_pg_id,dt.job_id,dt.aid)}
                                                onClick={() => fileInput.click()}
                                              >
                                                Upload Offer Letter
                                              </button>
                                              <input
                                                type="file"
                                                name="file"
                                                id="fileInput"
                                                ref={(input) => {
                                                  fileInput = input;
                                                }}
                                                style={{ display: "none" }}
                                                onChange={(event) => {
                                                  // Handle file upload logic here
                                                  const file = event.target.files[0];
                                                  console.log("Uploaded file:", file);
                                                  chooseOfferLetter(event, dt.crs_pg_id, dt.job_id, dt.aid);
                                                }}
                                              />
                                            </>
                                          ) : (
                                            <a href={dt.offer_letter} target="_blank" rel="noopener noreferrer">
                                              View Offer Letter
                                            </a>
                                          )}
                                        </TableCell>
                                        <TableCell className="btch-dt-td-app " scope="row">
                                          {dt.offer_status == 1 ? (
                                            <button
                                              onClick={() => handleCTCUpdate(dt.aid)}
                                              className="labs-table-btn-1"
                                            >
                                              Update CTC
                                            </button>
                                          ) : (
                                            <button
                                              onClick={() => handleStatusUpdate(dt.aid)}
                                              className="labs-table-btn-1"
                                            >
                                              Update Placement Status
                                            </button>
                                          )}

                                        </TableCell>
                                      </>
                                    )}

                                  </TableRow>
                                )
                              })}
                        </TableBody>
                      </Table>

                    </TableContainer>
                    <div style={{ marginRight: '256px' }}>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </Row>)}

                {subToUnsubArray.length > 0 && selectedStaticStatus < 4 && (
                  <div className="btch-dt-foot-main">
                    <div className="btch-dt-foot-cnt">
                      {subToUnsubArray.length} learners selected
                    </div>
                    <div className="btch-dt-foot-btn-3">
                      <button
                        className="btch-dt-foot-btn3"
                        onClick={() => {
                          exportLearnerResume();
                        }}
                      >
                        Bulk Download Resume
                      </button>
                      {selectedStaticStatus > 1 && (
                        <>
                          <button
                            className="btch-dt-foot-btn3"
                            onClick={() => {
                              exportLearnerResponse();
                            }}
                          >
                            Download Question Responses
                          </button>
                          <button
                            className="btch-dt-foot-btn3"
                            onClick={() => { exportData() }}
                          >
                            Bulk Download Gradebook
                          </button>
                        </>

                      )}
                    </div>
                  </div>)}
              </>
            ) :
              <div className="job-not-found">
                <img src='https://webcdn.imarticus.org/demo/Group4606.svg'></img>
                <div style={{ marginBottom: '1rem' }}>
                  <br></br>
                  <h5>It's empty here!</h5>
                </div>
                <div>
                  <p style={{ marginLeft: '1rem' }}>First select a course by clicking on</p>
                  <p >"Select Course Name" to view applications here</p>
                </div>
              </div>
            }
            <Modal isOpen={togglePDFDoc} className="resume-preview-modal" size={"md"} style={{ minwidth: "400px" }} centered>
              <ModalHeader
                className="resume-prev"
                style={{ paddingRight: "7px", width: "100%" }}
              >
                <div className="row" style={{ justifyContent: "space-between", marginLeft: "12px", marginRight: "12px" }}>
                  <div>Resume Preview </div>
                  <div>
                    <img
                      src="https://webcdn.imarticus.org/Placement/Group926.svg"
                      style={{ paddingRight: "7px" }}
                      onClick={() => setTogglePDFDoc(false)}
                    />
                  </div>
                </div>
              </ModalHeader>
              <ModalBody
                style={{
                  minHeight: "500px",
                  overflowY: "scroll",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <iframe
                  src={resumeURL}
                  style={{ width: "100%", height: "100%", border: "none", minHeight: "750px" }}
                  frameBorder="0"
                />
              </ModalBody>
              <ModalFooter style={{ justifyContent: "center" }}></ModalFooter>
            </Modal>

            <Modal className="jobs-viewresumemodal" isOpen={openResumeView} centered>
              {/* <ModalHeader className="viewres-header"> */}
              <ModalBody
                className="thumbModal"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                {/* <li className="row">
            <div className="col-md-8">
              <div style={{ display: 'flex', alignItems: 'center' , fontWeight: '700'}}>
              {selectedLearner ? `${selectedLearner.name}'s Resume` : "Learner's Resume"}
              </div>
            </div> */}
                {/* {selectedLearner && selectedLearnerResume && selectedLearnerResume.length > 0 && (
            <div className='res-dl-btn col-md-4 '>
              <span>
                Download Resumes
              </span>
            </div>
          )} */}
                {/* </li> */}
                <li className="row">
                  <div className="viewres-txt">
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '20px', fontWeight: '700', marginLeft: "32px" }}>
                      {selectedLearner ? `${selectedLearner.name}'s Resume` : "Learner's Resume"}
                    </div>
                    {/* {selectedLearner && selectedLearnerResume && selectedLearnerResume.length > 0 && (
              <div className='res-dl-btn ' style={{marginRight:"32px"}} onClick={ () => {handleBulkDownload()}}>
                  Download Resumes
              </div>)} */}
                  </div>

                </li>
                {/* </ModalHeader> */}


                {selectedLearner && selectedLearnerResume && selectedLearnerResume.length === 0 ? (
                  <div>No resume found</div>
                ) : (
                  <>
                    {selectedLearnerResume && selectedLearnerResume
                      .filter((file) => file.resume_type === 2)
                      .map((file, index) => (
                        <div key={index}>
                          <div className="resume-div drop-zone">
                            <li className="row">
                              <div className="col-md-2" style={{ display: "flex", justifyContent: "center" }}>
                                <img
                                  className="resume-icon-2"
                                  src="https://webcdn.imarticus.org/Placement/fluent_document-pdf-24-filled.svg"
                                />
                              </div>
                              <div className="col-md-4">
                                <h5 className="resume-heading">Resume {index + 1}</h5>
                              </div>
                              <div className="col-md-3">
                                <p>{moment(file.createdAt).format("DD-MM-YYYY")}</p>
                              </div>
                              <div className="col-md-1"></div>
                              <div className="col-md-2">
                                <img
                                  src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                                  className="as-dl-logo-job-2"
                                  onClick={() => previewResumeClick(file.resume_url)}
                                />
                              </div>
                            </li>
                          </div>
                          <br />
                        </div>
                      ))}

                    {selectedLearnerResume && selectedLearnerResume
                      .filter((file) => file.resume_type === 1)
                      .map((file, index) => (
                        <div key={index}>
                          <div className="resume-div drop-zone">
                            <li className="row">
                              <div className="col-md-2" style={{ display: "flex", justifyContent: "center" }}>
                                <img
                                  className="resume-icon-2"
                                  src="https://webcdn.imarticus.org/Placement/mingcute_video-fill.svg"
                                />
                              </div>
                              <div className="col-md-4">
                                <h5 className="resume-heading">Video Resume {index + 1}</h5>
                              </div>
                              <div className="col-md-3">
                                <p>{moment(file.createdAt).format("DD-MM-YYYY")}</p>
                              </div>
                              <div className="col-md-1"></div>
                              <div className="col-md-2">
                                <a href={file.resume_url} target="_blank" rel="noopener noreferrer">
                                  <img
                                    src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                                    className="as-dl-logo-job-2"
                                    alt="View Resume"
                                  />
                                </a>
                              </div>
                            </li>
                          </div>
                          <br />
                        </div>
                      ))}
                  </>
                )}

              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={() => handleResumeModalClose()}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>

            <Modal className="jobs-grantaccessmodal" isOpen={openChangeRound} centered>
              <ModalHeader>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: "20px", fontWeight: "700" }}>
                  Update Round Status
                </div>
              </ModalHeader>
              <ModalBody
                className="thumbModal"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <li className="row">
                  <div className="col-md-12">
                    <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px", fontWeight: "600" }}>
                      Select Round
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Autocomplete
                        disableClearable
                        value={selectedRound.name}
                        onChange={(event, round) => {
                          selectedRoundChanged(round)
                        }}
                        getOptionLabel={(batch) => {
                          return batch.name || batch
                        }}
                        getOptionSelected={(option, test) => option.name == test}
                        id="batchSelector"
                        options={roundsDropdown}
                        disabled={roundsDropdown.length === 0 ? true : false}
                        placeholder="Select Round"
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                            />
                          )
                        }}
                      />
                    </FormGroup>
                  </div>
                </li>
                <li className='row'>
                  <div className="col-md-12">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px", fontWeight: "600" }}>
                      Remarks (Optional)
                    </h5>
                    <FormGroup>
                      <Input
                        type="textarea"
                        name="text"
                        id="courseShortCode"
                        rows={6}
                        value={remarks}
                        onChange={(event, oppID) => {
                          setRemarks(event.target.value)
                        }}
                        style={{ width: '98%' }}
                      />
                    </FormGroup>
                    <p>Note: Move selected learners to the next round by selecting the round from the dropdown menu or mark learners as Not selected in case they have been rejected</p>
                  </div>

                </li>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={() => handleModalClose()}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  onClick={() => {
                    changeLearnerRoundStatus()
                  }}
                  disabled={Object.keys('selectedRound').length === 0 ? true : false}
                >
                  Update
                </Button>
              </ModalFooter>
            </Modal>

            <Modal className="jobs-grantaccessmodal" isOpen={openPrelimChange} centered>
              <ModalHeader>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: "20px", fontWeight: "700" }}>
                  Change Status
                </div>
              </ModalHeader>
              <ModalBody
                className="thumbModal"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <li className="row">
                  <div className="col-md-12">
                    <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px", fontWeight: "600" }}>
                      Select Status
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Autocomplete
                        disableClearable
                        value={selectedRound.name}
                        onChange={(event, round) => {
                          selectedRoundChanged(round)
                        }}
                        getOptionLabel={(batch) => {
                          return batch.name || batch
                        }}
                        getOptionSelected={(option, test) => option.name == test}
                        id="batchSelector"
                        options={roundsDropdown}
                        disabled={roundsDropdown.length === 0 ? true : false}
                        placeholder="Select Round"
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                            />
                          )
                        }}
                      />
                    </FormGroup>
                  </div>
                </li>
                <li className='row'>
                  <div className="col-md-12">
                    <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px", fontWeight: "600" }}>
                      Remarks (Optional)
                    </h5>
                    <FormGroup>
                      <Input
                        type="textarea"
                        name="text"
                        id="courseShortCode"
                        rows={6}
                        value={remarks}
                        onChange={(event, oppID) => {
                          setRemarks(event.target.value)
                        }}
                        style={{ width: '98%' }}
                      />
                    </FormGroup>
                    <p>Note: Move final shortlisted learners to the assessment rounds by selecting the “Move to Assessment” option from the dropdown list. It moves the learner to the first assessment round by default.</p>
                  </div>

                </li>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={() => handleModalClose()}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  onClick={() => {
                    changeLearnerRoundStatus()
                  }}
                  disabled={Object.keys('selectedRound').length === 0 ? true : false}
                >
                  Change
                </Button>
              </ModalFooter>
            </Modal>

            <Modal className="jobs-grantaccessmodal" isOpen={openSendReminder} style={{ maxWidth: "350px" }} centered>
              <ModalHeader>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Send Reminder
                </div>
              </ModalHeader>
              <ModalBody
                className="thumbModal"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <li className="row">
                  <div className="col-md-12">
                    <h5 className="job-apply-reminder" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                      Type of Reminder
                    </h5>
                    <FormGroup>
                      <Input
                        type="text"
                        name="text"
                        id="tempQuestion"
                        value={'Reminder to Apply for this Job'}
                        disabled
                        style={{ width: '98%' }}
                      />
                    </FormGroup>
                  </div>
                  <div className='apply-reminder-txt'>
                    <p >Note: The reminder will only be sent to those learners who haven’t applied to this job yet</p>
                  </div>

                </li>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={() => handleModalClose()}
                >
                  Cancel
                </Button>
                <Button
                  style={{ background: "#014644" }}
                  onClick={() => {
                    sendJobApplyReminder()
                  }}
                >
                  Send
                </Button>
              </ModalFooter>
            </Modal>

            <Modal className="jobs-grantaccessmodal" isOpen={openUpdateStatus} style={{ maxWidth: "350px" }} centered>
              <ModalHeader>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Update Placement Status
                </div>
              </ModalHeader>
              <ModalBody
                className="thumbModal"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <li className="row">
                  <div className="col-md-12">
                    <h5 className="job-apply-reminder" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                      Select Status
                    </h5>
                    <FormGroup>
                      <Select
                        options={offerOptions}
                        placeholder="Select Offer Status"
                        value={selectedOfferStatus}
                        onChange={(event) => {
                          handleSelectedOfferChange(event)
                        }}
                      />
                    </FormGroup>
                  </div>
                </li>
                {selectedOfferStatus.value === 1 && (
                  <li className="row">
                    <div className="col-md-12">
                      <h5 className="job-apply-reminder" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                        Enter CTC Offered (Rs)
                      </h5>
                      <FormGroup>
                        <Input
                          type="number"
                          name="text"
                          min={0}
                          max={50000000}
                          id="tempQuestion"
                          value={ctcOffered}
                          onChange={(e) => {
                            if (e.target.value < 50000000) {
                              setCtcOffered(e.target.value);
                            }
                          }}
                          style={{ width: '98%' }}
                        />
                      </FormGroup>
                    </div>
                  </li>
                )}

              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={() => handleModalClose()}
                >
                  Cancel
                </Button>
                <Button
                  style={{ background: "#014644" }}
                  onClick={() => {
                    updateLearnerOfferStatus()
                  }}
                >
                  Send
                </Button>
              </ModalFooter>
            </Modal>

            <Modal className="jobs-grantaccessmodal" isOpen={openCTCUpdate} style={{ maxWidth: "350px" }} centered>
              <ModalHeader>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Update Learner CTC
                </div>
              </ModalHeader>
              <ModalBody
                className="thumbModal"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <li className="row">
                  <div className="col-md-12">
                    <h5 className="job-apply-reminder" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                      Enter CTC Offered (Rs)
                    </h5>
                    <FormGroup>
                      <Input
                        type="number"
                        name="text"
                        min={0}
                        max={50000000}
                        id="tempQuestion"
                        value={ctcOffered}
                        onChange={(e) => {
                          if (e.target.value < 50000000) {
                            setCtcOffered(e.target.value);
                          }
                        }}
                        style={{ width: '98%' }}
                      />
                    </FormGroup>
                  </div>
                </li>

              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={() => handleModalClose()}
                >
                  Cancel
                </Button>
                <Button
                  style={{ background: "#014644" }}
                  onClick={() => {
                    updateLearnerCTC()
                  }}
                >
                  Send
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </>
    </div>
  )

}


export default SingleJobApplication