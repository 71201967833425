import React, { useEffect, useState, Fragment } from 'react'
import TopBar2 from './Components/TopBar2'
import Select from "react-select"
import Sidebar3 from './Components/Sidebar3'
import './PlacementPortal.scss'
import momentjs from 'moment-timezone'
import '../../Components/style/assignmentDeadline.css'
import TooltipItem from '../ManageGradebookAndCertification/PreviewGradebook/TooltipItem.js'
import { saveAs } from 'file-saver';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Autocomplete,
  TextField,
  ToggleButton, ToggleButtonGroup
} from '@mui/material'
import PDFReader from "../../Components/PDFReader/PDFReader";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormGroup,
} from 'reactstrap'
import { COUNTRY_CODES, GENDER_DROPDOWN, TIER_CONDITION, TIER_DROPDOWN, BOARD_DROPDOWN, BACHELORS_DROPDOWN, MASTERS_DROPDOWN, PLACEMENT_ELIGIBILITY, PASSING_YEAR, JOB_EXPERIENCE, BOOLEAN_DROPDOWN, SKILL_CONDITION, FILTER_CONDITION, JOIN_CONDITION, PERIODICITY } from "../../constants/constant";
import MiniHeader from './Components/MiniHeader'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'
import { LOCATION_CHANGE } from '../../actions/actiontypes.js'
import { history } from '../..'
import GradebookModal from '../../Components/GradebookModal/GradebookModal.jsx'
import {
  savePlacementCriteria,
  fetchFilteredCandidates,
  finalizeLearners,
  publishJob,
  updatedEndTime
} from '../../actions/jobAction.js'
import Form from '@rjsf/core'
const configs = require('../../config/configs.js')
const timezones = momentjs.tz.names()



const AddJobRolloutFilters = () => {
  const [requests, setRequests] = useState([])
  const [openSelectedFinalized, setOpenSelectedFinalized] = useState(false)
  const [openFilterChoice, setOpenFilterChoice] = useState(false)
  const [rawFilter, setRawFilter] = useState({})
  const [filterForProduct, setFilterForProduct] = useState({})
  const [rawFilterData, setRawFilterData] = useState({})
  const [filterData, setFilterData] = useState({})
  const [togglePDFDoc, setTogglePDFDoc] = useState(false);
  const [isAddFilterCollapsed, setIsAddFilterCollapsed] = useState(false)
  const [isJobReminderCollapsed, setIsJobReminderCollapsed] = useState(false)
  const [isAppDateCollapsed, setIsAppDateCollapsed] = useState(false)
  const [resumeURL, setResumeURL] = useState("");
  const [numRule, setNumRule] = useState(0)
  const [rules, setRules] = useState({})
  const [tierCondition, setTierCondition] = useState({})
  const [refresh, setRefresh] = useState(false)
  const [hoverImage, setHoverImage] = useState(false)
  const [eligibleProducts, setEligibleProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState([])
  const [openedProduct, setOpenedProduct] = useState('')
  const [selectedBatches, setSelectedBatches] = useState({})
  const [originalBatches, setOriginalBatches] = useState([])
  const [isJobPublished, setIsJobPublished] = useState(false)
  const [candidatesFetched, setCandidatesFetched] = useState(false)
  const [candidatesRequested, setCandidatesRequested] = useState(false)
  const [eligibleSelected, setEligibleSelected] = useState(true)
  const [ineligibleSelected, setIneligibleSelected] = useState(false)
  const [placedSelected, setPlacedSelected] = useState(false)
  const [numPlacedLearners, setNumPlacedLearners] = useState(0)
  const [numEligibleLearners, setNumEligibleLearners] = useState(0)
  const [numIneligibleLearners, setNumIneligibleLearners] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [addedInfo, setAddedInfo] = useState('')
  const [periodVal, setPeriodVal] = useState(0)
  const [totalLearners, setTotalLearners] = useState(0)
  const [selectedPeriod, setSelectedPeriod] = useState('')
  const [placedCandidates, setPlacedCandidates] = useState([])
  const [eligibleCandidates, setEligibleCandidates] = useState([])
  const [ineligibleCandidates, setIneligibleCandidates] = useState([])
  const [data, setData] = useState([])
  const [batches, setBatches] = useState({})
  const [eligibleSkills, setEligibleSkills] = useState({})
  const pathname = window.location.pathname
  const jobid = pathname.split('/').slice(-1)[0]
  const [eligibleArray, setEligibleArray] = useState([])
  const [ineligibleArray, setIneligibleArray] = useState([])
  const [selectedLearnerResume, setSelectedLearnerResume] = useState([])
  const [selectedLearner, setSelectedLearner] = useState({})
  const [openResumeView, setOpenResumeView] = useState(false)
  const [searchNameKey, setSearchNameKey] = useState('')
  const [finalSearchNameKey, setFinalSearchNameKey] = useState('')
  const [finalSelectedSearchNameKey, setFinalSelectedSearchNameKey] = useState('')
  const [showPrevFetched, setShowPrevFetched] = useState(true)
  const [selectedStudents, setSelectedStudents] = useState([])
  const [formdata, setFormdata] = useState({
    valid_from: '',
    valid_till: '',
    timezone: 'Asia/Kolkata',
  })
  const [openPublishModal, setOpenPublishModal] = useState(false)
  const [openChangeEndDateModal, setOpenChangeEndDateModal] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [openGradebookModal, setOpenGradebookModal] = useState(false)
  const [grddata, setGrdData] = useState({})
  const [enableCategory, setEnableCategory] = useState({})
  const [elecNameMap, setElecNameMap] = useState({})
  const [adata, setAdata] = useState({})
  const [grade, setGrade] = useState({})
  const [grades, setGrades] = useState()
  const [assignmentData, setAssignmentData] = useState()
  const [quizData, setQuizData] = useState([])
  const [loading, setLoading] = useState(false)
  const [gradebookLoaded, setGradebookLoaded] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [studentGradebook, setStudentGradebook] = useState([])
  const [collapseRow, setCollapseRow] = useState({})
  const [grdTotalPerc, setGrdTotalPerc] = useState({});
  const [allAssessmentPublished, setAllAssessmentPublished] = useState(false);

  const [grdPlsStatus, setGrdPlsStatus] = useState({})
  const [showWeightage, setShowWeightage] = useState(false)
  const [showPassMarks, setShowPassMarks] = useState(false)
  const [showPlcStatus, setShowPlcStatus] = useState(false)
  const [placementEnable, setPlacementEnable] = useState(false)
  const [columnSpan, setColumnSpan] = useState(false)
  const [gradebookType, setGradebookType] = useState(1)
  const [isElec, setIsElec] = useState(false)
  const [gradebookSkillTags, setGradebookSkillTags] = useState({})
  const [skillNameMap, setskillNameMap] = useState({})
  const [skillTagPresent, setSkillTagPresent] = useState(false)

  const [showPlcPassStatus, setShowPlcPassStatus] = useState(
    'Congratulations! You are eligible for Placement Criteria'
  )
  const [showPlcFailStatus, setShowPlcFailStatus] = useState(
    'You are not eligible for the Placement.  '
  )
  const [isAllEligibleSelected, setIsAllEligibleSelected] = useState(false)
  const [isAllIneligibleSelected, setIsAllIneligibleSelected] = useState(false)
  const [plcPassStatusText, setPlcPassStatusText] = useState('PASS')
  const [plcFailStatusText, setPlcFailStatusText] = useState('FAIL')
  const [showContentStatus, setShowContentStatus] = useState(false)
  const [totalWeightAssignedP, setTotalWeightAssignedP] = useState();
  const [sameFilterMap, setSameFilterMap] = useState({});

  const [selectedEligible, setSelectedEligible] = useState([])
  const [selectedIneligible, setSelectedIneligible] = useState([])
  const [selectedAids, setSelectedAids] = useState([])
  let serialNum = 1
  let selectedSerialNum = 1
  let finalSelectedNum = 1
  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const getEligibleProducts = () => {
      const cookies = new Cookies()
      setIsLoading(true)
      return new Promise((resolve, reject) => {
        let data = {
          jobid: jobid,
          aid: cookies.get('aid'),
        }
        var getData = {
          url: `${configs.routes.BASE_URL_DEVELOPMENT}/cfpanelPlacement/get_placementfilter_products`,
          method: 'POST',
          data: data,
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
        Axios(getData)
          .then((res) => {
            console.log(res.data.data)
            let publish_stts = res.data.data && res.data.data.isJobPublished
              ? res.data.data.isJobPublished
              : false
            setIsJobPublished(publish_stts)
            let tmp_data =
              res.data.data && res.data.data.productDetails
                ? res.data.data.productDetails
                : []
            let addedProducts = res.data.data && res.data.data.filtered_courses
              ? res.data.data.filtered_courses
              : []
            let result = []
            for (let d = 0; d < tmp_data.length; d++) {
              if (tmp_data[d].name) {
                result.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                  job_status: tmp_data[d].job_status,
                })
              }
            }
            setEligibleProducts(result)
            if (addedProducts.length > 0) {
              let selectedProducts = result.filter(product =>
                addedProducts.includes(product._id)
              );

              setSelectedProduct(selectedProducts);
            }
            getFilteredCandidates()
            setIsLoading(false)
            resolve(result)
          })
          .catch((err) => {
            setIsLoading(false)
            console.error(err)
            reject(err)
          })
      })
    }
    getEligibleProducts()
  }, [refresh, page, rowsPerPage])

  useEffect(() => {
    setNumEligibleLearners(eligibleCandidates.length)
    setNumIneligibleLearners(ineligibleCandidates.length)
  }, [eligibleCandidates]
  )

  const handleFinalModalOpen = () => {
    setOpenSelectedFinalized(true)
  }

  const handleFinalModalClose = () => {
    setOpenSelectedFinalized(false)
  }

  const finalizeLearnersForCourse = async (
  ) => {
    const productIds = selectedProduct.map(p => p._id);
    if (eligibleArray.length == 0 && ineligibleArray.length === 0) {
      window.alert("Please select learners to give job access")
      return;
    }
    setIsLoading(true)
    let resp = await finalizeLearners(
      productIds,
      selectedBatches,
      eligibleArray,
      ineligibleArray,
      filterData,
      rules,
      jobid,
      eligibleCandidates,
      ineligibleCandidates,
      placedCandidates,
      tierCondition
    )
    if (resp.success) {
      window.alert('Successfully finalised candidates')
      setIsAllEligibleSelected(false)
      setIsAllIneligibleSelected(false)
      ineligibleArray.forEach((aid) => {
        // const index = ineligibleCandidates.findIndex(candidate => candidate.aid === aid);
        // if (index !== -1) {
        //   const [removedCandidate] = ineligibleCandidates.splice(index, 1);
        //   eligibleCandidates.push(removedCandidate);
        // }
      });
      setIneligibleArray([])
      setEligibleArray([])
      setIsLoading(false)
      setRefresh(!refresh)
    } else {
      window.alert(resp.message)
      // props.batchChanged(batchName, cid)
      setIsLoading(false)
    }
  }

  const handleResumeModalClose = () => {
    setOpenResumeView(false)
  }

  const handleEligibleResumeModalOpen = (aid) => {
    console.log({ aid })

    const selectedResume = eligibleCandidates.find((item) => String(item.aid) === String(aid));
    console.log({ eligibleCandidates })
    console.log({ selectedResume })
    if (selectedResume) {
      setSelectedLearner(selectedResume)
      setSelectedLearnerResume(selectedResume.resumes_list);
      console.log('Opening Resume Modal');
      console.log('Selected Resume:', selectedResume.resumes_list);
    }
    setOpenResumeView(true)
  };

  const handleIneligibleResumeModalOpen = (aid) => {
    console.log({ aid })

    const selectedResume = ineligibleCandidates.find((item) => String(item.aid) === String(aid));
    console.log({ ineligibleCandidates })
    console.log({ selectedResume })
    if (selectedResume) {
      setSelectedLearner(selectedResume)
      setSelectedLearnerResume(selectedResume.resumes_list);
      console.log('Opening Resume Modal');
      console.log('Selected Resume:', selectedResume.resumes_list);
    }
    setOpenResumeView(true)
  };

  const handlePlacedResumeModalOpen = (aid) => {
    console.log({ aid })

    const selectedResume = placedCandidates.find((item) => String(item.aid) === String(aid));
    console.log({ placedCandidates })
    console.log({ selectedResume })
    if (selectedResume) {
      setSelectedLearner(selectedResume)
      setSelectedLearnerResume(selectedResume.resumes_list);
      console.log('Opening Resume Modal');
      console.log('Selected Resume:', selectedResume.resumes_list);
    }
    setOpenResumeView(true)
  };

  const publishJobForLearners = async (
  ) => {
    setIsLoading(true)
    let resp = await publishJob(
      // selectedProduct._id,
      jobid,
      formdata,
      selectedPeriod,
      periodVal
    )
    if (resp.success) {
      // window.alert('Job successfully published and learners will be notified')
      setOpenPublishModal(false)
      setOpenSuccessModal(true)
      setIsLoading(false)
      setRefresh(!refresh)
      // history.push(`/career-module/preview-job-description/${jobid}`)
    } else {
      window.alert(resp.message)
      setIsLoading(false)
    }
  }

  const updateEndTimeForLearners = async (
  ) => {
    setIsLoading(true)
    let resp = await updatedEndTime(
      // selectedProduct._id,
      jobid,
      formdata
    )
    if (resp.success) {
      // window.alert('Job successfully published and learners will be notified')
      setOpenChangeEndDateModal(false)
      // setOpenSuccessModal(true)
      setIsLoading(false)
      setRefresh(!refresh)
      // history.push(`/career-module/preview-job-description/${jobid}`)
    } else {
      window.alert(resp.message)
      setIsLoading(false)
    }
  }

  const previewResumeClick = (url) => {
    setResumeURL(url);
    setTogglePDFDoc(true);
  };

  const handleTotalGradeClick = (cid, aid) => {
    const tmp_data = ineligibleCandidates.find(item => item.aid === aid);

    console.log({ tmp_data })
    if (tmp_data) {
      console.log("reached here")
      setGrdData(tmp_data.gradeData.grddata)
      setEnableCategory(tmp_data.gradeData.enableCategory)
      setElecNameMap(tmp_data.gradeData.elecNameMap)
      setGrade(tmp_data.gradeData.tgrade)
      setAdata(tmp_data.gradeData.adata)

      if (tmp_data.courseData && tmp_data.gradeData.grddata) {
        console.log("reached here as well")
        setShowContentStatus(tmp_data.courseData.show_content_status)
        setIsElec(tmp_data.courseData.elec_level_index)
        setShowPassMarks(tmp_data.courseData.show_pass_marks)
        setShowWeightage(tmp_data.courseData.show_weightage)
        setGrdPlsStatus(tmp_data.gradeData.grddata.plcStaus)
        let pls_status = tmp_data.courseData.plac_type ? tmp_data.courseData.plac_type.gradebook_show_plcmnt_stts : false
        setShowPlcStatus(pls_status)

        let passStatus = tmp_data.courseData.plac_type
          ? tmp_data.courseData.plac_type.plc_pass_text
          : "Congratulations! You are eligible for Placement Criteria";
        let failStatus = tmp_data.courseData.plac_type
          ? tmp_data.courseData.plac_type.plc_fail_text
          : "You are not eligible for the Placement.  ";
        let plcpassStatus = tmp_data.courseData.plac_type
          ? tmp_data.courseData.plac_type.plc_pass_status_text
          : "PASS";
        let plcfailStatus = tmp_data.courseData.plac_type
          ? tmp_data.courseData.plac_type.plc_fail_status_text
          : "FAIL";

        setPlcPassStatusText(plcpassStatus);
        setPlcFailStatusText(plcfailStatus);
        setShowPlcFailStatus(failStatus);
        setShowPlcPassStatus(passStatus);
        setPlacementEnable(tmp_data.courseData.plcmnt_enable)
        setGrdTotalPerc(tmp_data.gradeData.grddata.grd_total_perc);
        setStudentGradebook([...tmp_data.gradeData.grddata.placementGradebookCategory])

        setOpenGradebookModal(true)
      }
    }
  }

  const allEligibleStudentSelect = async () => {
    // setTotalCheckboxes(0)
    const checkboxes = document.querySelectorAll('.btch-dt-inp-eligible')
    let duplicateDetected = false
    let elgArray = []
    for (let i = 0; i < eligibleCandidates.length; i++) {
      const candidate = eligibleCandidates[i];
      const existsInElgArray = elgArray.some(elg => elg.aid === candidate.aid);
      const existsInInelgArray = ineligibleArray.some(elg => elg.aid === candidate.aid);

      if (existsInElgArray || existsInInelgArray) {
        window.alert("Some candidates are repeatedly selected. Cannot select all.");
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
        duplicateDetected = true
        break; // Exit the loop if a duplicate candidate is found
      }

      elgArray.push(candidate);
    }
    if (!duplicateDetected) {
      setEligibleArray(elgArray);

      if (isAllEligibleSelected) {
        setIsAllEligibleSelected(false)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false
        })
        setEligibleArray([])
      } else {
        setIsAllEligibleSelected(true)
        checkboxes.forEach((checkbox) => {
          if (!checkbox.checked) {
            checkbox.checked = true
          }
        })

      }
    }

  }

  const allIneligibleStudentSelect = async () => {
    const checkboxes = document.querySelectorAll('.btch-dt-inp-ineligible')
    let duplicateDetected = false
    let inelgArray = []
    for (let i = 0; i < ineligibleCandidates.length; i++) {
      const candidate = ineligibleCandidates[i];
      const existsInElgArray = eligibleArray.some(elg => elg.aid === candidate.aid);
      const existsInInelgArray = inelgArray.some(elg => elg.aid === candidate.aid);

      if (existsInElgArray || existsInInelgArray) {
        window.alert("Some candidates are repeatedly selected. Cannot select all.");
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
        duplicateDetected = true
        break; // Exit the loop when a duplicate candidate is found
      }

      inelgArray.push(candidate);
    }

    if (!duplicateDetected) {
      setIneligibleArray(inelgArray);

      if (isAllIneligibleSelected) {
        setIsAllIneligibleSelected(false)
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false
        })
        setIneligibleArray([])
      } else {
        setIsAllIneligibleSelected(true)
        checkboxes.forEach((checkbox) => {
          if (!checkbox.checked) {
            checkbox.checked = true
          }
        })
        // let inelgArray = []
        // ineligibleCandidates.forEach(candidate => {
        //   inelgArray.push(candidate.aid);
        // });
        // setIneligibleArray(inelgArray)
      }
    }

  }

  const singleEligibleStudentSelect = async (aid, crs_pg_id,cid) => {
    const checkboxes = document.querySelectorAll('.btch-dt-inp-eligible') // Select all checkboxes with the specified class name
    // setTotalCheckboxes(props.totalStudents - props.totalUnsubStudents)
    console.log({ checkboxes })
    const existsInElgArray = eligibleArray.some(elg => String(elg.aid) === String(aid));
    const existsInInelgArray = ineligibleArray.some(elg => String(elg.aid) === String(aid));
    // const selectedAlready = selectedAids.includes(String(aid))
    const crsPgIdInElgArray = eligibleArray.find(elg => String(elg.aid) === String(aid))?.crs_pg_id;
    const crsPgIdInInelgArray = ineligibleArray.find(elg => String(elg.aid) === String(aid))?.crs_pg_id;

    if ((existsInElgArray && crsPgIdInElgArray !== crs_pg_id) ||
      (existsInInelgArray && crsPgIdInInelgArray !== crs_pg_id)) {
      window.alert("This candidate is already selected.");
      checkboxes.forEach((checkbox) => {
        // Uncheck the checkbox if the aid and crs_pg_id match
        if (checkbox.id == aid && checkbox.name == crs_pg_id && checkbox.title == cid) {
          checkbox.checked = false;
        }
      });
      return;
    } else {

      checkboxes.forEach((checkbox) => {
        const checkboxCrsPgId = checkbox.name;
        if (checkbox.id == aid && checkboxCrsPgId == crs_pg_id && checkbox.title == cid) {
          if (checkbox.checked) {
            // setCheckedCheckboxes((pre) => pre + 1)
            // if (eligibleArray.indexOf(aid) === -1) {
            setEligibleArray((prev) => {
              const candidate = eligibleCandidates.find(c => c.aid === aid && c.crs_pg_id === crs_pg_id);
              if (!candidate) {
                window.alert("Candidate not found.");
                return prev;
              }
              // if (prev.some(c => c.aid === aid)) {
              //   window.alert("This candidate is already selected.Please deselect at one place");
              //   return prev; 
              // }
              return [...prev, candidate];
            });
            // }
          } else {
            // setCheckedCheckboxes((pre) => pre - 1)
            setEligibleArray((prev) => {
              // Check if the candidate's aid exists in the eligibleArray
              if (prev.find(c => c.aid === aid)) {
                // Remove the candidate by filtering out the one with the matching aid
                return prev.filter((item) => item.aid !== aid);
              }
            })
          }
        }
      })
    }
    console.log("Eligible Array is ", eligibleArray)
  }


  const singleIneligibleStudentSelect = async (aid, crs_pg_id,cid) => {
    const checkboxes = document.querySelectorAll('.btch-dt-inp-ineligible');

    console.log({ checkboxes })
    const existsInElgArray = eligibleArray.some(elg => String(elg.aid) === String(aid)); // Compare strings
    const existsInInelgArray = ineligibleArray.some(elg => String(elg.aid) === String(aid));
    // const selectedAlready = selectedAids.includes(String(aid))

    const crsPgIdInElgArray = eligibleArray.find(elg => String(elg.aid) === String(aid))?.crs_pg_id;
    const crsPgIdInInelgArray = ineligibleArray.find(elg => String(elg.aid) === String(aid))?.crs_pg_id;

    const cidInElgArray = eligibleArray.find(elg => String(elg.aid) === String(aid))?.cid;
    const cidInInelgArray = ineligibleArray.find(elg => String(elg.aid) === String(aid))?.cid;

    if ((existsInElgArray && crsPgIdInElgArray !== crs_pg_id && cidInElgArray !== cid) ||
      (existsInInelgArray && crsPgIdInInelgArray !== crs_pg_id && cidInInelgArray !== cid)) {
      window.alert("This candidate is already selected.");
      checkboxes.forEach((checkbox) => {
        // Uncheck the checkbox if the aid and crs_pg_id match
        if (checkbox.id == aid && checkbox.name == crs_pg_id && checkbox.title == cid) {
          checkbox.checked = false;
        }
      });
      return;

    } else {
      checkboxes.forEach((checkbox) => {
        const checkboxCrsPgId = checkbox.name;

        console.log({ lala: checkbox.id, abab: checkboxCrsPgId })
        if (checkbox.id == aid && checkboxCrsPgId == crs_pg_id && checkbox.title == cid) {
          console.log("in here")
          if (checkbox.checked) {
            console.log("checkbox is checked")
            setIneligibleArray((prev) => {
              const candidate = ineligibleCandidates.find(c => c.aid === aid && c.crs_pg_id === crs_pg_id);
              if (!candidate) {
                window.alert("Candidate not found.");
                return prev;
              }
              // if (prev.some(c => c.aid === aid)) {
              //   window.alert("This candidate is already selected.");
              //   return prev;
              // }
              const updatedArray = [...prev, candidate];
              console.log("Ineligible Array After Adding:", updatedArray); // Log the updated array
              return updatedArray;
            });
          } else {
            setIneligibleArray((prev) => {
              if (prev.find(c => c.aid === aid)) {
                const updatedArray = prev.filter((item) => item.aid !== aid);
                console.log("Ineligible Array After Removing:", updatedArray); // Log the updated array
                return updatedArray;
              }
              return prev;
            });
          }
        }
      });
    }
  };


  const handleEligibleLearners = () => {
    // const checkboxes = document.querySelectorAll('.btch-dt-inp-eligible')
    // if (isAllEligibleSelected) {
    //   checkboxes.forEach((checkbox) => {
    //     checkbox.checked = false;
    //   });
    // } else {
    //   for (let i = 0; i < checkboxes.length; i++) {
    //     const checkbox = checkboxes[i];
    //     const matchingCandidate = eligibleArray.find(elg => 
    //       String(elg.aid) === checkbox.id && 
    //       String(elg.crs_pg_id) === checkbox.name
    //     );
    //     console.log({matchingCandidate})
    //     if (matchingCandidate) {
    //       checkbox.checked = true; 
    //     } else {
    //       checkbox.checked = false;
    //     }
    //   }
    // }
    setSearchNameKey("")
    setIsAllEligibleSelected(false)
    setIsAllIneligibleSelected(false)
    setEligibleSelected(true);
    setIneligibleSelected(false);
    setPlacedSelected(false)

    setTimeout(() => {
      const checkboxes = document.querySelectorAll('.btch-dt-inp-eligible');
      console.log({ checkboxes });

      if (isAllEligibleSelected) {
        // Uncheck all checkboxes
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      } else {
        for (let i = 0; i < checkboxes.length; i++) {
          const checkbox = checkboxes[i];
          const matchingCandidate = eligibleArray.find(elg =>
            String(elg.aid) === checkbox.id &&
            String(elg.crs_pg_id) === checkbox.name &&
            String(elg.cid) === checkbox.title
          );
          console.log({ matchingCandidate });
          if (matchingCandidate) {
            checkbox.checked = true; // Check the checkbox if a matching candidate is found
          } else {
            checkbox.checked = false; // Uncheck if no matching candidate is found
          }
        }
      }
    }, 20);
  };
  const handleIneligibleLearners = () => {
    console.log("in handle ineligible learners")

    // const checkboxes = document.querySelectorAll('.btch-dt-inp-ineligible')

    // console.log({checkboxes})
    // if (isAllIneligibleSelected) {
    //   // Uncheck all checkboxes
    //   checkboxes.forEach((checkbox) => {
    //     checkbox.checked = false;
    //   });
    // } else {
    //   for (let i = 0; i < checkboxes.length; i++) {
    //     const checkbox = checkboxes[i];
    //     const matchingCandidate = ineligibleArray.find(elg => 
    //       String(elg.aid) === checkbox.id && 
    //       String(elg.crs_pg_id) === checkbox.name
    //     );
    //     console.log({matchingCandidate})
    //     if (matchingCandidate) {
    //       checkbox.checked = true; // Check the checkbox if a matching candidate is found
    //     } else {
    //       checkbox.checked = false; // Uncheck if there is no matching candidate
    //     }
    //   }
    // }

    setSearchNameKey("")
    setIsAllEligibleSelected(false)
    setIsAllIneligibleSelected(false)
    setEligibleSelected(false);
    setIneligibleSelected(true);
    setPlacedSelected(false);

    setTimeout(() => {
      const checkboxes = document.querySelectorAll('.btch-dt-inp-ineligible');
      console.log({ checkboxes });

      if (isAllIneligibleSelected) {
        // Uncheck all checkboxes
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      } else {
        for (let i = 0; i < checkboxes.length; i++) {
          const checkbox = checkboxes[i];
          const matchingCandidate = ineligibleArray.find(elg =>
            String(elg.aid) === checkbox.id &&
            String(elg.crs_pg_id) === checkbox.name &&
            String(elg.cid) === checkbox.title
          );
          console.log({ matchingCandidate });
          if (matchingCandidate) {
            checkbox.checked = true; // Check the checkbox if a matching candidate is found
          } else {
            checkbox.checked = false; // Uncheck if there is no matching candidate
          }
        }
      }
    }, 20);
  };
  const handlePlacedLearners = () => {
    setSearchNameKey("")
    setIsAllEligibleSelected(false)
    setIsAllIneligibleSelected(false)
    setEligibleSelected(false);
    setIneligibleSelected(false);
    setPlacedSelected(true)
  };

  const applySameFilter = (productId) => {

    console.log("called apply same filter")
    const selectedProductId = selectedProduct[0]._id;

    // Check if both product IDs exist in the rawFilter data
    const selectedProductFilter = rawFilter[selectedProductId];
    const targetProductFilter = rawFilter[productId];

    if (selectedProductFilter && targetProductFilter) {
      const selectedKeys = Object.keys(selectedProductFilter);
      const targetKeys = Object.keys(targetProductFilter);

      const sameKeys = selectedKeys.length === targetKeys.length && selectedKeys.every(key => targetKeys.includes(key));

      if (sameKeys) {
        setFilterData(prevFilterData => ({
          ...prevFilterData,
          [productId]: {
            ...prevFilterData[selectedProductId],
          }
        }));

        setSameFilterMap(prevMap => {
          if (prevMap[productId]) {
            // If the entry exists, remove it from the map
            const updatedMap = { ...prevMap };
            delete updatedMap[productId];
            return updatedMap;
          } else {
            // Otherwise, add the new mapping
            return {
              ...prevMap,
              [productId]: selectedProductId
            };
          }
        });
      } else {
        window.alert("Raw filters do not have the same keys.");
      }
    } else {
      window.alert("Filter data not found for the specified product IDs.");
    }
  }

  const selectAllBatches = (productId) => {

    console.log("select all batches")
    const allBatchesForProduct = batches[productId];

    if (allBatchesForProduct) {
      const selectedBatchValues = allBatchesForProduct.map(batch => batch.value);

      setSelectedBatches(prevState => ({
        ...prevState,
        [productId]: selectedBatchValues,
      }));
    }
  };

  useEffect(() => {
    if (selectedProduct.length > 0) {
      const productIds = selectedProduct.map(p => p._id);
      getJobFilterData(productIds);
      getJobSkillData(productIds)
      getAllBatches(productIds)
    } else {
      setEligibleCandidates([])
      setIneligibleCandidates([])
      setPlacedCandidates([])
    }
  }, [selectedProduct])

  const getJobSkillData = (crs_pg_ids) => {
    const cookies = new Cookies()
    return new Promise((resolve, reject) => {
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT
          }/cfpanelPlacement/get_product_skilltags`,
        method: 'POST',
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: { selectedProducts: crs_pg_ids },
      }
      Axios(getData)
        .then((res) => {
          console.log(res.data.data)
          let tmp_data =
            res.data.data && res.data.data
              ? res.data.data
              : []
          let result = []
          setEligibleSkills(tmp_data.skillTags)
          // const selectedSkillsObj = [];
          // if(tmp_data.skillTags.length > 0){
          //   alreadyAddedSkills.forEach(index => {
          //     const gender = tmp_data.skillTags.find(gender => gender.value == index);
          //     if (gender) {
          //       selectedSkillsObj.push(gender);
          //     }
          //   });
          //   setSelectedSkills(selectedSkillsObj)
          // }
          // setIsLoading(false)
          resolve(result)
        })
        .catch((err) => {
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  const handleBulkDownload = () => {
    if (selectedLearner && selectedLearner.resumes_list) {
      console.log("here")
      selectedLearner.resumes_list.forEach((resume) => {
        let count = 1
        if (resume.resume_type === 2) {
          fetch(resume.resume_url)
            .then((response) => response.blob())
            .then((blob) => {
              saveAs(blob, `${selectedLearner.aid}_${count}.pdf`);
            })
            .catch((error) => console.error('Error downloading resume:', error));
        }
        count++
      });
    }
  };

  const getAllBatches = (crs_pg_id) => {
    const cookies = new Cookies()
    return new Promise((resolve, reject) => {
      let data = {
        crs_pg_id: crs_pg_id,
        a_id: cookies.get('aid'),
      }
      // if (location_id) {
      //   data['location_id'] = location_id
      // }
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT}/cfpanelPlacement/list_batch`,
        method: 'POST',
        data: data,
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      Axios(getData)
        .then((res) => {
          let tmp_data =
            res.data.data && res.data.data.courses ? res.data.data.courses : {}
          let batchesByCourseId = {};
          let result = []
          Object.values(tmp_data).forEach(course => {
            console.log({ abcd: course })
            course.forEach(crs => {
              console.log({ crs })
              if (crs.btch_name != "") {
                console.log("here again")
                const crs_pg_id = crs.crs_pg_id;

                if (!batchesByCourseId[crs_pg_id]) {
                  batchesByCourseId[crs_pg_id] = [];
                }

                batchesByCourseId[crs_pg_id].push({
                  label: crs.btch_name,
                  value: crs._id,
                });
              }

            })


          })
          // if (!result.length) {
          //   alert('No batches found at this location')
          // }
          // result.unshift({ name: '', _id: null })
          console.log({ batchesByCourseId })
          setBatches(batchesByCourseId)
          resolve(result)
        })
        .catch((err) => {
          console.log('inside get data')
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  function convertUnixToDatetimeLocal(unixTimestamp) {
    const date = new Date(unixTimestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const getJobFilterData = (crs_pg_ids) => {
    const cookies = new Cookies()
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      let data = {
        jobid: jobid,
        aid: cookies.get('aid'),
        crs_pg_ids: crs_pg_ids
      }
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT}/cfpanelPlacement/get_placementfilter_data`,
        method: 'POST',
        data: data,
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      Axios(getData)
        .then((res) => {
          console.log(res.data.data)
          let tmp_data =
            res.data.data && res.data.data
              ? res.data.data
              : []
          let result = []
          let filterData = {};
          let rawFilters = {};
          for (let productId in tmp_data) {
            if (tmp_data.hasOwnProperty(productId)) {
              let productData = tmp_data[productId].combinedFilterFields;
              rawFilters[productId] = productData
              filterData[productId] = {};

              for (let key in productData) {
                if (productData.hasOwnProperty(key)) {

                  if (!['notice_period', 'grad_grade', 'matric_grade', 'inter_grade', 'total_exp'].includes(key)) {
                    filterData[productId][key] = {
                      key: productData[key],
                      selected: false,
                      type: 1,
                      condition: 1,
                      value: []
                    };
                  } else {
                    if (!['grad_grade', 'matric_grade', 'inter_grade'].includes(key)) {
                      if (key == 'total_exp') {
                        filterData[productId][key] = {
                          key: productData[key],
                          selected: true,
                          type: 2,
                          condition: 1,
                          value: 0
                        };
                      } else {
                        filterData[productId][key] = {
                          key: productData[key],
                          selected: false,
                          type: 2,
                          condition: 1,
                          value: 0
                        };
                      }
                    } else {
                      filterData[productId][key] = {
                        key: productData[key],
                        selected: false,
                        type: 3,
                        condition_cgpa: 1,
                        condition_perc: 1,
                        value_cgpa: 0,
                        value_perc: 0
                      };
                    }
                  }

                }
              }
            }
          }
          console.log({ filterData })
          setRawFilter(rawFilters)
          setFilterData(filterData)
          for (let productId in tmp_data) {
            if (tmp_data.hasOwnProperty(productId)) {
              if (tmp_data[productId].placementJobFilter && Object.keys(tmp_data[productId].placementJobFilter).length !== 0) {

                setFilterData(prevState => ({
                  ...prevState,
                  [productId]: tmp_data[productId].placementJobFilter.filterData,
                }));

                setRules(prevState => ({
                  ...prevState,
                  [productId]: tmp_data[productId].placementJobFilter.rules,
                }));

                setSelectedBatches(prevState => ({
                  ...prevState,
                  [productId]: tmp_data[productId].placementJobFilter.selected_batches,
                }));
                // setOriginalBatches(tmp_data[productId].placementJobFilter.selected_batches)


                setPeriodVal(tmp_data[productId].placementJobFilter?.reminder_val)
                setSelectedPeriod(tmp_data[productId].placementJobFilter?.reminder_freq)

                const stimLocal = convertUnixToDatetimeLocal(tmp_data[productId].placementJobFilter?.stim)
                const etimLocal = convertUnixToDatetimeLocal(tmp_data[productId].placementJobFilter?.etim)
                setFormdata({
                  ...formdata,
                  valid_from: stimLocal,
                  valid_till: etimLocal
                });


              }
            }
          }
          setIsLoading(false)
          resolve(result)
        })
        .catch((err) => {
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }


  const getFilteredCandidates = () => {
    const cookies = new Cookies()
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      let data = {
        jobid: jobid,
        aid: cookies.get('aid'),
      }
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT}/cfpanelPlacement/get_filtered_candidates`,
        method: 'POST',
        data: data,
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      Axios(getData)
        .then((res) => {
          console.log(res.data.data)
          let tmp_data =
            res.data.data && res.data.data
              ? res.data.data
              : []
          let result = []
          setSelectedEligible(tmp_data.eligibleCandidates)
          setSelectedIneligible(tmp_data.ineligibleCandidates)
          setSelectedAids(tmp_data.selectedAids)
          setIsLoading(false)
          resolve(result)
        })
        .catch((err) => {
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  const productChanged = (product) => {
    console.log({ product })
    setSelectedProduct(product)
    // if (product.length > 0) {
    //   const productIds = product.map(p => p._id); 
    //   getJobFilterData(productIds);
    //   getJobSkillData(productIds)
    //   // getAllBatches()
    // }else{
    //   setEligibleCandidates([])
    //   setIneligibleCandidates([])
    //   setPlacedCandidates([])
    // }
    // getAllBatches(product._id)
    // getJobSkillData(product._id)

    // if (product.job_status > 2) {
    //   setIsJobPublished(true)
    // }
    setEligibleCandidates([])
    setIneligibleCandidates([])
    setPlacedCandidates([])

  }

  const fetchJobApplicants = () => {
    console.log("candidates fetched")
    // setOpenJobCreate(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  const singleFilterSelect = (key, productId) => {
    setFilterData((prevFilterData) => {
      if (prevFilterData[productId] && prevFilterData[productId][key]) {
        return {
          ...prevFilterData,
          [productId]: {
            ...prevFilterData[productId],
            [key]: {
              ...prevFilterData[productId][key],
              selected: !prevFilterData[productId][key].selected,
            }
          }
        };
      }
      return prevFilterData;
    });
  };



  const handleSelectedPeriodChange = (e) => {
    setSelectedPeriod(e.value);
  }

  const updateFormdata = (e) => {
    console.log({ formdata })
    console.log('updateFormadata is running')
    const newData = {
      ...formdata,
      [e.target.name]: e.target.value,
    }
    setFormdata(newData)
    // setMaxValidTill(new Date(props.finalCourseAccessDate))
  }

  const handleFilterChange = (key, value, productId) => {
    console.log({ key })
    console.log({ value })
    setFilterData((prevState) => ({
      ...prevState,
      [productId]: {
        ...prevState[productId],
        [key]: {
          ...prevState[productId][key],
          value: value,
        },
      },
    }));
  };

  const handleDualFilterChange = (key, valkey, value, productId) => {
    setFilterData((prevState) => ({
      ...prevState,
      [productId]: {
        ...prevState[productId],
        [key]: {
          ...prevState[productId][key],
          [valkey]: value,
        },
      },
    }));
  };

  const deleteRule = (index, productId) => {
    if (Object.keys(selectedProduct).length < 1) {
      window.alert("Please select a product first")
      return
    }
    setRules((prevRules) => {
      const updatedProductRules = prevRules[productId]?.filter((rule) => rule.index !== index) || [];

      const reindexedProductRules = updatedProductRules.map((rule, newIndex) => ({
        ...rule,
        index: newIndex,
      }));
      return {
        ...prevRules,
        [productId]: reindexedProductRules,
      };
    });

    setNumRule(numRule - 1)
  };

  const deleteSubRule = (index, subruleIndex, productId) => {
    setRules((prevRules) => {
      if (!prevRules[productId]) return prevRules;

      const updatedProductRules = prevRules[productId].map((rule) => {
        if (rule.index === index) {
          const updatedSubrules = rule.subrules.filter(
            (subrule, idx) => idx !== subruleIndex
          );

          const reindexedSubrules = updatedSubrules.map((subrule, newSubruleIndex) => ({
            ...subrule,
            subruleIndex: newSubruleIndex,
          }));

          return {
            ...rule,
            subrules: reindexedSubrules,
          };
        }
        return rule;
      });

      const reindexedProductRules = updatedProductRules.map((rule, newIndex) => ({
        ...rule,
        index: newIndex,
      }));
      return {
        ...prevRules,
        [productId]: reindexedProductRules,
      };
    });
  };

  const handleTierConditionChange = (productId) => {

    if (Object.keys(tierCondition).length > 1) {
      console.log("in here duh")
    }

    if (Object.keys(selectedProduct).length < 1) {
      window.alert("Please select a product first")
      return
    }

    const newTC = {
      index: 0,
      tier: "",
      value: 0,
      type: "",
      condition: "",
      val_array: []
    };

    setTierCondition(prevTC => {
      return {
        ...prevTC,
        [productId]: {
          ...newTC
        }
      };
    });

    console.log("in handle tier Condition")
  };

  const handleTierConditionKeyChange = (event, field, productId) => {
    console.log(event)

    if (!tierCondition[productId]) {
      window.alert("No tiers condition found for this product.");
      return;
    }

    if (field === 'val_array') {
      setTierCondition(prevTC => {
        const updatedConditions = {
          ...prevTC,
          [productId]: {
            ...prevTC[productId],
            [field]: event,
          }
        };

        console.log({ updatedConditions });
        return updatedConditions;
      });
    } else {
      setTierCondition(prevTC => {
        const updatedConditions = {
          ...prevTC,
          [productId]: {
            ...prevTC[productId],
            [field]: event.value,
          }
        };

        console.log({ updatedConditions });
        return updatedConditions;
      });
    }

  };

  // const handleTierConditionValueChange = (event, index, field,productId) => {
  //   console.log(event.target.valueAsNumber)

  //   if (!rules[productId]) {
  //     window.alert("No rules found for this product.");
  //     return;
  //   }

  //   setRules(prevRules => {
  //       const updatedRules = {
  //           ...prevRules,
  //           [productId]: [...prevRules[productId]] 
  //       };
  //       updatedRules[productId][index][field] = event.target.valueAsNumber;

  //       console.log({ updatedRules });
  //       return updatedRules;
  //   });
  // };

  const deleteTierCondition = (productId) => {
    if (Object.keys(selectedProduct).length < 1) {
      window.alert("Please select a product first")
      return
    }
    setTierCondition((prevTC) => {
      if (!prevTC[productId]) {
        window.alert("No tier condition found for the selected product");
        return prevTC;
      }

      // Set the entry for the specific productId to an empty object
      return {
        ...prevTC,
        [productId]: {} // Set to empty object
      };
    });

  };

  const handleRuleChange = (num, productId) => {

    if (Object.keys(selectedProduct).length < 1) {
      window.alert("Please select a product first")
      return
    }

    if (num > 2) {
      window.alert("Number of Rules cant be greater than 2")
      return
    }

    const newRule = {
      index: num - 1,
      key: "",
      value: 0,
      type: "",
      condition: "",
      subrules: []
    };

    setRules(prevRules => {
      if (!prevRules[productId]) {
        return {
          ...prevRules,
          [productId]: [newRule]
        };
      } else {
        return {
          ...prevRules,
          [productId]: [...prevRules[productId], newRule]
        };
      }
    });
    setNumRule(numRule + 1)
    console.log({ newRule });
  };

  const handleSubruleAddition = (key, productId) => {
    if (!rules[productId]) {
      window.alert("No rules found for this product.");
      return;
    }

    const rule = rules[productId][key];

    if (rule.subrules.length >= 1) {
      window.alert("Number of subrules cannot exceed 1");
      return;
    }

    const newSubRule = {
      key: "",
      value: 0,
      type: "",
      condition: "",
      join: 0
    };

    const updatedRules = {
      ...rules,
      [productId]: rules[productId].map((rule, index) => {
        if (index === key) {
          return {
            ...rule,
            subrules: [...rule.subrules, newSubRule]
          };
        }
        return rule;
      })
    };
    console.log({ rules })
    console.log({ key })
    console.log({ updatedRules })
    setRules(updatedRules);
  };

  const handleSelectedBatchChange = (value, action, productId) => {
    console.log({ value })
    console.log({ action })
    console.log({ productId })
    // if (action.action !== "remove-value" && action.action !== "clear") {
    const selectedBatch = value.map(option => option.value);
    // setSelectedBatches(selectedBatch);
    const updatedSelectedBatch = {
      [productId]: selectedBatch,
    };

    setSelectedBatches(prevState => ({
      ...prevState, // Spread the previous state to retain other keys
      [productId]: selectedBatch, // Update or add the new key-value pair for the current productId
    }));
    // }else if(action.action === "remove-value"){
    //   const selectedBatch = value.map(option => option.value);
    //   const allOriginalExist = originalBatches.every(batch => selectedBatch.includes(batch));

    //   if (allOriginalExist) {
    //     console.log(true);
    //     // setSelectedBatches(selectedBatch);
    //     const updatedSelectedBatch = {
    //       [productId]: selectedBatch,
    //     };
    //   }
    // }

    // console.log({selectedBatch})
    // let allSelectedBatch = selectedBatches
    // selectedBatch.forEach(option => {
    //   console.log({allSelectedBatch})
    //     if (!selectedBatches.includes(option.value)) {
    //       allSelectedBatch.push(option.value);
    //     }
    // });
    // console.log({allSelectedBatch})
    // console.log({selectedBatch})

  }

  const handleRuleKeyChange = (event, index, field, productId) => {
    console.log(event.value)

    if (!rules[productId]) {
      window.alert("No rules found for this product.");
      return;
    }

    setRules(prevRules => {
      const updatedRules = {
        ...prevRules,
        [productId]: [...prevRules[productId]]
      };

      updatedRules[productId][index][field] = event.value;

      console.log({ updatedRules });
      return updatedRules;
    });
  };

  const handleRuleValueChange = (event, index, field, productId) => {
    console.log(event.target.valueAsNumber)

    if (!rules[productId]) {
      window.alert("No rules found for this product.");
      return;
    }

    setRules(prevRules => {
      const updatedRules = {
        ...prevRules,
        [productId]: [...prevRules[productId]]
      };
      updatedRules[productId][index][field] = event.target.valueAsNumber;

      console.log({ updatedRules });
      return updatedRules;
    });
  };

  const handleSubRuleKeyChange = (event, index, subruleIndex, field, productId) => {
    // console.log({value})
    if (!rules[productId]) {
      window.alert("No subrules found for this product.");
      return;
    }

    setRules(prevRules => {
      const updatedRules = {
        ...prevRules,
        [productId]: [...prevRules[productId]]
      };

      updatedRules[productId][index].subrules[subruleIndex][field] = event.value;

      console.log({ updatedRules });
      return updatedRules;
    });
  };

  const handleSubRuleValueChange = (event, index, subruleIndex, field, productId) => {
    // console.log({value})
    if (!rules[productId]) {
      window.alert("No subrules found for this product.");
      return;
    }

    setRules(prevRules => {
      const updatedRules = {
        ...prevRules,
        [productId]: [...prevRules[productId]],
      };

      updatedRules[productId][index].subrules[subruleIndex][field] = event.target.valueAsNumber;

      console.log({ updatedRules });
      return updatedRules;
    });
  };

  const handleFilterSelection = (productId) => {
    if (Object.keys(selectedProduct).length < 1) {
      window.alert("Please select a product first")
      return
    }
    console.log({ productId })
    console.log({ rawFilter })
    const selectedFilters = rawFilter[productId];
    if (!selectedFilters) {
      window.alert("No filters found for the selected product");
      return;
    }
    setFilterForProduct(selectedFilters);
    setOpenedProduct(productId)
    openFilterChoice ? setOpenFilterChoice(false) : setOpenFilterChoice(true)
  }

  const fetchFilteredCandidatesDraft = async () => {
    setIsLoading(true)
    setCandidatesRequested(true)
    setCandidatesFetched(false)
    let crs_pg_id = selectedProduct._id
    const productIds = selectedProduct.map(p => p._id);
    console.log({ jobid })
    console.log({ productIds })
    let batchExists = true
    for (let i = 0; i < productIds.length; i++) {
      const productId = productIds[i];

      // Check if the selectedBatches[productId] is undefined, does not exist, or has a length of 0
      if (!selectedBatches[productId] || selectedBatches[productId].length === 0) {
        batchExists = false
        window.alert(`No batches selected for some courses`);
        setIsLoading(false); // Optionally stop loading if there's an error
        return; // Exit the function early
      }
    }

    if (batchExists) {
      fetchFilteredCandidates({
        productIds,
        selectedBatches,
        job_id: jobid,
        filterData,
        rules,
        tierCondition
      }).then((res) => {
        setIsLoading(false)
        console.log({ success: res })
        if (res.status != 200) {
          setCandidatesFetched(false)
          if (res.message) {
            window.alert(res.message);
          } else {
            window.alert('Something went wrong. Please try again.');
          }
        } else {
          // window.alert('Success')
          setCandidatesFetched(true)
          if (res) {
            console.log(res)
            setTotalLearners(res.learnerCount != null ? res.learnerCount : 0);
            // setEligibleCandidates(eligibleCandidates => {
            //   const newEligibleCandidates = res.eligibleCandidates.filter(candidate =>
            //     !eligibleCandidates.some(prevCandidate => prevCandidate.aid === candidate.aid)
            //   );
            //   console.log({ newEligibleCandidates })
            //   console.log({ eligibleCandidates })

            //   let len1 = newEligibleCandidates.length
            //   let len2 = eligibleCandidates.length
            //   setNumEligibleLearners(len1 + len2)
            //   return [
            //     ...eligibleCandidates,
            //     ...newEligibleCandidates
            //   ];
            // });
            setEligibleCandidates(res.eligibleCandidates)
            setIneligibleCandidates(res.ineligibleCandidates)

            // setTimeout(() => {
            //   sorting('name','eligible')
            // }, 1000);
            // setIneligibleCandidates(ineligibleCandidates => {
            //   console.log({ ineligibleCandidates })
            //   console.log({ eligibleCandidates })
            //   const newIneligibleCandidates = res.ineligibleCandidates.filter(candidate =>
            //     !ineligibleCandidates.some(prevCandidate => prevCandidate.aid === candidate.aid)
            //   );
            //   console.log({ newIneligibleCandidates })

            //   const newIneligibleCandidates2 = ineligibleCandidates.filter(candidate =>
            //     !res.eligibleCandidates.some(prevCandidate => prevCandidate.aid === candidate.aid)
            //   );
            //   console.log({ newIneligibleCandidates2 })

            //   let len1 = newIneligibleCandidates.length
            //   let len2 = newIneligibleCandidates2.length

            //   setNumIneligibleLearners(len1 + len2);

            //   console.log({ ineligibleCandidates })
            //   return [
            //     ...newIneligibleCandidates,
            //     ...newIneligibleCandidates2
            //   ];
            // })

            // Update placed candidates
            // setPlacedCandidates(prevPlacedCandidates => [
            //   ...prevPlacedCandidates,
            //   ...res.placedCandidates.filter(candidate =>
            //     !prevPlacedCandidates.some(prevCandidate => prevCandidate.aid === candidate.aid)
            //   )
            // ]);
            setPlacedCandidates(res.placedCandidates)

            // Update the counts
            setNumEligibleLearners(res.eligibleCandidates.length);
            setNumIneligibleLearners(res.ineligibleCandidates.length)
            setNumPlacedLearners(res.placedCandidates.length)
            // setNumPlacedLearners(prevNumPlacedLearners =>
            //   prevNumPlacedLearners + res.placedCandidates.length
            // );
            setShowPrevFetched(false)
            // setData()
          }

        }
      })
        .catch(error => {
          setCandidatesFetched(false)
          console.error('An error occurred:', error);
        });
    }
    // load()
    setIsLoading(false)
  }
  const toggleAddFilter = () => {
    setIsAddFilterCollapsed(!isAddFilterCollapsed);
  };
  const toggleJobFreq = () => {
    setIsJobReminderCollapsed(!isJobReminderCollapsed);
  };
  const toggleAppStart = () => {
    setIsAppDateCollapsed(!isAppDateCollapsed);
  };
  let displayedAids = new Set();
  let displayedSelected = new Set()
  let displayedPlaced = new Set();

  function getArrow(col) {
    console.log({ order })
    return order[col].arrow
  }

  //sorting logic for a particular coloumn
  const [order, setOrder] = useState({
    name: {
      arrow: 'https://cdn.pegasus.imarticus.org/sorting/Light.svg',
      sortMethod: 'ASC',
    },
    batch_name: {
      arrow: 'https://cdn.pegasus.imarticus.org/sorting/Light.svg',
      sortMethod: 'ASC',
    },
  })
  const sorting = (col,type) => {
    if (order[col].sortMethod === 'ASC') {
      let sorted = []
      if(type == 'placed'){
        sorted = [...placedCandidates].sort((a, b) =>
          a[col].toString().toLowerCase() > b[col].toString().toLowerCase()
            ? 1
            : -1
        )
      }else if( type == 'ineligible'){
        sorted = [...ineligibleCandidates].sort((a, b) =>
          a[col].toString().toLowerCase() > b[col].toString().toLowerCase()
            ? 1
            : -1
        )
      }else{
        sorted = [...eligibleCandidates].sort((a, b) =>
          a[col].toString().toLowerCase() > b[col].toString().toLowerCase()
            ? 1
            : -1
        )
      }
      // const sorted = [...data].sort((a, b) =>
      //   a[col].toString().toLowerCase() > b[col].toString().toLowerCase()
      //     ? 1
      //     : -1
      // )
      const updatedArrow = {
        name: {
          arrow: 'https://cdn.pegasus.imarticus.org/sorting/Light.svg',
          sortMethod: 'DSC',
        },
        batch_name: {
          arrow: 'https://cdn.pegasus.imarticus.org/sorting/Light.svg',
          sortMethod: 'DSC',
        },
      }
      updatedArrow[col].arrow =
        'https://cdn.pegasus.imarticus.org/sorting/Updark(1).svg'
      // setData(sorted)
      if(type == 'placed'){
        setPlacedCandidates(sorted)
      }else if(type == 'ineligible'){
        setIneligibleCandidates(sorted)
      }else{
        setEligibleCandidates(sorted)
      }
      setOrder(updatedArrow)
    }

    if (order[col].sortMethod === 'DSC') {
      let sorted = []
      if(type == 'placed'){
        sorted = [...placedCandidates].sort((a, b) =>
          a[col].toString().toLowerCase() < b[col].toString().toLowerCase()
            ? 1
            : -1
        )
      }else if( type == 'ineligible'){
        sorted = [...ineligibleCandidates].sort((a, b) =>
          a[col].toString().toLowerCase() < b[col].toString().toLowerCase()
            ? 1
            : -1
        )
      }else{
        sorted = [...eligibleCandidates].sort((a, b) =>
          a[col].toString().toLowerCase() < b[col].toString().toLowerCase()
            ? 1
            : -1
        )
      }

      // const sorted = [...data].sort((a, b) =>
      //   a[col].toString().toLowerCase() < b[col].toString().toLowerCase()
      //     ? 1
      //     : -1
      // )
      const updatedArrow = {
        name: {
          arrow: 'https://cdn.pegasus.imarticus.org/sorting/Light.svg',
          sortMethod: 'ASC',
        },
        batch_name: {
          arrow: 'https://cdn.pegasus.imarticus.org/sorting/Light.svg',
          sortMethod: 'ASC',
        },
      }
      updatedArrow[col].arrow =
        'https://cdn.pegasus.imarticus.org/sorting/Downdark(1).svg'
        if(type == 'placed'){
          setPlacedCandidates(sorted)
        }else if(type == 'ineligible'){
          setIneligibleCandidates(sorted)
        }else{
          setEligibleCandidates(sorted)
        }
      setOrder(updatedArrow)
    }
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <div className='placement-portal'>
      <>
        <div className="jobs-main">
          <TopBar2
            heading={'Add Job Rollout Filters'}
          />
          <div>
            <Sidebar3 active={1} jobid={jobid} />
          </div>
          <div>
            <div className="jobs-header">
              <Row>
                <MiniHeader breadCrumbs={[
                  { name: "Career Services", link: "/career-services" },
                  { name: "Job Postings", link: "/career-services" },
                ]}
                  active={2}
                />
              </Row>
            </div>
            <div className="jobs-content">
              <div className="adques-all" style={{ marginTop: '20px', marginBottom: '20px' }}>
                <div className='addcrit-content'>
                  <li className="row">
                    <div className="col-md-6">
                      <FormGroup>
                        <Autocomplete
                          multiple
                          style={{ width: '100%' }}
                          disableClearable
                          value={selectedProduct}
                          onChange={(event, product) => {
                            productChanged(product)
                          }}
                          getOptionLabel={(batch) => {
                            return batch.name || batch
                          }}
                          getOptionSelected={(option, test) => option.name == test}
                          id="batchSelector"
                          options={eligibleProducts}
                          disabled={eligibleProducts.length === 0 ? true : false}
                          renderInput={(params) => {
                            return (
                              <TextField
                                label="Select Product"
                                {...params}
                                variant="outlined"
                                fullWidth
                              />
                            )
                          }}
                        />
                      </FormGroup>
                    </div>
                  </li>
                </div>
              </div>
              {selectedProduct.map((product, index) => (
                <div className="learner-filter-all" >
                  <div className="learner-filter-course-container row">
                    <p className="learner-filter-course-header">{product.name}</p>
                  </div>
                  <div className="adques-header row">
                    <Col md={4} className="jobs-table-header-3">
                      <div>
                        <p style={{ marginTop: '10px' }}>Add Filters</p>
                      </div>
                      <div className='jobs-filter-add-container'>
                        <Button className="learner-add-aplicant-btn"
                          onClick={() => handleFilterSelection(product._id)}
                          disabled={isJobPublished || !!sameFilterMap[product._id]}
                        >Add Filter +
                        </Button>
                      </div>
                    </Col>
                    <Col md={4}>
                    </Col>
                    <Col md={4}>
                      {index > 0 && (
                        <>
                          <input
                            type="checkbox"
                            className="form-check-input job-samefilter-inp"
                            id="apply-same-filter"
                            checked={!!sameFilterMap[product._id]}
                            onClick={() => {
                              applySameFilter(product._id);
                            }}
                          />
                          <label style={{ paddingLeft: "38px" }} className="form-check-label" htmlFor="apply-same-filter">
                            Apply Same filter as First Course
                          </label>
                        </>
                      )}
                    </Col>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                  </div>
                  {!isAddFilterCollapsed && (
                    <div className='addcrit-content'>
                      {Object.keys(filterData).length > 0 && filterData[product._id] &&
                        filterData[product._id].gender &&
                        filterData[product._id].gender.selected && (
                          <li className="row">
                            <div className="col-md-8">
                              <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem',marginBottom:"8px" }}>
                                Gender
                                <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                              </h5>
                              <FormGroup>
                                <Select
                                  options={GENDER_DROPDOWN}
                                  placeholder="Select Gender"
                                  value={
                                    filterData[product._id].gender.value
                                      ? GENDER_DROPDOWN.filter(option => filterData[product._id].gender.value.includes(option.value))
                                      : []
                                  }
                                  onChange={(event) => {
                                    if (!isJobPublished) {
                                      const selectedValues = event.map((option) => option.value);
                                      handleFilterChange('gender', selectedValues, product._id);
                                    }
                                  }}
                                  isSearchable={true}
                                  isMulti
                                  className={isJobPublished ? "readonly-select" : ""}
                                />
                              </FormGroup>
                            </div>
                          </li>
                        )}
                      {Object.keys(filterData).length > 0 && filterData[product._id] && filterData[product._id].total_exp && filterData[product._id].total_exp.selected && (
                        <li className="row">
                          <div className="col-md-8">
                            <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem',marginBottom:"8px" }}>
                              Total Experience
                              <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                            </h5>
                            <FormGroup>
                              {/* <Select
                            options={JOB_EXPERIENCE}
                            placeholder="Select Experience"
                            value={
                              filterData.total_exp.value
                                ? JOB_EXPERIENCE.filter(option => filterData.total_exp.value.includes(option.value))
                                : []
                            }
                            onChange={(event) => {
                              const selectedValues = event.map((option) => option.value);
                              handleFilterChange('total_exp', selectedValues);
                            }}
                            isSearchable={true}
                            isMulti
                          /> */}
                              <div className='row'>
                                <div className="col-md-6">
                                  <Select
                                    options={FILTER_CONDITION}
                                    placeholder="Select Condition"
                                    value={
                                      filterData[product._id].total_exp.condition
                                        ? FILTER_CONDITION.filter(option => filterData[product._id].total_exp.condition == option.value)
                                        : []
                                    }
                                    onChange={(event) => {
                                      console.log({ event })
                                      if (!isJobPublished) {
                                        // const selectedValues = event.map((option) => option.value);
                                        handleDualFilterChange('total_exp', 'condition', event.value, product._id);
                                      }
                                    }}
                                    isSearchable={true}
                                    className={isJobPublished ? "readonly-select" : ""}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <Input
                                    name="text"
                                    type="number"
                                    id="totalOpenPositions"
                                    min="0"
                                    max="200"
                                    value={filterData[product._id].total_exp.value}
                                    onChange={(event) => {
                                      handleDualFilterChange('total_exp', 'value', event.target.valueAsNumber, product._id);
                                    }}
                                    style={{ width: '98%' }}
                                    readOnly={isJobPublished}
                                    className={isJobPublished ? "readonly-select" : ""}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </li>
                      )}
                      {Object.keys(filterData).length > 0 && filterData[product._id] && filterData[product._id].year && filterData[product._id].year.selected && (
                        <li className="row">
                          <div className="col-md-8">
                            <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem',marginBottom:"8px" }}>
                              Graduation Pass Out Year
                              <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                            </h5>
                            <FormGroup>
                              <Select
                                options={PASSING_YEAR}
                                placeholder="Select Graduation Year"
                                value={
                                  filterData[product._id].year.value
                                    ? PASSING_YEAR.filter(option => filterData[product._id].year.value.includes(option.value))
                                    : []
                                }
                                onChange={(event) => {
                                  if (!isJobPublished) {
                                    const selectedValues = event.map((option) => option.value);
                                    handleFilterChange('year', selectedValues, product._id);
                                  }
                                }}
                                isSearchable={true}
                                isMulti
                                className={isJobPublished ? "readonly-select" : ""}
                              />
                            </FormGroup>
                          </div>
                        </li>
                      )}
                      {Object.keys(filterData).length > 0 && filterData[product._id] && filterData[product._id].notice_period && filterData[product._id].notice_period.selected && (
                        <li className="row">
                          <div className="col-md-8">
                            <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem',marginBottom:"8px" }}>
                              Notice Period
                              <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                            </h5>
                            <FormGroup>
                              <div className='row'>
                                <div className="col-md-6">
                                  <Select
                                    options={FILTER_CONDITION}
                                    placeholder="Select Condition"
                                    value={
                                      filterData[product._id].notice_period.condition
                                        ? FILTER_CONDITION.filter(option => filterData[product._id].notice_period.condition == option.value)
                                        : []
                                    }
                                    onChange={(event) => {
                                      if (!isJobPublished) {
                                        console.log({ event })
                                        // const selectedValues = event.map((option) => option.value);
                                        handleDualFilterChange('notice_period', 'condition', event.value, product._id);
                                      }
                                    }}
                                    isSearchable={true}
                                    className={isJobPublished ? "readonly-select" : ""}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <Input
                                    name="text"
                                    type="number"
                                    id="totalOpenPositions"
                                    min="0"
                                    max="200"
                                    value={filterData[product._id].notice_period.value}
                                    onChange={(event) => {
                                      handleDualFilterChange('notice_period', 'value', event.target.valueAsNumber, product._id);
                                    }}
                                    style={{ width: '98%' }}
                                    readOnly={isJobPublished}
                                    className={isJobPublished ? "readonly-select" : ""}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </li>
                      )}
                      {Object.keys(filterData).length > 0 && filterData[product._id] && filterData[product._id]?.matric_grade && filterData[product._id]?.matric_grade.selected && (
                        <li className="row">
                          <div className="col-md-8">
                            <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem',marginBottom:"8px" }}>
                              10th CGPA & Percentage
                              <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                            </h5>
                            <FormGroup>
                              <div className='row'>
                                <div className="col-md-2">
                                  <h6>CGPA</h6>
                                </div>
                                <div className="col-md-6">
                                  <Select
                                    options={FILTER_CONDITION}
                                    placeholder="Select CGPA Condition"
                                    value={
                                      filterData[product._id]?.matric_grade?.condition_cgpa
                                        ? FILTER_CONDITION.filter(option => filterData[product._id]?.matric_grade?.condition_cgpa == option.value)
                                        : []
                                    }
                                    onChange={(event) => {
                                      if (!isJobPublished) {
                                        console.log({ event })
                                        // const selectedValues = event.map((option) => option.value);
                                        handleDualFilterChange('matric_grade', 'condition_cgpa', event.value, product._id);
                                      }
                                    }}
                                    isSearchable={true}
                                    className={isJobPublished ? "readonly-select" : ""}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Input
                                    name="text"
                                    type="number"
                                    id="totalOpenPositions"
                                    min="0"
                                    max="200"
                                    value={filterData[product._id]?.matric_grade?.value}
                                    onChange={(event) => {
                                      handleDualFilterChange('matric_grade', 'value_cgpa', event.target.valueAsNumber, product._id);
                                    }}
                                    style={{ width: '98%' }}
                                    readOnly={isJobPublished}
                                    className={isJobPublished ? "readonly-select" : ""}
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                <div className="col-md-2">
                                  <h6>Percentage</h6>
                                </div>
                                <div className="col-md-6">
                                  <Select
                                    options={FILTER_CONDITION}
                                    placeholder="Select Percentage Condition"
                                    value={
                                      filterData[product._id]?.matric_grade?.condition_perc
                                        ? FILTER_CONDITION.filter(option => filterData[product._id]?.matric_grade?.condition_perc == option.value)
                                        : []
                                    }
                                    onChange={(event) => {
                                      console.log({ event })
                                      // const selectedValues = event.map((option) => option.value);
                                      handleDualFilterChange('matric_grade', 'condition_perc', event.value, product._id);
                                    }}
                                    isSearchable={true}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Input
                                    name="text"
                                    type="number"
                                    id="totalOpenPositions"
                                    min="0"
                                    max="200"
                                    value={filterData[product._id]?.matric_grade?.value_perc}
                                    onChange={(event) => {
                                      handleDualFilterChange('matric_grade', 'value_perc', event.target.valueAsNumber, product._id);
                                    }}
                                    style={{ width: '98%' }}
                                    readOnly={isJobPublished}
                                    className={isJobPublished ? "readonly-select" : ""}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </li>
                      )}
                      {Object.keys(filterData).length > 0 && filterData[product._id] && filterData[product._id]?.inter_grade && filterData[product._id]?.inter_grade?.selected && (
                        <li className="row">
                          <div className="col-md-8">
                            <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem',marginBottom:"8px" }}>
                              12th CGPA & Percentage
                              <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                            </h5>
                            <FormGroup>
                              <div className='row'>
                                <div className="col-md-2">
                                  <h6>CGPA</h6>
                                </div>
                                <div className="col-md-6">
                                  <Select
                                    options={FILTER_CONDITION}
                                    placeholder="Select CGPA Condition"
                                    value={
                                      filterData[product._id]?.inter_grade?.condition_cgpa
                                        ? FILTER_CONDITION.filter(option => filterData[product._id]?.inter_grade?.condition_cgpa == option.value)
                                        : []
                                    }
                                    onChange={(event) => {
                                      if (!isJobPublished) {
                                        console.log({ event })
                                        // const selectedValues = event.map((option) => option.value);
                                        handleDualFilterChange('inter_grade', 'condition_cgpa', event.value, product._id);
                                      }
                                    }}
                                    isSearchable={true}
                                    className={isJobPublished ? "readonly-select" : ""}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Input
                                    name="text"
                                    type="number"
                                    id="totalOpenPositions"
                                    min="0"
                                    max="200"
                                    value={filterData[product._id]?.inter_grade?.value_cgpa}
                                    onChange={(event) => {
                                      handleDualFilterChange('inter_grade', 'value_cgpa', event.target.valueAsNumber, product._id);
                                    }}
                                    style={{ width: '98%' }}
                                    readOnly={isJobPublished}
                                    className={isJobPublished ? "readonly-select" : ""}
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                <div className="col-md-2">
                                  <h6>Percentage</h6>
                                </div>
                                <div className="col-md-6">
                                  <Select
                                    options={FILTER_CONDITION}
                                    placeholder="Select Percentage Condition"
                                    value={
                                      filterData[product._id]?.inter_grade?.condition_perc
                                        ? FILTER_CONDITION.filter(option => filterData[product._id]?.inter_grade?.condition_perc == option.value)
                                        : []
                                    }
                                    onChange={(event) => {
                                      console.log({ event })
                                      // const selectedValues = event.map((option) => option.value);
                                      handleDualFilterChange('inter_grade', 'condition_perc', event.value, product._id);
                                    }}
                                    isSearchable={true}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Input
                                    name="text"
                                    type="number"
                                    id="totalOpenPositions"
                                    min="0"
                                    max="200"
                                    value={filterData[product._id]?.inter_grade?.value_perc}
                                    onChange={(event) => {
                                      handleDualFilterChange('inter_grade', 'value_perc', event.target.valueAsNumber, product._id);
                                    }}
                                    style={{ width: '98%' }}
                                    readOnly={isJobPublished}
                                    className={isJobPublished ? "readonly-select" : ""}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </li>
                      )}
                      {Object.keys(filterData).length > 0 && filterData[product._id] && filterData[product._id]?.grad_grade && filterData[product._id]?.grad_grade?.selected && (
                        <li className="row">
                          <div className="col-md-8">
                            <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem',marginBottom:"8px" }}>
                              Graduation CGPA & Percentage
                              <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                            </h5>
                            <FormGroup>
                              <div className='row'>
                                <div className="col-md-2">
                                  <h6>CGPA</h6>
                                </div>
                                <div className="col-md-6">
                                  <Select
                                    options={FILTER_CONDITION}
                                    placeholder="Select CGPA Condition"
                                    value={
                                      filterData[product._id]?.grad_grade?.condition_cgpa
                                        ? FILTER_CONDITION.filter(option => filterData[product._id]?.grad_grade?.condition_cgpa == option.value)
                                        : []
                                    }
                                    onChange={(event) => {
                                      if (!isJobPublished) {
                                        console.log({ event })
                                        // const selectedValues = event.map((option) => option.value);
                                        handleDualFilterChange('grad_grade', 'condition_cgpa', event.value, product._id);
                                      }
                                    }}
                                    isSearchable={true}
                                    className={isJobPublished ? "readonly-select" : ""}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Input
                                    name="text"
                                    type="number"
                                    id="totalOpenPositions"
                                    min="0"
                                    max="200"
                                    value={filterData[product._id]?.grad_grade?.value_cgpa}
                                    onChange={(event) => {
                                      handleDualFilterChange('grad_grade', 'value_cgpa', event.target.valueAsNumber, product._id);
                                    }}
                                    style={{ width: '98%' }}
                                    readOnly={isJobPublished}
                                    className={isJobPublished ? "readonly-select" : ""}
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                <div className="col-md-2">
                                  <h6>Percentage</h6>
                                </div>
                                <div className="col-md-6">
                                  <Select
                                    options={FILTER_CONDITION}
                                    placeholder="Select Percentage Condition"
                                    value={
                                      filterData[product._id]?.grad_grade?.condition_perc
                                        ? FILTER_CONDITION.filter(option => filterData[product._id]?.grad_grade?.condition_perc == option.value)
                                        : []
                                    }
                                    onChange={(event) => {
                                      console.log({ event })
                                      // const selectedValues = event.map((option) => option.value);
                                      handleDualFilterChange('grad_grade', 'condition_perc', event.value, product._id);
                                    }}
                                    isSearchable={true}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Input
                                    name="text"
                                    type="number"
                                    id="totalOpenPositions"
                                    min="0"
                                    max="200"
                                    value={filterData[product._id]?.grad_grade?.value_perc}
                                    onChange={(event) => {
                                      handleDualFilterChange('grad_grade', 'value_perc', event.target.valueAsNumber, product._id);
                                    }}
                                    style={{ width: '98%' }}
                                    readOnly={isJobPublished}
                                    className={isJobPublished ? "readonly-select" : ""}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </li>
                      )}
                      {Object.keys(filterData).length > 0 && filterData[product._id] && filterData[product._id].night_shift && filterData[product._id].night_shift.selected && (
                        <li className="row">
                          <div className="col-md-8">
                            <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem',marginBottom:"8px" }}>
                              Open for Night Shift/Rotational
                              <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                            </h5>
                            <FormGroup>
                              <Select
                                options={BOOLEAN_DROPDOWN}
                                placeholder="Select Condition"
                                value={
                                  filterData[product._id].night_shift.value
                                    ? BOOLEAN_DROPDOWN.filter(option => filterData[product._id].night_shift.value.includes(option.value))
                                    : []
                                }
                                onChange={(event) => {
                                  if (!isJobPublished) {
                                    const selectedValues = event.map((option) => option.value);
                                    handleFilterChange('night_shift', selectedValues, product._id);
                                  }
                                }}
                                isSearchable={true}
                                isMulti
                                className={isJobPublished ? "readonly-select" : ""}
                              />
                            </FormGroup>
                          </div>
                        </li>
                      )}
                      {Object.keys(filterData).length > 0 && filterData[product._id] && filterData[product._id].intern && filterData[product._id].intern.selected && (
                        <li className="row">
                          <div className="col-md-8">
                            <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem',marginBottom:"8px" }}>
                              Open for Internship
                              <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                            </h5>
                            <FormGroup>
                              <Select
                                options={BOOLEAN_DROPDOWN}
                                placeholder="Select Condition"
                                value={
                                  filterData[product._id].intern.value
                                    ? BOOLEAN_DROPDOWN.filter(option => filterData.intern.value.includes(option.value))
                                    : []
                                }
                                onChange={(event) => {
                                  if (!isJobPublished) {
                                    const selectedValues = event.map((option) => option.value);
                                    handleFilterChange('intern', selectedValues, product._id);
                                  }
                                }}
                                isSearchable={true}
                                isMulti
                                className={isJobPublished ? "readonly-select" : ""}
                              />
                            </FormGroup>
                          </div>

                        </li>
                      )}
                      {Object.keys(filterData).length > 0 && filterData[product._id] && filterData[product._id].contract && filterData[product._id].contract.selected && (
                        <li className="row">
                          <div className="col-md-8">
                            <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem',marginBottom:"8px"}}>
                              Open for Contractual Roles
                              <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                            </h5>
                            <FormGroup>
                              <Select
                                options={BOOLEAN_DROPDOWN}
                                placeholder="Select Condition"
                                value={
                                  filterData[product._id].contract.value
                                    ? BOOLEAN_DROPDOWN.filter(option => filterData[product._id].contract.value.includes(option.value))
                                    : []
                                }
                                onChange={(event) => {
                                  if (!isJobPublished) {
                                    const selectedValues = event.map((option) => option.value);
                                    handleFilterChange('contract', selectedValues, product._id);
                                  }
                                }}
                                isSearchable={true}
                                isMulti
                                className={isJobPublished ? "readonly-select" : ""}
                              />
                            </FormGroup>
                          </div>
                        </li>
                      )}
                      {Object.keys(filterData).length > 0 && filterData[product._id] && filterData[product._id].relocate && filterData[product._id].relocate.selected && (
                        <li className="row">
                          <div className="col-md-8">
                            <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem',marginBottom:"8px" }}>
                              Relocation
                              <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                            </h5>
                            <FormGroup>
                              <Select
                                options={BOOLEAN_DROPDOWN}
                                placeholder="Select Condition"
                                value={
                                  filterData[product._id].relocate.value
                                    ? BOOLEAN_DROPDOWN.filter(option => filterData[product._id].relocate.value.includes(option.value))
                                    : []
                                }
                                onChange={(event) => {
                                  if (!isJobPublished) {
                                    const selectedValues = event.map((option) => option.value);
                                    handleFilterChange('relocate', selectedValues, product._id);
                                  }
                                }}
                                isSearchable={true}
                                isMulti
                                className={isJobPublished ? "readonly-select" : ""}
                              />
                            </FormGroup>
                          </div>
                        </li>
                      )}
                    </div>
                  )}
                  <div className="adques-header row">
                    {hoverImage && index < 1 ? <div className="showJobPopupSkill3">
                      Find learners by skill proficiency score
                    </div> : ''}
                    <Col md={4} className="jobs-table-header-3" >
                      <div>
                        <p style={{ marginTop: '10px' }}>Add Rules</p>
                      </div>
                      <div className='jobs-filter-add-container'>
                        <Button className="learner-add-aplicant-btn"
                          onClick={() => handleRuleChange(numRule + 1, product._id)}
                          disabled={isJobPublished || selectedProduct.length < 1}
                        >Add Rule +
                        </Button>
                        {index < 1 && <img src='https://webcdn.imarticus.org/webinar/Vector1.svg' style={{ objectFit: "contain", marginLeft: '5px' }} onMouseEnter={() => setHoverImage(true)} onMouseLeave={() => setHoverImage(false)} />
                        }
                      </div>
                    </Col>
                    <Col md={5} >
                    </Col>
                    <Col md={3} style={{ display: "flex", justifyContent: "flex-end" }}>
                      {(!tierCondition[product._id] || Object.keys(tierCondition[product._id]).length < 1) && (
                        <Button className="rolloutfilter-table-btn"
                          onClick={() => handleTierConditionChange(product._id)}
                          disabled={isJobPublished || selectedProduct.length < 1}
                        >Add Tier Condition
                        </Button>
                      )}
                    </Col>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                  </div>
                  <div className='addcrit-content'>
                    {rules[product._id] && rules[product._id].length >= 1 && (
                      rules[product._id].map((rule, index) => {
                        return (
                          <div key={index}>
                            <li className="row">
                              <div className="col-md-9">
                                <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                                  Rule {index + 1}:
                                  <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                                </h5>
                                <FormGroup>
                                  <div className='row'>
                                    <div className="col-md-3">
                                      <Select
                                        options={eligibleSkills && eligibleSkills[product._id]}
                                        placeholder="Select Skill Tag"
                                        value={eligibleSkills && eligibleSkills[product._id] && eligibleSkills[product._id].find(skill => skill.value === rule.key)}
                                        onChange={(event) => {
                                          handleRuleKeyChange(event, index, 'key', product._id)
                                        }}
                                        isSearchable={true}
                                      // isMulti
                                      />
                                    </div>
                                    <div className="col-md-3">
                                      <Select
                                        options={SKILL_CONDITION}
                                        placeholder="Select Condition"
                                        value={SKILL_CONDITION.find(skill => skill.value === rule.condition)} // Update to reflect the current rule data
                                        onChange={(event) => {
                                          handleRuleKeyChange(event, index, 'condition', product._id)
                                        }}
                                        isSearchable={true}
                                      />
                                    </div>
                                    <div className="col-md-3">
                                      <Input
                                        name="text"
                                        type="number"
                                        id="totalOpenPositions"
                                        value={rule.value}
                                        onChange={(event) => {
                                          handleRuleValueChange(event, index, 'value', product._id)
                                        }}
                                        style={{ width: '98%' }}
                                        readOnly={isJobPublished}
                                        className={isJobPublished ? "readonly-select" : ""}
                                      />
                                    </div>
                                  </div>
                                </FormGroup>
                              </div>
                              <div className='col-md-3' style={{marginTop:"1rem"}}>
                                {!isJobPublished && (
                                  <Button className="jobs-table-btn-88"
                                    color="danger"
                                    onClick={() => deleteRule(index, product._id)}
                                    style={{marginTop:"10%"}}
                                  >Delete Rule
                                  </Button>
                                )}
                              </div>
                            </li>
                            {rule.subrules.map((subrule, subruleIndex) => (
                              <li key={subruleIndex} className="row">
                                <div className="col-md-9">
                                  <FormGroup>
                                    <div className='row'>
                                      <div className="col-md-3">
                                        <Select
                                          options={JOIN_CONDITION}
                                          placeholder="Select Join Condition"
                                          value={JOIN_CONDITION.find(skill => skill.value === subrule.join)} // Update to reflect the current subrule data
                                          onChange={(event) => {
                                            handleSubRuleKeyChange(event, index, subruleIndex, 'join', product._id)
                                          }}
                                          isSearchable={true}
                                        />
                                      </div>
                                      <div className="col-md-3">
                                        <Select
                                          options={eligibleSkills && eligibleSkills[product._id]}
                                          placeholder="Select Skill Tag"
                                          value={eligibleSkills && eligibleSkills[product._id] && eligibleSkills[product._id].find(skill => skill.value === subrule.key)}// Update to reflect the current subrule data
                                          onChange={(event) => {
                                            handleSubRuleKeyChange(event, index, subruleIndex, 'key', product._id)
                                          }}
                                          isSearchable={true}
                                        />
                                      </div>
                                      <div className="col-md-3">
                                        <Select
                                          options={SKILL_CONDITION}
                                          placeholder="Select Condition"
                                          value={SKILL_CONDITION.find(skill => skill.value === subrule.condition)} // Update to reflect the current subrule data
                                          onChange={(event) => {
                                            handleSubRuleKeyChange(event, index, subruleIndex, 'condition', product._id)
                                          }}
                                          isSearchable={true}
                                        />
                                      </div>
                                      <div className="col-md-3">
                                        <Input
                                          name="text"
                                          type="number"
                                          id="totalOpenPositions"
                                          value={subrule.value}
                                          onChange={(event) => {
                                            handleSubRuleValueChange(event, index, subruleIndex, 'value', product._id)
                                          }}
                                          style={{ width: '98%' }}
                                          readOnly={isJobPublished}
                                          className={isJobPublished ? "readonly-select" : ""}
                                        />
                                      </div>
                                    </div>
                                  </FormGroup>
                                </div>
                                <div className='col-md-1'>
                                  {!isJobPublished && (
                                    <div className='darken-on-hover' style={{ width: "32px", height: "32px", background: "red" }} onClick={() => deleteSubRule(index, subruleIndex, product._id)}>
                                      <img src='https://webcdn.imarticus.org/fsd-pro/Group938.svg' style={{ objectFit: "contain" }} />
                                    </div>
                                  )}
                                </div>
                              </li>
                            ))}
                            <li className='row'>
                              <div className='col-md-8'>
                                <button
                                  className='addfilter-newrule row'
                                  onClick={(event) => {
                                    // const selectedValues = event.map((option) => option.value);
                                    handleSubruleAddition(index, product._id);
                                  }}
                                  disabled={isJobPublished}
                                >
                                  <span className=''>
                                    +&nbsp;
                                  </span>
                                  <span className=''>
                                    Add More Skills
                                  </span>
                                </button>
                              </div>
                            </li>
                          </div>
                        );
                      })
                    )}

                  </div>
                  <div style={{ marginTop: '2rem' }}>
                  </div>
                  <div className='addcrit-content'>
                    {tierCondition[product._id] && Object.keys(tierCondition[product._id]).length > 0 ? (
                      <>
                        <div>
                          <li className="row">
                            <div className="col-md-9">
                              <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                                Tier Condition:
                                <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                              </h5>
                              <FormGroup>
                                <div className='row'>

                                  <div className="col-md-4">
                                    <Select
                                      options={TIER_DROPDOWN}
                                      placeholder="Select Condition"
                                      value={TIER_DROPDOWN.find(skill => skill.value === tierCondition[product._id].condition)}
                                      onChange={(event) => {
                                        handleTierConditionKeyChange(event, 'condition', product._id);
                                      }}
                                      isSearchable={true}
                                    />
                                  </div>
                                  {tierCondition[product._id].condition === 4 ? (
                                    <div className="col-md-5">
                                      <Select
                                        options={TIER_CONDITION}
                                        placeholder="Select Tier"
                                        value={tierCondition && tierCondition[product._id] && tierCondition[product._id].val_array}
                                        onChange={(event) => {
                                          handleTierConditionKeyChange(event, 'val_array', product._id);
                                        }}
                                        isSearchable={true}
                                        isMulti
                                      />
                                    </div>
                                  ) : (
                                    <div className="col-md-5">
                                      <Select
                                        options={TIER_CONDITION}
                                        placeholder="Select Tier"
                                        value={TIER_CONDITION.find(skill => skill.value === tierCondition[product._id].tier)}
                                        onChange={(event) => {
                                          handleTierConditionKeyChange(event, 'tier', product._id);
                                        }}
                                        isSearchable={true}
                                      />
                                    </div>)}
                                </div>
                              </FormGroup>
                            </div>
                            <div className='col-md-3' style={{ marginTop: "1rem" }}>
                              {!isJobPublished && (
                                <Button className="jobs-table-btn-88"
                                  color="danger"
                                  onClick={() => deleteTierCondition(product._id)}
                                  style={{marginTop: '10%'}}
                                  >
                                  Delete Condition
                                </Button>
                              )}
                            </div>
                          </li>
                        </div>
                      </>
                    ) : (
                      <div></div>
                    )}

                  </div>
                  <div className='learner-filter-batch-container row'>
                    <li className="row" style={{ width: "100%" }}>
                      <div className="col-md-12" style={{ marginLeft: "16px" }}>
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '16px',marginBottom:"8px" }}>
                          Select Batches
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <div className='row'>
                            <div className="col-md-9">
                              <Select
                                closeMenuOnSelect={false}
                                options={batches[product._id]}
                                placeholder="Select Batches"
                                value={
                                  batches && batches[product._id]
                                    ? batches[product._id]
                                      .filter(batch => selectedBatches && selectedBatches[product._id] && selectedBatches[product._id].includes(batch.value))
                                      .map(batch => ({ ...batch }))
                                    : []
                                }
                                onChange={(value, action) => handleSelectedBatchChange(value, action, product._id)}
                                isSearchable={true}
                                isMulti
                              // isClearable={batches.some((v) => v.isFixed)}
                              />
                            </div>
                            <div className='col-md-3' style={{marginTop:"5px"}}>
                              <input
                                type="checkbox"
                                className="form-check-input job-dt-inp"
                                id={`select-all-batches-${product._id}`}
                                checked={batches && batches[product._id] && selectedBatches && selectedBatches[product._id] && batches[product._id].length === selectedBatches[product._id].length}
                                onClick={() => {
                                  selectAllBatches(product._id);
                                }}
                              />
                              <label style={{ paddingLeft: "38px" }} className="form-check-label" htmlFor={`select-all-batches-${product._id}`}>
                                Select All Batches
                              </label>
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </li>
                  </div>
                </div>
              ))}
              <div className="learner-filter-add-btn-ctnr" style={{ marginTop: '20px' }}>
                <div className='job-rollout-footer'>
                  <Row className="adques-footer">
                    <Col md={4}></Col>
                    <Col md={4}>
                      <Button
                        className="jobs-table-btn-5"
                        onClick={fetchFilteredCandidatesDraft}
                        disabled={selectedProduct.length < 1}
                      >
                        Add Applicants
                      </Button>
                      <div style={{ width: "80%", marginTop: "3px" }}>
                        <p style={{ marginBottom: "0px" }}>
                          Note: Data updated by the learners can take upto 30 mins to appear for filtering
                        </p>
                        {/* <p style={{display:"flex",justifyContent:"center"}}>
                        appear for filtering
                        </p> */}
                      </div>
                    </Col>
                    <Col md={4}></Col>
                  </Row>
                </div>
              </div>
              {candidatesRequested && (
                <div className="adques-all" style={{ marginTop: '20px' }}>
                  <div className="rollout-addapplicant-header row">
                    <Col md={9} className="jobs-table-header-2">
                      <p style={{ marginBottom: '10px' }}>Add Applicants</p>
                    </Col>
                    <Col md={2}>
                    </Col>
                  </div>
                  {!candidatesFetched ? (<Loader active inline />) :
                    (
                      <>
                        <div style={{ marginTop: '2rem' }}>
                          <Row style={{ marginLeft: '0px',paddingRight: "10px" }}>
                            <Col md={3}>Total Learners</Col>
                            <Col md={3}>Total Eligible Learners</Col>
                            <Col md={3}>Total Non Eligible Learners</Col>
                            <Col md={3}>Total Excluded Learners</Col>
                            <Col md={3}>
                              <Input
                                type="number"
                                name="text"
                                id="courseShortCode"
                                value={totalLearners}
                                style={{ width: '98%' }}
                                disabled
                              /></Col>
                            <Col md={3}>
                              <Input
                                type="number"
                                name="text"
                                id="courseShortCode"
                                value={numEligibleLearners}
                                style={{ width: '98%' }}
                                disabled
                              /></Col>
                            <Col md={3}><Input
                              type="number"
                              name="text"
                              id="courseShortCode"
                              value={numIneligibleLearners}
                              style={{ width: '98%' }}
                              disabled
                            /></Col>
                            <Col md={3}><Input
                              type="number"
                              name="text"
                              id="courseShortCode"
                              value={totalLearners - numEligibleLearners - numIneligibleLearners}
                              style={{ width: '98%' }}
                              disabled
                            /></Col>
                          </Row>

                        </div>
                        <div style={{ marginTop: '2rem' }}>
                          <Row >
                            <Col md={12}>
                              <div className="rollout-toggle-container">
                                <button
                                  className={`job-dt-tog-btn2  ${!eligibleSelected ? 'unsubscribed' : 'subscribed'
                                    }`}
                                  onClick={handleEligibleLearners}
                                >

                                  <span
                                    className={` ${!eligibleSelected ? 'btch-toggle-unchecked-2' : 'btch-toggle-2'}`}
                                  >
                                    Eligible Learners
                                  </span>
                                </button>
                                <button
                                  className={`job-dt-tog-btn2  ${!ineligibleSelected ? 'unsubscribed' : 'subscribed'
                                    }`}
                                  onClick={handleIneligibleLearners}
                                >

                                  <span
                                    className={` ${!ineligibleSelected ? 'btch-toggle-unchecked-2' : 'btch-toggle-2'}`}
                                  >
                                    Non-Eligible Learners
                                  </span>
                                </button>
                                <button
                                  className={`job-dt-tog-btn2  ${!placedSelected ? 'unsubscribed' : 'subscribed'
                                    }`}
                                  onClick={handlePlacedLearners}
                                >
                                  <span
                                    className={` ${!placedSelected ? 'btch-toggle-unchecked-2' : 'btch-toggle-2'
                                      }`}
                                  >
                                    Placed Learners
                                  </span>
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {eligibleSelected && (
                          <>
                            <Row style={{ marginLeft: '0px' }}>
                              <Col md={12}>Total Eligible Learners</Col>
                              <Col md={4}>
                                <Input
                                  type="text"
                                  name="text"
                                  id="courseShortCode"
                                  value={searchNameKey}
                                  onChange={(e) => setSearchNameKey(e.target.value)}
                                  style={{ width: '98%' }}
                                  placeholder="Search"
                                /></Col>
                            </Row>

                            <div className='addcrit-content'>
                              {eligibleCandidates.length >= 0 ? (
                                <Row style={{ marginTop: '1rem' }}>
                                  <TableContainer component={Paper} style={{ height: '600px', border: "1px solid #000", marginLeft: "15px", marginRight: "15px", marginBottom: "8px" }}>
                                    <Table aria-label="custom pagination table" id="batch-data-table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            className='btch-dt-inp sticky-header-both-zero-1 test-heading-left'
                                            scope="col">
                                            <Input
                                              type="checkbox"
                                              className="btch-dt-inp-2"
                                              id="selectAllCheckbox"
                                              checked={isAllEligibleSelected}
                                              onChange={allEligibleStudentSelect}
                                            />
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-chk sticky-header-both-first-1 test-heading-left-2"
                                            scope="col"
                                          >
                                            S No.
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header-both-second-1 test-heading-left-3"
                                            scope="col"
                                            onClick={() => sorting('name','eligible')}
                                          >
                                            Student Name
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' ,marginLeft:'8px'}}
                                            >
                                              <img src={getArrow('name')} />
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header-both-third-1 test-heading-left-4"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Training Location
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header"
                                            scope="col"
                                            onClick={() => sorting('batch_name','eligible')}
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Batch Name
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px',marginLeft:'8px' }}
                                            >
                                              <img src={getArrow('batch_name')} />
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Product
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Resume Approved
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            User Status
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            User Type
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Gradebook %
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Course Completion %
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Student Tier
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Job Shared
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Shared
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Applied
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Rejects
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {eligibleCandidates &&
                                          eligibleCandidates
                                            .filter(dt => (
                                              (searchNameKey === "" || dt.name.toLowerCase().includes(searchNameKey.toLowerCase()))) &&
                                              // !Object.values(selectedEligible).some(arr => arr.some(sel => sel.aid === dt.aid)) &&
                                              // !Object.values(selectedIneligible).some(arr => arr.some(sel => sel.aid === dt.aid)) 
                                              !selectedAids.includes(String(dt.aid))

                                            )
                                            .map((dt, key) => {
                                              return (
                                                <TableRow>
                                                  <TableCell className="sticky-first-1 btch-dt-chk-3 test-left"
                                                    scope="row">
                                                    <Input
                                                      type="checkbox"
                                                      className="btch-dt-inp btch-dt-inp-eligible"
                                                      id={dt.aid}
                                                      name={dt.crs_pg_id}
                                                      title={dt.cid}
                                                      onClick={() => {
                                                        singleEligibleStudentSelect(dt.aid, dt.crs_pg_id, dt.cid)
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td-app  sticky-second-1 test-left-2"
                                                    scope="row"
                                                  >{serialNum++}</TableCell>
                                                  <TableCell
                                                    className="btch-dt-td-app  sticky-third-1 test-left-3"
                                                    scope="row"
                                                  >
                                                    {dt.name}
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td-app  sticky-fourth-1 test-left-4"
                                                    scope="row"
                                                  >
                                                    {dt.loc_name}
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td-app "
                                                    scope="row"
                                                  >
                                                    {dt.batch_name}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.course_name || "-"}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    <a href="/head" className="resume-link"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEligibleResumeModalOpen(dt.aid);
                                                      }}>
                                                      View Resume
                                                    </a>
                                                    {/* {dt.profile_completion} */}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {'Eligible'}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.comp_perc == 100 ? 'Course Completed' : 'In Training'}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    <span
                                                      onClick={() => { handleTotalGradeClick(dt.crs_pg_id, dt.aid) }}
                                                      className="resume-link"
                                                      style={{ cursor: 'pointer' }}>
                                                      {dt.total_grade ? dt.total_grade + '%' : '-'}
                                                    </span>
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.comp_perc}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.student_tier}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {selectedStudents.includes(dt.aid) ? "Yes" : "No"}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.num_opportunities}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.num_applied}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.num_rejects}
                                                  </TableCell>

                                                </TableRow>
                                              )
                                            })}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                  {/* <div style={{ marginRight: '256px' }}>
                                    <TablePagination
                                      rowsPerPageOptions={[10, 25, 50]}
                                      component="div"
                                      count={totalCount}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onPageChange={handleChangePage}
                                      onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                  </div> */}
                                </Row>
                              ) :
                                <div className="job-not-found">
                                  <img src='https://webcdn.imarticus.org/demo/Group4606.svg'></img>
                                  <div style={{ marginBottom: '1rem' }}>
                                    <br></br>
                                    <h5>It's empty here!</h5>
                                  </div>
                                  <div>
                                    <p style={{ marginLeft: '1rem' }}>No Eligible Learners Found</p>
                                    {/* <p>"Create a Job" to view jobs here</p> */}
                                  </div>
                                </div>
                              }
                            </div>
                          </>
                        )}
                        {ineligibleSelected && (
                          <>
                            <Row style={{ marginLeft: '0px' }}>
                              <Col md={12}>Total Non-Eligible Learners</Col>
                              <Col md={4}>
                                <Input
                                  type="text"
                                  name="text"
                                  id="courseShortCode"
                                  value={searchNameKey}
                                  onChange={(e) => setSearchNameKey(e.target.value)}
                                  style={{ width: '98%' }}
                                  placeholder="Search"
                                /></Col>
                            </Row>

                            <div className='addcrit-content'>
                              {ineligibleCandidates.length >= 0 ? (
                                <Row style={{ marginTop: '1rem' }}>
                                  <TableContainer component={Paper} style={{ height: '600px', border: "1px solid #000", marginLeft: "15px", marginRight: "15px", marginBottom: "8px" }}>
                                    <Table aria-label="custom pagination table" id="batch-data-table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            className=' sticky-header-both-zero-1 test-heading-left'
                                            scope="col">
                                            <Input
                                              type="checkbox"
                                              className="btch-dt-inp-2"
                                              id="selectAllCheckbox"
                                              checked={isAllIneligibleSelected}
                                              onChange={allIneligibleStudentSelect}
                                            />
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-chk sticky-header-both-first-1 test-heading-left-2"
                                            scope="col"
                                          >
                                            S No.
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header-both-second-1 test-heading-left-3"
                                            scope="col"
                                            onClick={() => sorting('name','ineligible')}
                                          >
                                            Student Name
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px',marginLeft:'8px' }}
                                            >
                                              <img src={getArrow('name')} />
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header-both-third-1 test-heading-left-4"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Training Location
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => sorting('batch_name','ineligible')}
                                          >
                                            Batch Name
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px',marginLeft:'8px' }}
                                            >
                                              <img src={getArrow('batch_name')} />
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Product
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Resume Approved
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            User Status
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            User Type
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Gradebook %
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Course Completion %
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Student Tier
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Job Shared
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Shared
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Applied
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Rejects
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {ineligibleCandidates &&
                                          ineligibleCandidates
                                            .filter(dt => (searchNameKey === "" || dt.name.toLowerCase().includes(searchNameKey.toLowerCase())) &&
                                              // Object.values(selectedEligible).some(arr => arr.some(sel => String(sel.aid) === String(dt.aid))) &&
                                              // !Object.values(selectedIneligible).some(arr => arr.some(sel => String(sel.aid) === String(dt.aid)))
                                              !selectedAids.includes(String(dt.aid))
                                            )
                                            .map((dt, key) => {
                                              return (
                                                <TableRow>
                                                  <TableCell className="sticky-first-1 btch-dt-chk-3 test-left"
                                                    scope="row">
                                                    <Input
                                                      type="checkbox"
                                                      className="btch-dt-inp-ineligible"
                                                      id={dt.aid}
                                                      name={dt.crs_pg_id}
                                                      title={dt.cid}
                                                      onClick={() => {
                                                        singleIneligibleStudentSelect(dt.aid, dt.crs_pg_id, dt.cid)
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td-app  sticky-second-1 test-left-2"
                                                    scope="row"
                                                  >{serialNum++}</TableCell>
                                                  <TableCell
                                                    className="btch-dt-td-app  sticky-third-1 test-left-3"
                                                    scope="row"
                                                  >
                                                    {dt.name}
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td-app  sticky-fourth-1 test-left-4"
                                                    scope="row"
                                                  >
                                                    {dt.loc_name}
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td-app "
                                                    scope="row"
                                                  >
                                                    {dt.batch_name}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.course_name || "-"}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    <a href="/head" className="resume-link"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleIneligibleResumeModalOpen(dt.aid);
                                                      }}
                                                    >
                                                      View Resume
                                                    </a>
                                                    {/* {dt.profile_completion} */}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {'Ineligible'}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.comp_perc == 100 ? 'Course Completed' : 'In Training'}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    <span
                                                      onClick={() => { handleTotalGradeClick(dt.crs_pg_id, dt.aid) }}
                                                      className="resume-link"
                                                      style={{ cursor: 'pointer' }}>
                                                      {dt.total_grade ? dt.total_grade + '%' : '-'}
                                                    </span>
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.comp_perc}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.student_tier}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {selectedStudents.includes(dt.aid) ? "Yes" : "No"}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.num_opportunities}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.num_applied}
                                                    {/* {PLACEMENT_ELIGIBILITY[1]} */}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.num_rejects}
                                                  </TableCell>

                                                </TableRow>
                                              )
                                            })}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                  {/* <div style={{ marginRight: '256px' }}> */}
                                  {/* <TablePagination
                                      rowsPerPageOptions={[10, 25, 50]}
                                      component="div"
                                      count={totalCount}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onPageChange={handleChangePage}
                                      onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                  </div> */}
                                </Row>
                              ) :
                                <div className="job-not-found">
                                  <img src='https://webcdn.imarticus.org/demo/Group4606.svg'></img>
                                  <div style={{ marginBottom: '1rem' }}>
                                    <br></br>
                                    <h5>It's empty here!</h5>
                                  </div>
                                  <div>
                                    <p style={{ marginLeft: '1rem' }}>No Eligible Learners Found</p>
                                    {/* <p>"Create a Job" to view jobs here</p> */}
                                  </div>
                                </div>
                              }
                            </div>
                          </>
                        )}
                        {placedSelected && (
                          <>
                            <Row style={{ marginLeft: '0px' }}>
                              <Col md={12}>Total Placed Learners</Col>
                              <Col md={4}>
                                <Input
                                  type="text"
                                  name="text"
                                  id="courseShortCode"
                                  value={searchNameKey}
                                  onChange={(e) => setSearchNameKey(e.target.value)}
                                  style={{ width: '98%' }}
                                  placeholder="Search"
                                /></Col>
                            </Row>

                            <div className='addcrit-content'>
                              {placedCandidates.length >= 0 ? (
                                <Row style={{ marginTop: '1rem' }}>
                                  <TableContainer component={Paper} style={{ height: '600px', border: "1px solid #000", marginLeft: "15px", marginRight: "15px", marginBottom: "8px" }}>
                                    <Table aria-label="custom pagination table" id="batch-data-table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            className='btch-dt-inp-ineligible sticky-header-both-zero-1 test-heading-left'
                                            scope="col">
                                            {/* <Input
                                              type="checkbox"
                                              className="btch-dt-inp-2"
                                              id="selectAllCheckbox"
                                              checked={isAllIneligibleSelected}
                                              onChange={allIneligibleStudentSelect}
                                            />  */}
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-chk sticky-header-both-first-1 test-heading-left-2"
                                            scope="col"
                                          >
                                            S No.
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header-both-second-1 test-heading-left-3"
                                            scope="col"
                                            onClick={() => sorting('name','placed')}
                                          >
                                            Student Name
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px',marginLeft:'8px' }}
                                            >
                                              <img src={getArrow('name')} />
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header-both-third-1 test-heading-left-4"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Training Location
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => sorting('batch_name','placed')}
                                          >
                                            Batch Name
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px',marginLeft:'8px' }}
                                            >
                                              <img src={getArrow('batch_name')} />
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Product
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Resume Approved
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            User Status
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            User Type
                                          </TableCell>
                                          {/* <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Gradebook %
                                          </TableCell> */}
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Shared
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Applied
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Rejects
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {placedCandidates &&
                                          placedCandidates
                                            .filter(dt => {
                                              const matchesSearch = searchNameKey === "" || dt.name.includes(searchNameKey);

                                              // Check if the candidate's aid is in the selectedAids array
                                              // const isAidSelected = selectedAids.includes(dt.aid);

                                              // Ensure the aid is not already processed (display the first occurrence)
                                              const isFirstOccurrence = !displayedPlaced.has(dt.aid);

                                              // If all conditions are met, add the aid to the displayedAids set and return true
                                              if (matchesSearch && isFirstOccurrence) {
                                                displayedPlaced.add(dt.aid);
                                                return true;
                                              }

                                              return false;
                                            })
                                            .map((dt, key) => {
                                              return (
                                                <TableRow>
                                                  <TableCell className="sticky-first-1 btch-dt-chk-3 test-left"
                                                    scope="row">
                                                    {/* <Input
                                                      type="checkbox"
                                                      className="btch-dt-inp-ineligible"
                                                      id={dt.aid}
                                                      onClick={() => {
                                                        singleIneligibleStudentSelect(dt.aid)
                                                      }}
                                                    /> */}
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td-app  sticky-second-1 test-left-2"
                                                    scope="row"
                                                  >{serialNum++}</TableCell>
                                                  <TableCell
                                                    className="btch-dt-td-app  sticky-third-1 test-left-3"
                                                    scope="row"
                                                  >
                                                    {dt.name}
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td-app  sticky-fourth-1 test-left-4"
                                                    scope="row"
                                                  >
                                                    {dt.loc_name}
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td-app "
                                                    scope="row"
                                                  >
                                                    {dt.batch_name}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.course_name || "-"}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    <a href="/head" className="resume-link"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handlePlacedResumeModalOpen(dt.aid);
                                                      }}
                                                    >
                                                      View Resume
                                                    </a>
                                                    {/* {dt.profile_completion} */}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {'Ineligible'}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    In Training
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.num_opportunities}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.num_applied}
                                                    {/* {PLACEMENT_ELIGIBILITY[1]} */}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td-app " scope="row">
                                                    {dt.num_rejects}
                                                  </TableCell>

                                                </TableRow>
                                              )
                                            })}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                  {/* <div style={{ marginRight: '256px' }}>
                                    <TablePagination
                                      rowsPerPageOptions={[10, 25, 50]}
                                      component="div"
                                      count={totalCount}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onPageChange={handleChangePage}
                                      onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                  </div> */}
                                </Row>
                              ) :
                                <div className="job-not-found">
                                  <img src='https://webcdn.imarticus.org/demo/Group4606.svg'></img>
                                  <div style={{ marginBottom: '1rem' }}>
                                    <br></br>
                                    <h5>It's empty here!</h5>
                                  </div>
                                  <div>
                                    <p style={{ marginLeft: '1rem' }}>No Eligible Learners Found</p>
                                    {/* <p>"Create a Job" to view jobs here</p> */}
                                  </div>
                                </div>
                              }
                            </div>
                          </>
                        )}
                        <div className='job-rollout-footer'>
                          <Row className="adques-footer">
                            <Col md={2}>
                            </Col>
                            <Col md={5}></Col>
                            <Col md={3}>
                            </Col>
                            <Col md={2}>
                              <Button
                                className="jobs-table-btn-7"
                                onClick={() => {
                                  // finalizeLearnersForCourse()
                                  handleFinalModalOpen()
                                }}
                              >
                                Finalize Applicants
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                </div>
              )}
              {selectedAids.length > 0 && (
                <div className="adques-all" style={{ marginTop: '20px' }}>
                  <div className="learner-filter-final-learner row">
                    <Col md={9} className="jobs-table-header-2">
                      <p style={{ marginBottom: '10px' }}>Final Learner List</p>
                    </Col>
                    <Col md={2}>
                    </Col>
                  </div>
                  <>
                    <Row style={{ marginLeft: '8px', marginRight: "0px" }}>
                      <Col md={5}>
                        <Input
                          type="text"
                          name="text"
                          id="courseShortCode"
                          value={finalSearchNameKey}
                          onChange={(e) => setFinalSearchNameKey(e.target.value)}
                          style={{ width: '98%' }}
                          placeholder="Search"
                        />
                      </Col>
                      <Col md={2}></Col>
                      <Col md={5} style={{ display: "flex", justifyContent: "flex-end", paddingRight: "32px" }}>
                        <div style={{ width: "20px", height: "20px", borderRadius: "4px", backgroundColor: "#D9EAD3", marginRight: "8px" }}></div>Eligible Learners

                        <div style={{ width: "20px", height: "20px", borderRadius: "4px", backgroundColor: "#FFF2CC", marginLeft: "16px", marginRight: "8px" }}></div>Previously Ineligible Learners
                      </Col>
                    </Row>

                    <div className='addcrit-content'>
                      {selectedAids.length >= 0 ? (
                        <Row style={{ marginTop: '1rem', marginLeft: "8px", marginRight: "8px", marginBottom: "23px" }}>
                          <TableContainer component={Paper} style={{ height: '600px', border: "1px solid #000" }}>
                            <Table aria-label="custom pagination table" id="batch-data-table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    className="btch-dt-chk sticky-header-both-first-1 final-select-heading-left"
                                    scope="col"
                                  >
                                    S No.
                                  </TableCell>
                                  <TableCell
                                    className="btch-dt-th sticky-header-both-second-1 final-select-heading-left-2"
                                    scope="col"
                                  >
                                    Student Name
                                  </TableCell>
                                  <TableCell
                                    className="btch-dt-th sticky-header-both-third-1 final-select-heading-left-3"
                                    scope="col"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Training Location
                                    <span
                                      style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                    >
                                    </span>
                                  </TableCell>
                                  <TableCell
                                    className="btch-dt-th sticky-header"
                                    scope="col"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Batch Name
                                    <span
                                      style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                    >
                                    </span>
                                  </TableCell>
                                  <TableCell
                                    className="btch-dt-th sticky-header"
                                    scope="col"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Product
                                    <span
                                      style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                    >
                                    </span>
                                  </TableCell>
                                  <TableCell className="btch-dt-th sticky-header" scope="col">
                                    Resume Approved
                                  </TableCell>
                                  <TableCell className="btch-dt-th sticky-header" scope="col">
                                    User Status
                                  </TableCell>
                                  {/* <TableCell className="btch-dt-th sticky-header" scope="col">
                                    User Type
                                  </TableCell>
                                  <TableCell className="btch-dt-th sticky-header" scope="col">
                                    Gradebook %
                                  </TableCell>
                                  <TableCell className="btch-dt-th sticky-header" scope="col">
                                    Course Completion %
                                  </TableCell>
                                  <TableCell className="btch-dt-th sticky-header" scope="col">
                                    Student Tier
                                  </TableCell> */}
                                  <TableCell className="btch-dt-th sticky-header" scope="col">
                                    Job Shared
                                  </TableCell>
                                  {/* <TableCell className="btch-dt-th sticky-header" scope="col">
                                    No. of Jobs Shared
                                  </TableCell>
                                  <TableCell className="btch-dt-th sticky-header" scope="col">
                                    No. of Jobs Applied
                                  </TableCell>
                                  <TableCell className="btch-dt-th sticky-header" scope="col">
                                    No. of Jobs Rejects
                                  </TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedEligible &&
                                  selectedEligible
                                    .filter(dt => {
                                      const matchesSearch = finalSearchNameKey === "" || dt.name.includes(finalSearchNameKey);

                                      // Check if the candidate's aid is in the selectedAids array
                                      const isAidSelected = selectedAids.includes(dt.aid);

                                      // Ensure the aid is not already processed (display the first occurrence)
                                      const isFirstOccurrence = !displayedAids.has(dt.aid);

                                      // If all conditions are met, add the aid to the displayedAids set and return true
                                      if (matchesSearch && isAidSelected && isFirstOccurrence) {
                                        displayedAids.add(dt.aid);
                                        return true;
                                      }

                                      return false;
                                    })
                                    .map((dt, key) => {
                                      return (
                                        <TableRow>
                                          <TableCell
                                            className="btch-dt-td-app  sticky-second-1 final-select-left"
                                            scope="row"
                                          >{selectedSerialNum++}</TableCell>
                                          <TableCell
                                            className="job-rollout-dt-td-eligible sticky-third-1 final-select-left-2"
                                            scope="row"
                                            style={{ background: "#D9EAD3 !important" }}
                                          >
                                            {dt.name}
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-td-app  sticky-fourth-1 final-select-left-3"
                                            scope="row"
                                          >
                                            {dt.loc_name}
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-td-app "
                                            scope="row"
                                          >
                                            {dt.batch_name}
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            {dt.course_name || "-"}
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            <a href="/head" className="resume-link"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleEligibleResumeModalOpen(dt.aid);
                                              }}>
                                              View Resume
                                            </a>
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            {'Eligible'}
                                          </TableCell>
                                          {/* <TableCell className="btch-dt-td-app " scope="row">
                                            {dt.comp_perc == 100 ? 'Course Completed' : 'In Training'}
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            <span
                                              onClick={() => { handleTotalGradeClick(dt.crs_pg_id, dt.aid) }}
                                              className="resume-link"
                                              style={{ cursor: 'pointer' }}>
                                              {dt.total_grade ? dt.total_grade + '%' : '-'}
                                            </span>
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            {dt.comp_perc}
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            {dt.student_tier}
                                          </TableCell> */}
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            {"Yes"}
                                          </TableCell>
                                          {/* <TableCell className="btch-dt-td-app " scope="row">
                                            {dt.num_opportunities}
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            {dt.num_applied}
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            {dt.num_rejects}
                                          </TableCell> */}

                                        </TableRow>
                                      )
                                    })}
                                {selectedIneligible &&
                                  selectedIneligible
                                    .filter(dt => {
                                      const matchesSearch = finalSearchNameKey === "" || dt.name.includes(finalSearchNameKey);

                                      // Check if the candidate's aid is in the selectedAids array
                                      const isAidSelected = selectedAids.includes(dt.aid);

                                      // Ensure the aid is not already processed (display the first occurrence)
                                      const isFirstOccurrence = !displayedAids.has(dt.aid);

                                      // If all conditions are met, add the aid to the displayedAids set and return true
                                      if (matchesSearch && isAidSelected && isFirstOccurrence) {
                                        displayedAids.add(dt.aid);
                                        return true;
                                      }

                                      return false;
                                    })
                                    .map((dt, key) => {
                                      return (
                                        <TableRow>
                                          <TableCell
                                            className="btch-dt-td-app  sticky-second-1 final-select-left"
                                            scope="row"
                                          >{selectedSerialNum++}</TableCell>
                                          <TableCell
                                            className="job-rollout-dt-td-ineligible sticky-third-1 final-select-left-2"
                                            scope="row"
                                            style={{ background: "#FFF2CC !important" }}
                                          >
                                            {dt.name}
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-td-app  sticky-fourth-1 final-select-left-3"
                                            scope="row"
                                          >
                                            {dt.loc_name}
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-td-app "
                                            scope="row"
                                          >
                                            {dt.batch_name}
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            {dt.course_name || "-"}
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            <a href="/head" className="resume-link"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleEligibleResumeModalOpen(dt.aid);
                                              }}>
                                              View Resume
                                            </a>
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            {'Ineligible'}
                                          </TableCell>
                                          {/* <TableCell className="btch-dt-td-app " scope="row">
                                            {dt.comp_perc == 100 ? 'Course Completed' : 'In Training'}
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            <span
                                              onClick={() => { handleTotalGradeClick(dt.crs_pg_id, dt.aid) }}
                                              className="resume-link"
                                              style={{ cursor: 'pointer' }}>
                                              {dt.total_grade ? dt.total_grade + '%' : '-'}
                                            </span>
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            {dt.comp_perc}
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            {dt.student_tier}
                                          </TableCell> */}
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            {"Yes"}
                                          </TableCell>
                                          {/* <TableCell className="btch-dt-td-app " scope="row">
                                            {dt.num_opportunities}
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            {dt.num_applied}
                                          </TableCell>
                                          <TableCell className="btch-dt-td-app " scope="row">
                                            {dt.num_rejects}
                                          </TableCell> */}

                                        </TableRow>
                                      )
                                    })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Row>
                      ) :
                        <div className="job-not-found">
                          <img src='https://webcdn.imarticus.org/demo/Group4606.svg'></img>
                          <div style={{ marginBottom: '1rem' }}>
                            <br></br>
                            <h5>It's empty here!</h5>
                          </div>
                          <div>
                            <p style={{ marginLeft: '1rem' }}>No Eligible Learners Found</p>
                          </div>
                        </div>
                      }
                    </div>
                  </>
                </div>
              )}
              <div className="adques-all" style={{ marginTop: '20px' }}>
                <div className="adques-header row" style={{ paddingTop: "8px" }}>
                  <Col md={9} className="jobs-table-header-3">
                    <p style={{ marginBottom: '10px' }}>Set Job Reminder Frequency</p>
                  </Col>
                  <Col md={2}>
                  </Col>
                  <Col md={1}>
                    <img src={isJobReminderCollapsed ? 'https://webcdn.imarticus.org/Placement/chevron-up.svg' : 'https://webcdn.imarticus.org/Placement/chevron-down.svg'} style={{ objectFit: "contain", marginTop: '10px', marginLeft: '5px' }}
                      onClick={toggleJobFreq}
                    />
                  </Col>
                </div>
                <div style={{ marginTop: '2rem' }}>
                </div>
                {!isJobReminderCollapsed && (
                  <div className='addcrit-content'>
                    <li className="row">
                      <div className="col-md-6">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Select Periodicity
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <Select
                            options={PERIODICITY}
                            placeholder="Select Period"
                            value={PERIODICITY.find(period => period.value === selectedPeriod)}
                            onChange={(event) => {

                              handleSelectedPeriodChange(event)
                            }}
                            isSearchable={true}
                            className={isJobPublished ? "readonly-select" : ""}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Select No. (hours,days,weeks)
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <Input
                            type="number"
                            name="text"
                            id="courseShortCode"
                            value={periodVal}
                            max="1000"
                            min="0"
                            onChange={(event, oppID) => {
                              const value = event.target.value;
                              if (/^\d+$/.test(value) && parseInt(value, 10) > 0) {
                                setPeriodVal(value);
                              }
                            }}
                            style={{
                              width: '98%'
                            }}
                            readOnly={isJobPublished}
                            className={isJobPublished ? "readonly-select" : ""}
                          //   disabled={companyName !== ""}
                          />
                        </FormGroup>
                      </div>
                    </li>
                    <li className="row">
                      <div className="col-md-12">
                        <p htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Note: These reminder will be only sent till application ends or once the learner has applied for the job
                        </p>
                      </div>
                    </li>
                  </div>
                )}
              </div>

              <div className="adques-all" style={{ marginTop: '20px' }}>
                <div className="adques-header row" style={{ paddingTop: "8px" }}>
                  <Col md={9} className="jobs-table-header-3">
                    <p style={{ marginBottom: '10px' }}>Select Application Start Date & End Date</p>
                  </Col>
                  <Col md={2}>
                  </Col>
                  <Col md={1}>
                    <img src={isAppDateCollapsed ? 'https://webcdn.imarticus.org/Placement/chevron-up.svg' : 'https://webcdn.imarticus.org/Placement/chevron-down.svg'} style={{ objectFit: "contain", marginTop: '10px', marginLeft: '5px' }}
                      onClick={toggleAppStart}
                    />
                  </Col>
                </div>
                <div style={{ marginTop: '2rem' }}>
                </div>
                {!isAppDateCollapsed && (
                  <div className='addcrit-content'>
                    <li className="row">
                      <div className="col-md-12">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Select Timezone
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <div className="jobs-create-new-form">
                            <div>Timezone </div>
                            <select
                              className={`labs-create-new-form-text_input ${isJobPublished ? "readonly-select" : ""}`}
                              required={true}
                              name="timezone"
                              readOnly={isJobPublished}
                              value={formdata.timezone}
                              onChange={(e) => {
                                if (!isJobPublished) {
                                  updateFormdata(e);
                                }
                              }}
                            >
                              <option value="Asia/Calcutta">Asia/Calcutta</option>
                              {timezones
                                ? timezones.map((elem, i) => {
                                  return (
                                    <option value={elem} key={i}>
                                      {elem}
                                    </option>
                                  )
                                })
                                : null}
                            </select>
                          </div>
                        </FormGroup>
                      </div>
                    </li>
                    <li className='row'>
                      <div className="col-md-6">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Select Start Date & Time
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <input
                            type="datetime-local"
                            className="labs-create-new-form-text_input"
                            name="valid_from"
                            value={formdata.valid_from}
                            required={true}
                            onChange={updateFormdata}
                            readOnly={isJobPublished}
                          // min={currentFormattedDate}
                          // max={finalFormattedDate}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Select End Date & Time
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <input
                            type="datetime-local"
                            className="labs-create-new-form-text_input"
                            name="valid_till"
                            value={formdata.valid_till}
                            required={true}
                            onChange={updateFormdata}
                            min={formdata.valid_from}
                            readOnly={isJobPublished}
                          // max={finalFormattedDate}
                          />
                        </FormGroup>
                      </div>
                    </li>
                    <div className='addcrit-footer'>

                      <Row className="adques-footer">
                        <Col md={2} >
                        </Col>
                        <Col md={5}></Col>
                        <Col md={2}>
                        </Col>
                        <Col md={3}>
                          {isJobPublished ? (
                            <Button
                              className="jobs-table-btn-5"
                              // onClick={() => {
                              //   publishJobForLearners()
                              // }}
                              onClick={() => {
                                setOpenChangeEndDateModal(true)
                              }}
                            // disabled={isJobPublished}
                            >
                              Update End Time
                            </Button>
                          ) : (
                            <Button
                              className="jobs-table-btn-5"
                              // onClick={() => {
                              //   publishJobForLearners()
                              // }}
                              onClick={() => {
                                setOpenPublishModal(true)
                              }}
                              disabled={isJobPublished}
                            >
                              Publish Job
                            </Button>)}

                        </Col>
                      </Row>
                    </div>

                  </div>
                )}
              </div>
            </div>
          </div>

        </div>

        <Modal className="jobs-show-filter-modal" isOpen={openFilterChoice} centered>
          <ModalHeader>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Add Filters{' '}
            </div>
          </ModalHeader>
          <ModalBody
            className="thumbModal"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <li className="row">
              <div className="col-md-12">
                <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px", fontWeight: "600" }}>
                  Select Filters
                  <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                </h5>
                <br></br>
                <div className='job-fltr-modal'>
                  <FormGroup>
                    {Object.keys(filterForProduct).length > 0 && filterForProduct.gender && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData[openedProduct]?.gender.selected}
                              onClick={() => {
                                singleFilterSelect('gender', openedProduct)
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px", fontWeight: "400" }}>Gender</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct.total_exp && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData[openedProduct]?.total_exp.selected}
                            // onClick={() => {
                            //   singleFilterSelect('total_exp')
                            // }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px", fontWeight: "400" }}>Total Experience</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct.year && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData[openedProduct]?.year.selected}
                              onClick={() => {
                                singleFilterSelect('year', openedProduct)
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px", fontWeight: "400" }}>Graduation Pass Out Year</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct.notice_period && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData[openedProduct]?.notice_period.selected}
                              onClick={() => {
                                singleFilterSelect('notice_period', openedProduct)
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px", fontWeight: "400" }}>Notice Period</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct?.grad_grade && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData[openedProduct]?.grad_grade?.selected}
                              onClick={() => {
                                singleFilterSelect('grad_grade', openedProduct)
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px", fontWeight: "400" }}>Graduation Percentage</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct?.matric_grade && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData[openedProduct]?.matric_grade?.selected}
                              onClick={() => {
                                singleFilterSelect('matric_grade', openedProduct)
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px", fontWeight: "400" }}>10th Percentage</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct?.inter_grade && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData[openedProduct]?.inter_grade?.selected}
                              onClick={() => {
                                singleFilterSelect('inter_grade', openedProduct)
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px", fontWeight: "400" }}>12th Percentage</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct.night_shift && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData[openedProduct]?.night_shift.selected}
                              onClick={() => {
                                singleFilterSelect('night_shift', openedProduct)
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px", fontWeight: "400" }}>Open for Night Shifts</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct.intern && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData[openedProduct]?.intern.selected}
                              onClick={() => {
                                singleFilterSelect('intern', openedProduct)
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px", fontWeight: "400" }}>Open for Internship? (for PGA only)</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}

                    {Object.keys(filterForProduct).length > 0 && filterForProduct.contract && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData[openedProduct]?.contract.selected}
                              onClick={() => {
                                singleFilterSelect('contract', openedProduct)
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px", fontWeight: "400" }}>Open for Contractual Roles</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct.relocate && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData[openedProduct]?.relocate.selected}
                              onClick={() => {
                                singleFilterSelect('relocate', openedProduct)
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px", fontWeight: "400" }}>Relocation</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                  </FormGroup>
                </div>
              </div>
            </li>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                (openFilterChoice ? setOpenFilterChoice(false) : setOpenFilterChoice(true))
                setOpenedProduct('')
              }}
            >
              Close
            </Button>
            <Button
              style={{ background: "#014644", borderRadius: "4px" }}
              onClick={() => {
                (openFilterChoice ? setOpenFilterChoice(false) : setOpenFilterChoice(true))
                setOpenedProduct('')
              }}
            >
              Done
            </Button>
          </ModalFooter>
        </Modal>

        <Modal className="jobs-show-final-list-modal" isOpen={openSelectedFinalized} centered>
          <ModalBody
            className="thumbModal"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              paddingTop: '15px !important'
            }}
          >
            <li className="row">
              <div className="adques-selected-modal ">
                <div className="filter-selected-final-learner row">
                  <Col md={9} className="filter-selected-table-header">
                    <p style={{ marginBottom: '10px' }}>Final Learner List</p>
                  </Col>
                  <Col md={2}>
                  </Col>
                </div>
                <>
                  <Row style={{ marginLeft: '0px' }}>
                    <Col md={5}>
                      <Input
                        type="text"
                        name="text"
                        id="courseShortCode"
                        value={finalSelectedSearchNameKey}
                        onChange={(e) => setFinalSelectedSearchNameKey(e.target.value)}
                        style={{ width: '98%' }}
                        placeholder="Search"
                      />
                    </Col>
                    <Col md={2}></Col>
                    <Col md={5} style={{ display: "flex", justifyContent: "flex-end", paddingRight: "20px" }}>
                      <div style={{ width: "20px", height: "20px", borderRadius: "4px", backgroundColor: "#D9EAD3", marginRight: "8px" }}></div>Eligible Learners

                      <div style={{ width: "20px", height: "20px", borderRadius: "4px", backgroundColor: "#FFF2CC", marginLeft: "16px", marginRight: "8px" }}></div>Previously Ineligible Learners
                    </Col>
                  </Row>

                  <div className='addcrit-content'>
                    <Row style={{ marginTop: '1rem', marginLeft: "8px", marginRight: "8px" ,paddingLeft: "7px"}}>
                      <TableContainer component={Paper} style={{ height: '600px', border: "1px solid #000" }}>
                        <Table aria-label="custom pagination table" id="batch-data-table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className="modal-filter-dt-chk sticky-header-both-first-1"
                                scope="col"
                              >
                                S No.
                              </TableCell>
                              <TableCell
                                className="modal-filter-dt-th sticky-header-both-second-1"
                                scope="col"
                              >
                                Student Name
                              </TableCell>
                              <TableCell
                                className="modal-filter-dt-th sticky-header-both-third-1"
                                scope="col"
                                style={{ cursor: 'pointer' }}
                              >
                                Training Location
                                <span
                                  style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                >
                                </span>
                              </TableCell>
                              <TableCell
                                className="modal-filter-dt-th modal-sticky-header"
                                scope="col"
                                style={{ cursor: 'pointer' }}
                              >
                                Batch Name
                                <span
                                  style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                >
                                </span>
                              </TableCell>
                              <TableCell
                                className="modal-filter-dt-th modal-sticky-header"
                                scope="col"
                                style={{ cursor: 'pointer' }}
                              >
                                Product
                                <span
                                  style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                >
                                </span>
                              </TableCell>
                              <TableCell className="modal-filter-dt-th modal-sticky-header" scope="col">
                                Resume Approved
                              </TableCell>
                              <TableCell className="modal-filter-dt-th modal-sticky-header" scope="col">
                                User Status
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {eligibleArray &&
                              eligibleArray
                                .filter(dt => {
                                  const matchesSearch = finalSelectedSearchNameKey === "" || dt.name.toLowerCase().includes(finalSelectedSearchNameKey.toLowerCase());

                                  // Check if the candidate's aid is in the selectedAids array
                                  // const isAidSelected = selectedAids.includes(dt.aid);

                                  // Ensure the aid is not already processed (display the first occurrence)
                                  const isFirstOccurrence = !displayedAids.has(dt.aid);

                                  // If all conditions are met, add the aid to the displayedAids set and return true
                                  if (matchesSearch && isFirstOccurrence) {
                                    displayedSelected.add(dt.aid);
                                    return true;
                                  }

                                  return false;
                                })
                                .map((dt, key) => {
                                  return (
                                    <TableRow>
                                      <TableCell
                                        className="modal-dt-td-filter modal-sticky-second-1"
                                        scope="row"
                                      >{finalSelectedNum++}</TableCell>
                                      <TableCell
                                        className="modal-rollout-dt-td-eligible modal-sticky-third-1"
                                        scope="row"
                                        style={{ background: "#D9EAD3 !important" }}
                                      >
                                        {dt.name}
                                      </TableCell>
                                      <TableCell
                                        className="modal-dt-td-filter  modal-sticky-fourth-1"
                                        scope="row"
                                      >
                                        {dt.loc_name}
                                      </TableCell>
                                      <TableCell
                                        className="modal-dt-td-filter "
                                        scope="row"
                                      >
                                        {dt.batch_name}
                                      </TableCell>
                                      <TableCell className="modal-dt-td-filter " scope="row">
                                        {dt.course_name || "-"}
                                      </TableCell>
                                      <TableCell className="modal-dt-td-filter " scope="row">
                                        <a href="/head" className="resume-link"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleEligibleResumeModalOpen(dt.aid);
                                          }}>
                                          View Resume
                                        </a>
                                      </TableCell>
                                      <TableCell className="modal-dt-td-filter " scope="row">
                                        {'Eligible'}
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                            {ineligibleArray &&
                              ineligibleArray
                                .filter(dt => {
                                  const matchesSearch = finalSelectedSearchNameKey === "" || dt.name.toLowerCase().includes(finalSelectedSearchNameKey.toLowerCase());

                                  // Check if the candidate's aid is in the selectedAids array
                                  // const isAidSelected = selectedAids.includes(dt.aid);

                                  // Ensure the aid is not already processed (display the first occurrence)
                                  const isFirstOccurrence = !displayedSelected.has(dt.aid);

                                  // If all conditions are met, add the aid to the displayedAids set and return true
                                  if (matchesSearch && isFirstOccurrence) {
                                    displayedSelected.add(dt.aid);
                                    return true;
                                  }

                                  return false;
                                })
                                .map((dt, key) => {
                                  return (
                                    <TableRow>
                                      <TableCell
                                        className="modal-dt-td-filter modal-sticky-second-1"
                                        scope="row"
                                      >{finalSelectedNum++}</TableCell>
                                      <TableCell
                                        className="modal-rollout-dt-td-ineligible modal-sticky-third-1"
                                        scope="row"
                                        style={{ background: "#FFF2CC !important" }}
                                      >
                                        {dt.name}
                                      </TableCell>
                                      <TableCell
                                        className="modal-dt-td-filter  modal-sticky-fourth-1"
                                        scope="row"
                                      >
                                        {dt.loc_name}
                                      </TableCell>
                                      <TableCell
                                        className="modal-dt-td-filter "
                                        scope="row"
                                      >
                                        {dt.batch_name}
                                      </TableCell>
                                      <TableCell className="modal-dt-td-filter " scope="row">
                                        {dt.course_name || "-"}
                                      </TableCell>
                                      <TableCell className="modal-dt-td-filter " scope="row">
                                        <a href="/head" className="resume-link"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleIneligibleResumeModalOpen(dt.aid);
                                          }}>
                                          View Resume
                                        </a>
                                      </TableCell>
                                      <TableCell className="modal-dt-td-filter " scope="row">
                                        {'Ineligible'}
                                      </TableCell>

                                    </TableRow>
                                  )
                                })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Row>
                  </div>
                </>
              </div>
            </li>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={handleFinalModalClose}
            >
              Close
            </Button>
            <Button
              style={{ background: "#014644", borderRadius: "4px" }}
              onClick={() => {
                handleFinalModalClose()
                finalizeLearnersForCourse()
              }}
            >
              Done
            </Button>
          </ModalFooter>
        </Modal>

        <Modal className="jobs-viewresumemodal" isOpen={openResumeView} centered>
          {/* <ModalHeader>
            <li className="row">
              <div className="col-md-8">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {selectedLearner ? `${selectedLearner.name}'s Resume` : "Learner's Resume"}
                </div>
              </div>
              {selectedLearner && selectedLearnerResume && selectedLearnerResume.length > 0 && (
                <div className='res-dl-btn col-md-4 '>
                  Download Resumes
                </div>)}
            </li>
          </ModalHeader> */}

          <ModalBody
            className="thumbModal"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <li className="row">
              <div className="viewres-txt">
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '20px', fontWeight: '700', marginLeft: "32px" }}>
                  {selectedLearner ? `${selectedLearner.name}'s Resume` : "Learner's Resume"}
                </div>
                {selectedLearner && selectedLearnerResume && selectedLearnerResume.length > 0 && (
                  <div className='res-dl-btn ' style={{ marginRight: "32px",cursor: "pointer" }} onClick={() => { handleBulkDownload() }}>
                    Download Resumes
                  </div>
                )}
              </div>

            </li>
            {selectedLearner && selectedLearnerResume && selectedLearnerResume.length == 0 && (
              <div>No resume found</div>
            )}
            {selectedLearnerResume
              .filter((file) => file.resume_type === 2)
              .map((file, index) => (
                <div key={index}>
                  <div className="resume-div drop-zone">
                    <li className="row">
                      <div className="col-md-2" style={{ display: "flex", justifyContent: "center" }}>
                        <img
                          className="resume-icon-2"
                          src="https://webcdn.imarticus.org/Placement/fluent_document-pdf-24-filled.svg"
                        />
                      </div>
                      <div className="col-md-4">
                        <h5 className="resume-heading">
                          Resume {index + 1}
                        </h5>
                      </div>
                      <div className='col-md-3'>
                        <p>{moment(file.createdAt).format("DD-MM-YYYY")}</p>
                      </div>
                      <div className="col-md-1">
                      </div>
                      <div className="col-md-2">
                        <img
                          src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                          className="as-dl-logo-job-2"
                          onClick={() => previewResumeClick(file.resume_url)}
                          style={{cursor: "pointer"}}
                        />
                      </div>
                    </li>
                  </div>
                  <br />
                </div>
              ))}

            {selectedLearnerResume
              .filter((file) => file.resume_type === 1)
              .map((file, index) => (
                <div key={index}>
                  <div className="resume-div drop-zone">
                    <li className="row">
                      <div className="col-md-2" style={{ display: "flex", justifyContent: "center" }}>
                        <img
                          className="resume-icon-2"
                          src="https://webcdn.imarticus.org/Placement/mingcute_video-fill.svg"
                        />
                      </div>
                      <div className="col-md-4">
                        <h5 className="resume-heading">
                          Video Resume {index + 1}
                        </h5>
                      </div>
                      <div className='col-md-3'>
                        <p>{moment(file.createdAt).format("DD-MM-YYYY")}</p>
                      </div>
                      <div className="col-md-1">
                      </div>
                      <div className="col-md-2">
                        <a
                          href={file.resume_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                            className="as-dl-logo-job-2"
                            alt="View Resume"
                          />
                        </a>
                      </div>
                    </li>
                  </div>
                  <br />
                </div>
              ))}
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => handleResumeModalClose()}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={togglePDFDoc} className="resume-preview-modal" size={"md"} style={{ minwidth: "400px" }} centered >
          <ModalHeader
            className="resume-prev"
            style={{ paddingRight: "7px", width: "100%" }}
          >
            <div className="row" style={{ justifyContent: "space-between", marginLeft: "12px", marginRight: "12px" }}>
              <div>Resume Preview </div>
              <div>
                <img
                  src="https://webcdn.imarticus.org/Placement/Group926.svg"
                  style={{ paddingRight: "7px" }}
                  onClick={() => setTogglePDFDoc(false)}
                />
              </div>
            </div>
          </ModalHeader>
          <ModalBody
            style={{
              minHeight: "500px",
              overflowY: "scroll",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {/* <PDFReader url={resumeURL}></PDFReader> */}
            <iframe
              src={resumeURL}
              style={{ width: "100%", height: "100%", border: "none", minHeight: "750px" }}
              frameBorder="0"
            />
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}></ModalFooter>
        </Modal>
        <Modal
          style={{ height: 'fit-content', margin: 'auto', marginTop: 'auto' }}
          isOpen={openGradebookModal}
          centered
        >
          <ModalHeader>Gradebook</ModalHeader>
          <ModalBody
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >


            {studentGradebook &&
              enableCategory &&
              studentGradebook.map((grd, index) => {
                return (
                  <div key={index}>
                    {grd.length == 0 ? (
                      <div className="rvm-v1-cont-ctnr bottomRowGradebook">
                        <div
                          className="row no-margin gradeBlock"
                          style={{
                            display: "block",
                            width: "100vw",
                            paddingTop: "8vh",
                            flex: "0 1 100vw",
                            maxWidth: "100vw",
                          }}
                        >
                          <div className="col-12" style={{ padding: 0 }}>
                            <h2
                              style={{
                                color: "#055646",
                                fontWeight: 600,
                                fontSize: "20px",
                                lineHeight: "24px",
                              }}
                            >
                              <span style={{ borderBottom: "2px solid #055646" }}>
                                {isElec
                                  ? elecNameMap[grd.elec_id]
                                    ? elecNameMap[grd.elec_id]
                                    : "Core Track"
                                  : ""}
                              </span>
                            </h2>

                            <span className="my-grade">
                              {!grd.elec_id && "My Grades"}
                            </span>
                          </div>
                          <hr />
                          <div style={{ textAlign: "center" }}>
                            <img
                              src="http://cdn.pegasus.imarticus.org/images/gradebook_empty.svg"
                              alt="Empty Gradebook"
                            />
                            <br />
                            <br />
                            <span>
                              There are no quizzes and assignments to show at this
                              moment.
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={`rvm-v1-cont-ctnr bottomRowGradebook`}>
                        <div
                          className="row no-margin gradeBlock gradeBlockPadding"
                          style={{ display: "block" }}
                        >

                          <div className="col-12" style={{ padding: 0 }}>
                            <h2
                              style={{
                                color: "#055646",
                                fontWeight: 600,
                                fontSize: "20px",
                                lineHeight: "24px",
                              }}
                            >
                              <span style={{ borderBottom: "2px solid #055646" }}>
                                {isElec
                                  ? elecNameMap[grd.elec_id]
                                    ? elecNameMap[grd.elec_id]
                                    : "Core Track"
                                  : ""}
                              </span>

                            </h2>

                            <span className="my-grade">
                              {!isElec && !grd.elec_id && "My Grades"}
                            </span>
                          </div>
                          <hr />

                          <div className="flexRow">
                            <table
                              className="table table-bordered gradeTooltip"
                              style={{ minWidth: "700px" }}
                            >
                              <thead className="th-bg-clr">
                                <tr>
                                  <th className="t-center th-fnt">Category</th>
                                  <th className="t-center th-fnt">
                                    Marks Scored
                                  </th>
                                  <th className="t-center th-fnt">
                                    Total Marks{" "}
                                  </th>
                                  {showPlcStatus && placementEnable && (
                                    <th className="t-center th-fnt">
                                      Placement Eligibility Cutoff &nbsp;
                                      <TooltipItem
                                        style={{ backgroundColor: "none" }}
                                        id={"namePopup1" + index}
                                        batch="Sum of scores of all the assessment  should be greater than or equal to the Placement cut off score to be eligible for placement."
                                      />
                                    </th>
                                  )}
                                  {showPassMarks && !placementEnable && (
                                    <th className="t-center th-fnt">
                                      Passing Marks
                                    </th>
                                  )}
                                  {showPassMarks &&
                                    placementEnable &&
                                    !showPlcStatus && (
                                      <th className="t-center th-fnt">
                                        Passing Marks
                                      </th>
                                    )}
                                  {showWeightage && (
                                    <th className="t-center th-fnt">
                                      Assigned Weightage %
                                    </th>
                                  )}
                                  {showWeightage && (
                                    <th className="t-center th-fnt">
                                      Weighted % Scored
                                    </th>
                                  )}
                                  {placementEnable && showPlcStatus && (
                                    <th className="t-center th-fnt">
                                      Placement Status
                                    </th>
                                  )}
                                  {!placementEnable && showContentStatus && (
                                    <th className="t-center th-fnt">Status</th>
                                  )}
                                  {placementEnable &&
                                    showContentStatus &&
                                    !showPlcStatus && (
                                      <th className="t-center th-fnt">Status</th>
                                    )}
                                </tr>
                              </thead>
                              <tbody>
                                {grd.catg &&
                                  grd.catg.map((category, index) => {
                                    return (
                                      <Fragment key={`${index}${category.name}`}>
                                        {category.dids &&
                                          category.dids.length > 0 && (
                                            <>
                                              {" "}
                                              <tr
                                                style={{ cursor: "pointer" }}
                                              // onClick={() =>
                                              //   handleClick(
                                              //     index,
                                              //     `${index}${category.name}`
                                              //   )
                                              // }
                                              >
                                                <td className="bg-white-clr tr-fnt">
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      flexDirection: "row",
                                                    }}
                                                  >
                                                    {" "}
                                                    <div>
                                                      {category.name
                                                        ? category.name
                                                        : "-"}
                                                    </div>{" "}
                                                    <div>
                                                      {" "}
                                                      <img
                                                        src={
                                                          collapseRow[
                                                            `${index}${category.name}`
                                                          ]
                                                            ? "https://webcdn.imarticus.org/Pegasus/uparrow.svg"
                                                            : "https://cdn.pegasus.imarticus.org/Gradebook/dropdown.svg"
                                                        }
                                                      ></img>
                                                    </div>
                                                  </div>

                                                </td>

                                                <td className="bg-white-clr tr-fnt">
                                                  {category.marks_scored || category.marks_scored == 0
                                                    ? category.marks_scored
                                                    : "-"}
                                                </td>
                                                <td className="bg-white-clr tr-fnt">
                                                  {category.total_marks || category.total_marks == 0
                                                    ? category.total_marks
                                                    : "-"}
                                                </td>
                                                {showPlcStatus &&
                                                  placementEnable && (
                                                    <td className="bg-white-clr tr-fnt">
                                                      {category.plac_cutoff || category.plac_cutoff == 0
                                                        ? category.plac_cutoff
                                                        : "-"}
                                                    </td>
                                                  )}
                                                {showPassMarks &&
                                                  !placementEnable && (
                                                    <td className="bg-white-clr tr-fnt">
                                                      {category.pass_marks || category.pass_marks == 0
                                                        ? category.pass_marks
                                                        : "-"}
                                                    </td>
                                                  )}
                                                {showPassMarks &&
                                                  placementEnable &&
                                                  !showPlcStatus && (
                                                    <td className="bg-white-clr tr-fnt">
                                                      -
                                                    </td>
                                                  )}
                                                {showWeightage && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.weight || category.weight == 0
                                                      ? category.weight
                                                      : "-"}
                                                  </td>
                                                )}
                                                {showWeightage && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.weightedPView || category.weightedPView == 0
                                                      ? category.weightedPView
                                                      : "-"}
                                                  </td>
                                                )}

                                                {placementEnable &&
                                                  showPlcStatus && (
                                                    <td className="bg-white-clr tr-fnt">
                                                      {category.status
                                                        ? category.status
                                                        : "-"}
                                                    </td>
                                                  )}
                                                {!placementEnable &&
                                                  showContentStatus && (
                                                    <td className="bg-white-clr tr-fnt">
                                                      {category.all_cagetory_content_pass_status
                                                        ? category.all_cagetory_content_pass_status
                                                        : "-"}
                                                    </td>
                                                  )}
                                                {placementEnable &&
                                                  showContentStatus &&
                                                  !showPlcStatus && (
                                                    <td className="bg-white-clr tr-fnt">
                                                      {category.all_cagetory_content_pass_status
                                                        ? category.all_cagetory_content_pass_status
                                                        : "-"}
                                                    </td>
                                                  )}
                                              </tr>
                                              {collapseRow[
                                                `${index}${category.name}`
                                              ] && category.dids ? (
                                                <>
                                                  <tr>
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      My Assessments
                                                    </td>
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Marks Scored
                                                    </td>
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Total Marks
                                                    </td>
                                                    {showPassMarks && (
                                                      <td
                                                        className="bg-blue-clr"
                                                        style={{
                                                          textAlign: "center",
                                                          fontWeight: 600,
                                                          fontSize: "16px",
                                                        }}
                                                      >
                                                        Pass Marks
                                                      </td>
                                                    )}
                                                    {showWeightage && (
                                                      <td
                                                        className="bg-blue-clr"
                                                        style={{
                                                          textAlign: "center",
                                                          fontWeight: 600,
                                                          fontSize: "16px",
                                                        }}
                                                      >
                                                        Assigned Weight%
                                                      </td>
                                                    )}
                                                    {showWeightage && (
                                                      <td
                                                        className="bg-blue-clr"
                                                        style={{
                                                          textAlign: "center",
                                                          fontWeight: 600,
                                                          fontSize: "16px",
                                                        }}
                                                      >
                                                        Weighted % Scored
                                                      </td>
                                                    )}

                                                    {showContentStatus && (
                                                      <td
                                                        className="bg-blue-clr"
                                                        style={{
                                                          textAlign: "center",
                                                          fontWeight: 600,
                                                          fontSize: "16px",
                                                        }}
                                                      >
                                                        Assessment Status
                                                      </td>
                                                    )}
                                                  </tr>
                                                  {category.dids.map((did) => {
                                                    return (
                                                      <tr>
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.name
                                                            ? did.name
                                                            : "-"}
                                                        </td>
                                                        {/* <td className="bg-blue-clr tr-fnt">
                                                  {did.marks ? did.marks:'-'}
                                                </td> */}
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {/* {did.status
                                                          ? did.status === 1
                                                            ? `${did.marks}`
                                                            : did.status === 2
                                                              ? "Under Evaluation"
                                                              : "Absent"
                                                          : did.marks !== "-"
                                                            ? `${did.marks}`
                                                            : "Absent"} */}
                                                          {did.marks || did.marks == 0
                                                            ? did.marks
                                                            : "-"}
                                                        </td>
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.total_marks || did.total_marks == 0
                                                            ? did.total_marks
                                                            : "-"}
                                                        </td>

                                                        {showPassMarks && (
                                                          <td className="bg-blue-clr tr-fnt">
                                                            {did.pasmrk || did.pasmrk == 0
                                                              ? did.pasmrk
                                                              : "-"}
                                                          </td>
                                                        )}
                                                        {showWeightage && (
                                                          <td className="bg-blue-clr tr-fnt">
                                                            {did.weight || did.weight == 0
                                                              ? did.weight
                                                              : "-"}
                                                          </td>
                                                        )}
                                                        {showWeightage && (
                                                          <td className="bg-blue-clr tr-fnt">
                                                            {did.weight || did.weight == 0
                                                              ? did.weightedPView
                                                              : "-"}
                                                          </td>
                                                        )}

                                                        {showContentStatus && (
                                                          <td className="bg-blue-clr tr-fnt">
                                                            {did.showStatus
                                                              ? did.showStatus
                                                              : "-"}
                                                          </td>
                                                        )}
                                                      </tr>
                                                    );
                                                  })}
                                                </>
                                              ) : null}
                                            </>
                                          )}
                                      </Fragment>
                                    );
                                  })}
                                {/* Render assignment data */}

                                <tr>
                                  <th
                                    className="t-center th-fnt"
                                    colSpan={columnSpan} // ----------------------
                                    style={{ textAlign: "left" }}
                                  >
                                    Total Grade (%)
                                  </th>
                                  <th
                                    className="t-center th-fnt total"
                                    colSpan="1"
                                    style={{ position: "relative" }}
                                  >
                                    {grdTotalPerc[grd.elec_id]
                                      ? grdTotalPerc[grd.elec_id].toFixed(2)
                                      : grdTotalPerc["FOUNDATION"]
                                        ? grdTotalPerc["FOUNDATION"].toFixed(2)
                                        : "-"}
                                    % &nbsp;&nbsp;
                                    {totalWeightAssignedP < 100 && (
                                      <TooltipItem
                                        id="namePopupGrade2"
                                        batch=" You will be able to see your final
                                 Grade for the course once all your
                                 assessment scores have been
                                 published."
                                      />
                                    )}
                                  </th>
                                </tr>
                                {showPlcStatus &&
                                  placementEnable &&
                                  allAssessmentPublished && (
                                    <>
                                      {" "}
                                      <tr style={{ backgroundColor: "white" }}>
                                        <th
                                          className="t-center th-fnt"
                                          colSpan={
                                            // showWeightage &&
                                            // showPassMarks &&
                                            // placementEnable
                                            //   ? 5
                                            //   : (!showWeightage && showPassMarks) ||
                                            //     (showWeightage && !showPassMarks)
                                            //   ? 4
                                            //   : placementEnable
                                            //   ? 3
                                            //   : 2
                                            columnSpan
                                          } // ----------------------
                                          style={{
                                            textAlign: "left",
                                            weight: 60,
                                            size: "20px",
                                          }}
                                        >
                                          Placement Status
                                        </th>
                                        <th
                                          className="t-center th-fnt total"
                                          colSpan="1"
                                          style={{ position: "relative" }}
                                        >
                                          {/* {grdPlsStatus[grd.elec_id]
                                    ? (grdPlsStatus[grd.elec_id] ? 'PASS' :'FAIL')
                                    : grdPlsStatus["FOUNDATION"]
                                    ? (grdPlsStatus["FOUNDATION"]?'PASS' :'FAIL')
                                    : "-"} */}
                                          {grd.elec_id &&
                                            (grdPlsStatus[grd.elec_id]
                                              ? plcPassStatusText
                                              : plcFailStatusText)}
                                          {!grd.elec_id &&
                                            (grdPlsStatus["FOUNDATION"]
                                              ? plcPassStatusText
                                              : plcFailStatusText)}{" "}
                                        </th>
                                      </tr>
                                      <tr style={{ backgroundColor: "white" }}>
                                        <th
                                          className="t-center th-fnt"
                                          colSpan={
                                            // showWeightage && showPassMarks
                                            //   ? 6
                                            //   : (!showWeightage && showPassMarks) ||
                                            //     (showWeightage && !showPassMarks)
                                            //   ? 5
                                            //   : 4
                                            columnSpan + 1
                                          } // ----------------------
                                          style={{ textAlign: "left" }}
                                        >
                                          {" "}
                                          {grd.elec_id &&
                                            (grdPlsStatus[grd.elec_id]
                                              ? `${showPlcPassStatus}`
                                              : `${showPlcFailStatus}`)}
                                          {!grd.elec_id &&
                                            (grdPlsStatus["FOUNDATION"]
                                              ? `${showPlcPassStatus}`
                                              : `${showPlcFailStatus}`)}
                                        </th>
                                      </tr>
                                    </>
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>




                      </div>
                    )}
                  </div>
                );
              })}

            {grddata && grddata.contentElecMap &&
              !enableCategory &&
              Object.keys(grddata.contentElecMap).map((grd, index) => {
                return (
                  <div key={index}>
                    {grddata.contentElecMap[grd] && grddata.contentElecMap[grd].length == 0 ? (
                      <div className="rvm-v1-cont-ctnr bottomRowGradebook">
                        <div
                          className="row no-margin gradeBlock"
                          style={{
                            display: 'block',
                            width: '100vw',
                            paddingTop: '8vh',
                            flex: '0 1 100vw',
                            maxWidth: '100vw',
                          }}
                        >
                          <div className="col-12" style={{ padding: 0 }}>
                            <h2
                              style={{
                                color: '#055646',
                                fontWeight: 600,
                                fontSize: '20px',
                                lineHeight: '24px',
                              }}
                            >
                              {/* {elecNameMap[grd] &&  <> <span style={{ borderBottom: "2px solid #055646" }}>
                           
                           </span>
                           </>} */}
                              <span className="my-grade">{'My Grades'}</span>
                            </h2>
                          </div>
                          <hr />
                          <div style={{ textAlign: 'center' }}>
                            <img
                              src="http://cdn.pegasus.imarticus.org/images/gradebook_empty.svg"
                              alt="Empty Gradebook"
                            />
                            <br />
                            <br />
                            <span>
                              There are no quizzes and assignments to show at this
                              moment.
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className={`rvm-v1-cont-ctnr bottomRowGradebook`}>

                          <div
                            className="row no-margin gradeBlock gradeBlockPadding"
                            style={{ display: 'block' }}
                          >
                            <div className="col-12" style={{ padding: 0 }}>
                              <h2
                                style={{
                                  color: '#055646',
                                  fontWeight: 600,
                                  fontSize: '20px',
                                  lineHeight: '24px',
                                }}
                              >
                                <span
                                  style={{ borderBottom: '2px solid #055646' }}
                                >
                                  {isElec
                                    ? elecNameMap[grd]
                                      ? elecNameMap[grd]
                                      : 'Core Track'
                                    : ''}
                                </span>
                              </h2>

                              {!grddata.elec_enable && (
                                <span className="my-grade">{'My Grades'}</span>
                              )}
                            </div>
                            <hr />

                            <div className="flexRow">
                              <table
                                className="table table-bordered gradeTooltip"
                                style={{ minWidth: '700px' }}
                              >
                                <thead className="th-bg-clr">
                                  <tr>
                                    <th className="t-center th-fnt">
                                      Assessment/Quiz
                                    </th>
                                    <th className="t-center th-fnt">
                                      Actual Marks
                                    </th>
                                    <th className="t-center th-fnt">
                                      Total Marks
                                    </th>

                                    {showWeightage && (
                                      <th className="t-center th-fnt">
                                        Assigned Weightage%
                                      </th>
                                    )}
                                    {showPassMarks && (
                                      <th className="t-center th-fnt">
                                        Passing Marks
                                      </th>
                                    )}
                                    {showWeightage && (
                                      <th className="t-center th-fnt">
                                        Weighted % Scored

                                      </th>
                                    )}
                                    {showContentStatus && <th className="t-center th-fnt">  Assessment Status</th>}

                                  </tr>
                                </thead>
                                <tbody>
                                  {/* Render assignment data */}
                                  {grddata.contentElecMap[grd].map((ag) => (
                                    <tr key={ag.nm}>
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.name ? ag.name : '-'}
                                      </td>
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.marks || ag.marks == 0 ? ag.marks : "-"}
                                      </td>
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.total_marks || ag.total_marks == 0 ? ag.total_marks : ag.maxmrk}
                                      </td>

                                      {showWeightage && (
                                        <td className="bg-white-clr tr-fnt">
                                          {ag.weight || ag.weight == 0 ? ag.weight : '-'}%
                                        </td>
                                      )}
                                      {showPassMarks && (
                                        <td className="bg-white-clr tr-fnt">
                                          {ag.pasmrk || ag.pasmrk == 0 ? ag.pasmrk : '-'}
                                        </td>
                                      )}
                                      {showWeightage && (
                                        <td className="bg-white-clr tr-fnt">
                                          {ag.weightedPView || ag.weightedPView == 0 ? ag.weightedPView : '-'}%
                                        </td>
                                      )}
                                      {showContentStatus && <td className="bg-white-clr tr-fnt">
                                        {ag.showStatus || ag.showStatus == 0 ? ag.showStatus : "-"}

                                      </td>}
                                    </tr>
                                  ))}

                                  <tr>
                                    <th
                                      className="t-center th-fnt"
                                      // colSpan={showWeightage ? "3" : "2"}
                                      colSpan={showContentStatus ?
                                        showWeightage && showPassMarks
                                          ? 6
                                          : showWeightage && !showPassMarks
                                            ? 5
                                            : !showWeightage && showPassMarks
                                              ? 4
                                              : 3 :
                                        showWeightage && showPassMarks
                                          ? 5
                                          : showWeightage && !showPassMarks
                                            ? 4
                                            : !showWeightage && showPassMarks
                                              ? 3 : 2
                                      }// ----------------------
                                      style={{ textAlign: 'left' }}
                                    >
                                      Total Grade (%)
                                    </th>
                                    <th
                                      className="t-center  total"
                                      colSpan="1"
                                      style={{ position: 'relative' }}
                                    >
                                      {grdTotalPerc[grd]
                                        ? grdTotalPerc[grd].toFixed(2)
                                        : "-"} %&nbsp;&nbsp;
                                      {/* {totalWeightAssignedP < 100 && (
                                        <TooltipItem
                                          id="namePopupGrade25"
                                          batch=" You will be able to see your final
                                    Grade for the course once all your
                                    assessment scores have been
                                    published."
                                        />
                                      )} */}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}

            <div>
              You will be able to see the final Grades for the course once all
              the assessment scores have been published.
            </div>

          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={(e) => { e.stopPropagation(); setOpenGradebookModal(false) }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={openPublishModal}
          style={{ width: '20%' }}
          size={"xl"}
          className="apply-sucess-modal"
          centered
        >
          <ModalBody style={{ maxHeight: "800px" }}>
            <div>
              <div className="prof-dtl-img ">
              </div>
              <p className="aply-success-txt">
                Are you sure you want to Publish the Job?
              </p>
              <p className="prof-dtl-txt">
              </p>
            </div>
            <div style={{ justifyContent: "center", display: "flex", marginTop: "30px", marginLeft: "20px", marginRight: "20px" }}>
              <div style={{ width: "48%" }}>
                <Button
                  style={{ width: "100%", borderRadius: "4px" }}
                  onClick={() => {
                    setOpenPublishModal(false);
                    // setSelectedJob('')
                  }}
                  className="apply-success-btn"
                  color="danger"
                >
                  No
                </Button>
              </div>
              <div style={{ width: "48%", marginLeft: "12px" }}>
                <Button
                  onClick={() => {
                    publishJobForLearners()
                  }}
                  style={{ background: "#014644", width: "100%", borderRadius: "4px" }}
                >
                  Yes
                </Button>
              </div>

            </div>
          </ModalBody>
        </Modal>

        <Modal className="jobs-grantaccessmodal" isOpen={openChangeEndDateModal} centered>
          <ModalHeader>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Update Application End Date & Time
            </div>
          </ModalHeader>
          <ModalBody
            className="thumbModal"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <li className="row">
              <div className="col-md-12">
                <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                  New Application End Date & Time
                  <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                </h5>
                <FormGroup>
                  <input
                    type="datetime-local"
                    className="labs-create-new-form-text_input"
                    name="valid_till"
                    value={formdata.valid_till}
                    required={true}
                    onChange={updateFormdata}
                    min={formdata.valid_from}
                  />
                </FormGroup>
              </div>
            </li>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                setOpenChangeEndDateModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="success"
              onClick={() => {
                updateEndTimeForLearners()
                // setOpenChangeEndDateModal(false);
              }}
            >
              Done
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={openSuccessModal}
          style={{ width: '20%' }}
          size={"xl"}
          className="apply-sucess-modal"
          centered
        >
          <ModalBody style={{ maxHeight: "800px" }}>
            <div>
              <div className="prof-dtl-img ">
              </div>
              <p className="aply-success-txt">
                Job published successfully
              </p>
              <p className="prof-dtl-txt">
                Learners will be notified about the job to apply
              </p>
            </div>
            <div style={{ justifyContent: "center", display: "flex", marginTop: "30px", marginLeft: "20px", marginRight: "20px" }}>
              <div style={{ width: "48%" }}>
                <Button
                  onClick={() => {
                    setOpenSuccessModal(false)
                    history.push(`/career-module/preview-job-description/${jobid}`)
                  }}
                  style={{ background: "#014644", width: "100%", borderRadius: "4px" }}
                >
                  Done
                </Button>
              </div>

            </div>
          </ModalBody>
        </Modal>
      </>
    </div>
  )

}


export default AddJobRolloutFilters