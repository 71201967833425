import React, { useEffect, useState } from 'react'
import TopBar2 from './Components/TopBar2'
import Select from "react-select"
import styles from './index_module.scss'
import Sidebar2 from './Components/Sidebar2'
import { connect } from 'react-redux'
import './PlacementPortal.scss'
import { history } from '../..'
import '../../Components/style/assignmentDeadline.css'
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Autocomplete,
  TextField,
  ToggleButton, ToggleButtonGroup
} from '@mui/material'
import {
    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    Input,
    ModalBody,
    Label,
    FormGroup,
  } from 'reactstrap'
import { COUNTRY_CODES } from "../../constants/constant";
import MiniHeader from './Components/MiniHeader'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'
import { LOCATION_CHANGE } from '../../actions/actiontypes.js'
import {
  saveAddedQuestion,
  deleteAddedQuestion,
  saveEditedQuestion
} from '../../actions/jobAction.js'
const configs = require('../../config/configs.js')

const AddAdditionalQuestions = (props) => {
  const [requests, setRequests] = useState([])
  const [quesToEdit, setQuesToEdit] = useState(0)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [createdQuestions, setCreatedQuestions] = useState([])
  const [tempQuestion, setTempQuestion] = useState('')
  const [locationName, setLocationName] = useState('All')
  const [locationId, setLocationId] = useState('') 
  const [refresh, setRefresh] = useState(false)
  const [hoverImage, setHoverImage] = useState(false)
  const [openQuesCreate, setOpenQuesCreate] = useState(false)
  const [listOptions, setListOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [reviewStatus, setReviewStatus] = useState('All')  
  const [isObjectiveQues, setIsObjectiveQues] = useState(true)
  const [data, setData] = useState([])
  const [isJobPublished,setIsJobPublished] = useState(false)
  const [searchOptions,setSearchOptions] = useState([{value :'job_id',
  label:'Search By Job ID'} ,{value:'company_name', label:'Search By Company Name'}, { value:'job_title',label:'Search By Job Title'}])
  const [selectedSearchOptions,setSelectedSearchOptions] = useState('')
  const pathname = window.location.pathname
  const jobid = pathname.split('/').slice(-1)[0]

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const getAllQuestions = () => {
      return new Promise((resolve, reject) => {
        var getData = {
          url: `${
            configs.routes.BASE_URL_DEVELOPMENT
          }/cfpanelPlacement/get_all_additionalquestions`,
          method: 'POST',
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          data: { jobid },
        }
        Axios(getData)
          .then((res) => {
            let tmp_data =
              res.data.data && res.data.data.allQuestions
                ? res.data.data.allQuestions
                : []
            let result = []
            setCreatedQuestions(tmp_data)
            setData(tmp_data)
            setIsLoading(false)
            resolve(result)

            if(res.data.data && res.data.data.isJobPublished){
              setIsJobPublished(res.data.data.isJobPublished)
            }

          })
          .catch((err) => {
            setIsLoading(false)
            console.error(err)
            reject(err)
          })
      })
    }
    getAllQuestions()
    

  }, [refresh, page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const checkVisibility = (resource,resourceVal = 1) => {

    if (!props.permissions) {
      return true
    } else {
      if (!props.permissions[resource]) {
        return false
      } else if (props.permissions[resource].indexOf(resourceVal) != -1) {
        return true
      } else {
        return false
      }
    }
  }

  const handleModalClose = () => {
    setOpenQuesCreate(false)
  }

  const handleEditModalClose = () => {
    setTempQuestion('')
    setListOptions([])
    setIsObjectiveQues(true)
    setOpenEditModal(false)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  const locationChanged = (locationName, locationId) => {
    setLocationName(locationName)
    setLocationId(locationId)
  }
  const reviewStatusChanged = (reviewStatus) => {
    setReviewStatus(reviewStatus)
  }

  const handleAddQuesStatusChange = () => {
    setIsObjectiveQues(!isObjectiveQues)
    setListOptions([])
  }

  const handleEditQuesStatusChange = () => {
    setIsObjectiveQues(!isObjectiveQues)
    // setListOptions([])
  }

  const editOptionData = (id, value) => {
    const updatedOptions = listOptions.map(item => {
      if (item.id === id) {
        return { ...item, optiondata: value };
      }
      return item;
    });
  
    // Assuming setOptions is the function to update the options array
    setListOptions(updatedOptions);
  };

  const addAdditionalOption = () => {
    // Create a new list item object
    

    if(listOptions.length < 5){
      if (listOptions.length === 0) {
        // If it's empty, set newListOption as the listOptions
        setListOptions([{
            id: 0, // You can use a proper ID generator here
            optiondata: ''
        }]);
    } else {
      const newListOption = {
        id: listOptions[listOptions.length - 1].id + 1, // You can use a proper ID generator here
        optiondata: ''
      };
      setListOptions(prevListOptions => [...prevListOptions, newListOption]);
    } 
    }else{
      window.alert("Max no. of options is limited to 5")
      return
    }
  };

  const deleteAdditionalOption = (id) => {
    console.log({id:id})
    const updatedListOptions = listOptions.filter(option => option.id !==id);

    // Rearrange the id numbers of the remaining options
    let index = 0;
    const newListOptions = updatedListOptions.map(option => ({
        ...option,
        id: index++ // Rearranging id numbers starting from 0 and incrementing index
    }));
    console.log({newListOptions:newListOptions})
    // Update the state with the new listOptions
    setListOptions(newListOptions);
  }

  const editQuestion = (id) => {
    const questionToEdit = data.find(item => item._id === id)
    setTempQuestion(questionToEdit.text)
    if(questionToEdit.type == 2){
      setIsObjectiveQues(false)
    }else{
      setListOptions(questionToEdit.ans_options)
    }
    setOpenEditModal(true)
    setQuesToEdit(id)
  }

  const deleteQuestion = (id) => {
    console.log(id)
    setIsLoading(true)
    deleteAddedQuestion({
      jobid,
      quesid:id  
    })
    .then((res) => {
      setIsLoading(false)
      console.log({res:res})
      if (!res.status == 200) {
        if (res.message) {
          window.alert(res.message);
        } else {
          window.alert('Something went wrong. Please try again.');
        }
      } else {
        setData(res.allQuestions);
      }
    })
    .catch(error => {
      console.error('An error occurred:', error);
      // Handle any errors that occurred during the async operation
    });
    // load()
    setIsLoading(false)
  }

  const saveAddedQuestions = async () => {

    if(isObjectiveQues && listOptions.length == 0 ){
      window.alert("Can't create objective Question with no Options")
    }
    setIsLoading(true)
    let { success, message } = await saveAddedQuestion({
      jobid,
      question: tempQuestion,
      options: listOptions,
      isObjectiveQues: isObjectiveQues,
      
    })
    // console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
    } else {
      window.alert('Success')
      setTempQuestion('')
      setListOptions([])
      setOpenQuesCreate(false)
      window.location.reload()
    }
    // load()
    // getAllQuestions()
    setIsLoading(false)
  }

  const saveEditedQuestions = async (quesId) => {
    setIsLoading(true)
    console.log({quesToEdit:quesToEdit})
    console.log({quesId:quesId})
    let { success, message } = await saveEditedQuestion({
      jobid,
      quesid: quesToEdit,
      question: tempQuestion,
      options: listOptions,
      isObjectiveQues: isObjectiveQues,
      
    })
    // console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
    } else {
      window.alert('Success')
      setTempQuestion('')
      setListOptions([])
      setOpenQuesCreate(false)
      window.location.reload()
    }
    // load()
    // getAllQuestions()
    setIsLoading(false)
  }
  let serialNum = 1
  const minToTime = (time) => {
    const result =
      Math.floor(time / 60) +
      ':' +
      (time - Math.floor(time / 60) * 60 < 10
        ? '0' + (time - Math.floor(time / 60) * 60)
        : time - Math.floor(time / 60) * 60)

    return result
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <div className='placement-portal'>
    <>
      <div className="jobs-main">
        <TopBar2
        heading={'Create a Job'}
        />
        <div>
            <Sidebar2 active={3} jobid={jobid}/>
        </div>
        <div className='jobs-root'>
        <div className="jobs-header">
          <Row style={{ height: '30px'}}>
          <MiniHeader  breadCrumbs={[
            { name: "Career Services", link: "/career-services" },
            { name: "Job Postings", link: "/career-services" },
            ]}
            active={1}
            />
          </Row>
        </div>
        <div className="jobs-content">
            <div className="adques-all">
            <div className="adques-header row">
              <div className="jobs-table-header-22 col-md-8">
                <p style={{ marginBottom : '10px' }}>Additional Questions</p>
              </div>
              <div className='col-md-1'>

              </div>
              <div className="col-md-3" style={{display:"flex",justifyContent: "flex-end", paddingTop: "8px", paddingBottom: "8px"}}>
              {checkVisibility('43', 2) && (
                <Button className="jobs-table-btn-5"
                  onClick={() => (openQuesCreate ? setOpenQuesCreate(false) : setOpenQuesCreate(true))}
                  disabled={isJobPublished}
                >Add Questions +
                </Button>
              )}
              </div>
            </div>
          <div style={{ marginTop: '2rem' }}>
          </div>
          {data.length > 0 ? (
            <Row style={{ marginTop: '1rem',marginLeft: '-7px' }}>
            <TableContainer component={Paper} style={{ height: '600px', marginLeft: '7px' }}>
              <Table aria-label="custom pagination table" id="batch-data-table">
                <TableHead>
                  <TableRow>
                    <TableCell
                    className="btch-dt-th-2"
                      scope="col"
                    >
                      S No.
                    </TableCell>
                    <TableCell
                    className="btch-dt-th-2"
                      scope="col"
                    >
                      Question Type
                    </TableCell>
                    <TableCell
                      className="btch-dt-th-2"
                      scope="col"
                      style={{ cursor: 'pointer' }}
                    >
                      Question
                      <span
                        style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                      >
                      </span>
                    </TableCell>
                    <TableCell
                      className="btch-dt-th-2"
                      scope="col"
                      style={{ cursor: 'pointer' ,width: '24px', height: '24px'}}
                    >
                      Options (if MCQ)
                      <span
                        style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                      >
                      </span>
                    </TableCell>
                    {checkVisibility('43', 2) && (
                    <TableCell 
                      className="btch-dt-th-2"
                      scope="col">                      
                    </TableCell>
                  ) }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.map((dt, key) => {
                      return (
                        <TableRow>
                          <TableCell
                            className="btch-dt-chk-2 "
                            scope="row"
                          >{serialNum++}</TableCell>
                          <TableCell
                            className="btch-dt-td-3 "
                            scope="row"
                          >{dt.type == 1 ? 'MCQ' : 'Subjective'}</TableCell>
                          <TableCell
                            className="btch-dt-td-2 "
                            scope="row"
                          >{dt.text}</TableCell>
                          <TableCell className="btch-dt-td" scope="row">
                          {dt.type !== 1 ? (
                            '-'
                          ) : (
                            dt.ans_options.map((option, index) => (
                              <div key={option._id}>
                                {index + 1 + '. ' + option.optiondata}
                                <br></br>
                              </div>
                            ))
                          )}
                          </TableCell>
                          {checkVisibility('43', 2) && (
                          <TableCell className="btch-dt-td-2" scope="row">
                            {
                              <div className='row'>
                                <div className="col-md-1">
                                {!isJobPublished && (
                                  <div className= 'darken-on-hover' style={{width:"32px", height:"32px", borderRadius: "4px"}} onClick={() => editQuestion(dt._id)}>
                                    <img src='https://webcdn.imarticus.org/Demo/Group5555.svg' style={{objectFit:"contain"}} />    
                                  </div>  
                                )}
                                </div>
                                <div className="col-md-6">
                                {!isJobPublished && (
                                  <div className= 'darken-on-hover' style={{width:"32px", height:"32px", background:"red", borderRadius: "4px"}} onClick={() => deleteQuestion(dt._id)}>
                                    <img src='https://webcdn.imarticus.org/fsd-pro/Group938.svg' style={{objectFit:"contain"}} />    
                                  </div>  
                                )}
                                </div>
                              </div>}
                          </TableCell>
                          )}
                        </TableRow>
                      )
                    })}

                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginRight: '256px' }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Row>
          ) : (
          <div>
          <div className="job-not-found-2">
              <img src='https://webcdn.imarticus.org/demo/Group4606.svg'></img>
              <div style={{ marginBottom: '1rem' }}>
                <br></br>
                <h5>It's empty here!</h5>
              </div>
              <div>
                <p >First add questions by clicking on</p>
                <p style={{ marginLeft: '30px' }}>"Add Questions" button</p>
              </div>
            </div>
            </div>
          )}
          <div className='addcrit-footer'>
            <Row className="adques-footer">
            <Col md={2} className="jobs-table-header-2" style={{marginLeft: "0px"}}>
            <Button
                className="jobs-table-btn-5"
                onClick={ () => {
                  history.push(`/career-module/add-job-criteria/${jobid}`)
                }}
            >
            <img
          src="https://webcdn.imarticus.org/Placement/small-left.svg"
          height={12}
          width={10}
          alt="location"
        />&nbsp;&nbsp; Previous
            </Button>
            </Col>
            <Col md={5}></Col>
            <Col md={3}>
              {/* <Button
                className="labs-table-btn-2"
                onClick={() => saveAddedQuestions()
                }
              >
                Save Draft 
              </Button> */}
              </Col>
            <Col md={2}>
            <Button
                className="jobs-table-btn-5"
                onClick={() => {
                  history.push(`/career-module/preview-job-description/${jobid}`)
                }}
            >
                Next &nbsp;&nbsp;
                <img
                src="https://webcdn.imarticus.org/Placement/small-right.svg"
                height={12}
                width={10}
                alt="location"
              />&nbsp;&nbsp;
            </Button>
            </Col>
            </Row>
            
          </div>
          </div>
        </div>
        </div>
        
      </div>

      <Modal className="jobs-addquesmodal" isOpen={openQuesCreate}>
                    <ModalHeader>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        Add Question{' '}
                      </div>
                    </ModalHeader>
                    <ModalBody
                      className="thumbModal"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <li className="row">
                        <div className="col-md-12">
                          <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                            Question Type
                          </h5>
                          <FormGroup>
                          <button
                                className={`job-tog-btn1  ${
                                  isObjectiveQues ? 'subscribed' : 'unsubscribed'
                                }`}
                                onClick={handleAddQuesStatusChange}
                            >
                                <span
                                className={` ${
                                  isObjectiveQues ? 'job-toggle' : 'job-toggle-unchecked'
                                }`}
                                >
                                Objective
                                </span>
                                <span
                                className={` ${
                                  isObjectiveQues ? 'job-toggle-unchecked' : 'job-toggle'
                                }`}
                                >
                                Subjective
                                </span>
                            </button>
                          </FormGroup>
                        </div>
                      </li>

                      <li className='row'>
                        <div className="col-md-12">
                          <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                            Question
                            <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                          </h5>
                          <FormGroup>
                            <Input
                              type="text"
                              name="text"
                              id="tempQuestion"
                              value={tempQuestion}
                              onChange={(event, oppID) => {
                                setTempQuestion(event.target.value)
                              }}
                              style={{ width: '98%' }}
                            />
                          </FormGroup>
                        </div>

                      </li>

                      {isObjectiveQues ? (
                        <div>
                          <li className="col-md-10 row">
                          <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                            Options
                            <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                          </h5>
                          </li>
                          {
                          listOptions.map(item => (
                          <li id={item.id} className="row">
                        <div className="col-md-10">
                          <FormGroup>
                            <Input
                              type="text"
                              name="text"
                              id={item.id}
                              value={item.optiondata}
                              onChange={(event) => {
                                editOptionData(item.id, event.target.value)
                              }}
                              style={{ width: '98%' }}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-2">
                          <div className= 'darken-on-hover' style={{width:"32px", height:"32px", background:"red"}} onClick={() => deleteAdditionalOption(item.id)}>
                            <img src='https://webcdn.imarticus.org/fsd-pro/Group938.svg' style={{objectFit:"contain"}} />    
                          </div>  
                        </div>
                        
                      </li>
                          ))
                    }
                      <li className='row'>
                        <div className='col-md-12'>
                          <button
                                className='addques-newoption row'
                                onClick={addAdditionalOption}
                            >
                                <span
                                className=''
                                >
                                +
                                </span>
                                <span
                                className=''
                                >
                                Add New Option
                                </span>
                          </button>
                        </div>

                      </li>
                        </div>
                       ) : ('') }



                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="success"
                        onClick={() => saveAddedQuestions()
                        }
                      >
                        Add
                      </Button>
                      <Button
                        color="danger"
                        onClick={() => handleModalClose()
                        }
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
      </Modal>

      <Modal className="jobs-editquesmodal" isOpen={openEditModal}>
        <ModalHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Edit Question{' '}
          </div>
        </ModalHeader>
        <ModalBody
          className="thumbModal"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <li className="row">
            <div className="col-md-12">
              <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                Question Type
              </h5>
              <FormGroup>
              <button
                    className={`job-tog-btn1  ${
                      isObjectiveQues ? 'subscribed' : 'unsubscribed'
                    }`}
                    onClick={handleEditQuesStatusChange}
                >
                    <span
                    className={` ${
                      isObjectiveQues ? 'job-toggle' : 'job-toggle-unchecked'
                    }`}
                    >
                    Objective
                    </span>
                    <span
                    className={` ${
                      isObjectiveQues ? 'job-toggle-unchecked' : 'job-toggle'
                    }`}
                    >
                    Subjective
                    </span>
                </button>
              </FormGroup>
            </div>
          </li>

          <li className='row'>
            <div className="col-md-12">
              <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                Question
                <span style={{ color: 'red', fontSize: '18px' }}>*</span>
              </h5>
              <FormGroup>
                <Input
                  type="text"
                  name="text"
                  id="tempQuestion"
                  value={tempQuestion}
                  onChange={(event, oppID) => {
                    setTempQuestion(event.target.value)
                  }}
                  style={{ width: '98%' }}
                />
              </FormGroup>
            </div>

          </li>

          {isObjectiveQues ? (
            <div>
              <li className="col-md-10 row">
              <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                Options
                <span style={{ color: 'red', fontSize: '18px' }}>*</span>
              </h5>
              </li>
              {
              listOptions.map(item => (
              <li id={item.id} className="row">
            <div className="col-md-10">
              <FormGroup>
                <Input
                  type="text"
                  name="text"
                  id={item.id}
                  value={item.optiondata}
                  onChange={(event) => {
                    editOptionData(item.id, event.target.value)
                  }}
                  style={{ width: '98%' }}
                />
              </FormGroup>
            </div>
            <div className="col-md-2">
              <div className= 'darken-on-hover' style={{width:"32px", height:"32px", background:"red"}} onClick={() => deleteAdditionalOption(item.id)}>
                <img src='https://webcdn.imarticus.org/fsd-pro/Group938.svg' style={{objectFit:"contain"}} />    
              </div>  
            </div>
            
          </li>
              ))
        }
          <li className='row'>
            <div className='col-md-12'>
              <button
                    className='addques-newoption row'
                    onClick={addAdditionalOption}
                >
                    <span
                    className=''
                    >
                    +
                    </span>
                    <span
                    className=''
                    >
                    Add New Option
                    </span>
              </button>
            </div>

          </li>
            </div>
            ) : ('') }



        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() => saveEditedQuestions(quesToEdit)
            }
          >
            Edit
          </Button>
          <Button
            color="danger"
            onClick={() => handleEditModalClose()
            }
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
    </div>
  )

}



const mapStateToProps = (state) => {
  return {
    permissions: state.panelPermissionReducer.panelPermissions,
    resources: state.panelPermissionReducer.panelResources,
  }
}

export default connect(mapStateToProps)(AddAdditionalQuestions)