import React, { useEffect, useState ,Fragment} from 'react'
import PlacementHeader from './Components/TopBar'
import Select from "react-select"
import Sidebar from './Components/Sidebar'
import './PlacementPortal.scss'
import '../../Components/style/assignmentDeadline.css'
import { saveAs } from 'file-saver';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Autocomplete,
  TextField,
  ToggleButton, ToggleButtonGroup
} from '@mui/material'
import GradebookModal from '../../Components/GradebookModal/GradebookModal.jsx'
import TooltipItem from '../ManageGradebookAndCertification/PreviewGradebook/TooltipItem.js'
import PDFReader from "../../Components/PDFReader/PDFReader";
import {
    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    Input,
    ModalBody,
    Label,
    FormGroup,
  } from 'reactstrap'
  import { Modal as ModalSem } from 'semantic-ui-react'
import { COUNTRY_CODES,PLACEMENT_ELIGIBILITY,PLACEMENT_STATUS,PORTAL_STATUS } from "../../constants/constant";

import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'
import { LOCATION_CHANGE } from '../../actions/actiontypes.js'
import {
  fetchPlacementCourse,
  bulkExtendAccess,
  revokeSingleLearnerAccess,
  sendProfileCompletionReminderToStudents
} from '../../actions/jobAction.js'
import zIndex from '@mui/material/styles/zIndex.js'

const configs = require('../../config/configs.js')

const ManageLearners = () => {
  const [requests, setRequests] = useState([])
  const [selectedlocationDropdown, setSelectedLocationDropdown] = useState('')
  const [locationDropdown, setLocationDropdown] = useState([])
  const [totalLearners,setTotalLearners] = useState(0)
  const [locations, setLocations] = useState([])
  const [courses, setCourses] = useState([])
  const [batches, setBatches] = useState([])
  const [selectedCourse, setSelectedCourse] = useState({})
  const [selectedBatch, setSelectedBatch] = useState({})
  const [togglePDFDoc, setTogglePDFDoc] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false)
  const [totalCheckboxes, setTotalCheckboxes] = useState(0)
  const [searchNameKey, setSearchNameKey] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchNameKey);
  const [locationName, setLocationName] = useState('')
  const [locationId, setLocationId] = useState('') 
  const [refresh, setRefresh] = useState(false)
  const [hoverImage, setHoverImage] = useState(false)
  const [openGrantAccess, setOpenGrantAccess] = useState(false)
  const [openResumeView, setOpenResumeView] = useState(false)
  const [selectedLearner, setSelectedLearner] = useState({})
  const [selectedLearnerResume, setSelectedLearnerResume] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [reviewStatus, setReviewStatus] = useState('ALL')  
  const [subToUnsubArray, setSubToUnsubArray] = useState([])
  const [openSingleGrantAccess, setOpenSingleGrantAccess] = useState(false)
  const [openSingleRevokeAccess, setOpenSingleRevokeAccess] = useState(false)
  const [singleLearnerAccess,setSingleLearnerAccess] = useState('')
  const [singleLearnerRevoke,setSingleLearnerRevoke] = useState('')
  const [singleLearnerExtend,setSingleLearnerExtend] = useState('')
  const [openSendReminder, setOpenSendReminder] = useState(false)
  const [selectedReminder, setSelectedReminder] = useState({})
  const [gradebookData,setGradebookData] = useState({})
  const [reminders,setReminders] = useState([{_id:1, name:'Profile Completion'}, { _id:2,name:'Resume Approval'}, { _id:3,name:'Both Profile & Resume Approval'}])
  const [reviewStatuses, setReviewStatuses] = useState(['ALL','UNDER_REVIEW',
    'APPROVED',
    'REJECTED',
    'FLAGGED',])
  const [checkedCheckboxes, setCheckedCheckboxes] = useState(0)  
  const [isTrack, setIsTrack] = useState(false)   
  const [data, setData] = useState([])
  const [searchOptions,setSearchOptions] = useState([{_id :'',
  name:'All'} ,{_id:1, name:'Assigned'}, { _id:0,name:'Not Assigned'}, { _id:2,name:'Unassigned'}])
  const [accessStatus,setAccessStatus] = useState({_id :'',
    name:'All'})
  const [selectedEligibilityStatus,setSelectedEligibilityStatus] = useState({_id :'',
    name:'All'})
  const [selectedPlcStatus,setSelectedPlcStatus] = useState({_id :'',
    name:'All'})
  const [placementStatus,setPlacementStatus] = useState([{_id :'',
    name:'All'} ,{_id:0, name:'Not Placed'}, { _id:1,name:'Placed'}, { _id:2,name:'Self Placed'}])
  const [eligibilityStatus,setEligibilityStatus] = useState([{_id :'',
      name:'All'},{_id:0, name:'Ineligible'},  ,{_id:1, name:'Eligible'}, { _id:2,name:'Exception'}, { _id:3,name:'Placed'}])  
  const [formdata, setFormdata] = useState({
    valid_till: '',
    timezone: 'Asia/Kolkata',
  })
  const [resumeURL, setResumeURL] = useState("");
  const [openGradebookModal, setOpenGradebookModal] = useState(false)
  const [grddata, setGrdData] = useState({})
  const [enableCategory, setEnableCategory] = useState({})
  const [elecNameMap, setElecNameMap] = useState({})
  const [adata, setAdata] = useState({})
  const [grade, setGrade] = useState({})
  const [grades, setGrades] = useState()
  const [assignmentData, setAssignmentData] = useState()
  const [quizData, setQuizData] = useState([])
  const [loading, setLoading] = useState(false)
  const [gradebookLoaded, setGradebookLoaded] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [studentGradebook, setStudentGradebook] = useState([])
  const [collapseRow, setCollapseRow] = useState({})
  const [grdTotalPerc, setGrdTotalPerc] = useState({});
  const [allAssessmentPublished, setAllAssessmentPublished] = useState(false);

  const [grdPlsStatus, setGrdPlsStatus] = useState({})
  const [showWeightage, setShowWeightage] = useState(false)
  const [showPassMarks, setShowPassMarks] = useState(false)
  const [showPlcStatus, setShowPlcStatus] = useState(false)
  const [placementEnable, setPlacementEnable] = useState(false)
  const [columnSpan, setColumnSpan] = useState(false)
  const [gradebookType, setGradebookType] = useState(1)
  const [isElec, setIsElec] = useState(false)
  const [gradebookSkillTags, setGradebookSkillTags] = useState({})
  const [skillNameMap, setskillNameMap] = useState({})
  const [skillTagPresent, setSkillTagPresent] = useState(false)

  const [showPlcPassStatus, setShowPlcPassStatus] = useState(
    'Congratulations! You are eligible for Placement Criteria'
  )
  const [showPlcFailStatus, setShowPlcFailStatus] = useState(
    'You are not eligible for the Placement.  '
  )
  const [plcPassStatusText, setPlcPassStatusText] = useState('PASS')
  const [plcFailStatusText, setPlcFailStatusText] = useState('FAIL')
  const [showContentStatus, setShowContentStatus] = useState(false)
  const [totalWeightAssignedP, setTotalWeightAssignedP] = useState();

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    fetchPlacementCourse().then((res) => {
      if(res && res.data){
        setIsLoading(false)
        let tmp_data =
              res.data.courses && res.data.courses
                ? res.data.courses
                : []
            let result = []
            for (let d = 0; d < tmp_data.length; d++) {
              if (tmp_data[d].name) {
                result.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                })
              }
            }
            setCourses(tmp_data)
      }
    })
    const getAllLocations = () => {
      return new Promise((resolve, reject) => {
        var getData = {
          url: `${
            configs.routes.BASE_URL_DEVELOPMENT
          }/cfpanelGeneric/get_all_course_locations?aid=${cookies.get(
            'aid'
          )}&pid=${cookies.get('pid')}`,
          method: 'GET',
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
        Axios(getData)
          .then((res) => {
            let tmp_data =
              res.data.data && res.data.data.allLocations
                ? res.data.data.allLocations
                : []
            let result = []
            let resultDropdown = []
            for (let d = 0; d < tmp_data.length; d++) {
              if (tmp_data[d].name) {
                result.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                })
                resultDropdown.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                })
              }
            }
            
            setLocationDropdown(resultDropdown)
            result.unshift({ name: 'All', _id: null })
            setLocations(result)
            setIsLoading(false)
            resolve(result)
          })
          .catch((err) => {
            setIsLoading(false)
            console.error(err)
            reject(err)
          })
      })
    }
    getAllLocations()
    

  }, [refresh])

  useEffect(() => {
    const checkedCheckboxes = document.querySelectorAll(
      '.btch-dt-inp:checked'
    ).length
    setCheckedCheckboxes(checkedCheckboxes)
  }, [checkedCheckboxes])

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    fetchPlacementCourse().then((res) => {
      if(res && res.data){
        // if ('courses' in res.data) {
        //   setCourses(res.data.courses)
        // }

        let tmp_data =
              res.data.courses && res.data.courses
                ? res.data.courses
                : []
            let result = []
            for (let d = 0; d < tmp_data.length; d++) {
              if (tmp_data[d].name) {
                result.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                })
              }
            }
            console.log(res.data.courses)
            console.log({coursessssss: tmp_data})
            setCourses(tmp_data)
      }
      setLoading(false)
    })
    const getAllLocations = () => {
      return new Promise((resolve, reject) => {
        var getData = {
          url: `${
            configs.routes.BASE_URL_DEVELOPMENT
          }/cfpanelGeneric/get_all_course_locations?aid=${cookies.get(
            'aid'
          )}&pid=${cookies.get('pid')}`,
          method: 'GET',
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
        Axios(getData)
          .then((res) => {
            let tmp_data =
              res.data.data && res.data.data.allLocations
                ? res.data.data.allLocations
                : []
            let result = []
            let resultDropdown = []
            for (let d = 0; d < tmp_data.length; d++) {
              if (tmp_data[d].name) {
                result.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                })
                resultDropdown.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                })
              }
            }
            
            setLocationDropdown(resultDropdown)
            result.unshift({ name: 'All', _id: null })
            setLocations(result)
            // setIsLoading(false)
            resolve(result)
          })
          .catch((err) => {
            setIsLoading(false)
            console.error(err)
            reject(err)
          })
      })
    }
    getAllLocations()
    

  }, [refresh])

  const sendProfileCompletionReminder = async ( 
  ) => {
    setIsLoading(true)
    let resp = await sendProfileCompletionReminderToStudents(
      selectedCourse._id,
      selectedBatch._id, 
      selectedReminder._id
    )
    if (resp.success) {
      window.alert('Successfully sent reminders')
      setIsLoading(false)
      setRefresh(!refresh)
    } else {
      window.alert(resp.message)
      setIsLoading(false)
    }
  }


  const previewResumeClick = (url) => {
    setResumeURL(url);
    setTogglePDFDoc(true);
  };

  const handleBulkDownload = (aid) => {
    subToUnsubArray.forEach((aid) => {
      console.log({aid})
      console.log(subToUnsubArray)
      const learner = data.find((learnerData) => learnerData.aid === aid);
      console.log({learner})
      if (learner && learner.learnerResume) {
        console.log("here")
        learner.learnerResume.forEach((resume) => {
          if (resume.resume_type === 2) {
            fetch(resume.resume_url)
              .then((response) => response.blob())
              .then((blob) => {
                saveAs(blob, `${learner.aid}_${resume._id}.pdf`);
              })
              .catch((error) => console.error('Error downloading resume:', error));
          }
        });
      }
    });
  };

  const exportData = () => {
    console.log("called export data")
    if (!selectedBatch || !selectedBatch._id) {
      alert('Selecting a Batch is mandatory to export Gradebook');
      return; 
    }

    // if(subToUnsubArray.length < 1){
    //   window.alert("Please select learners first")
    // }

    const cookies = new Cookies()
    let isGrade = 1 
    // let crPage = props.currentPage
    // let tbSize = props.tableSize
    let url = `${
      configs.routes.BASE_URL_DEVELOPMENT
    }/cfpanelPlacement/exp_to_xlsx`

    var btch_data = {
      url: url,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id: selectedCourse._id,
        crs_id: selectedBatch._id,
        subUsersArray:[],
        isGrade: 1,
      },
    }
    Axios(btch_data)
      .then((res) => {
        downloadFile(res)
      })
      .catch((err) => {
        alert('Some error occured while downloading the file!')
        console.error(err)
      })
  }

  const exportLearnerResume = () => {
    if(subToUnsubArray.length < 1){
      window.alert("Please select learners first")
    }
    const cookies = new Cookies()
    let isGrade = 1 
    // let crPage = props.currentPage
    // let tbSize = props.tableSize
    let url = `${
      configs.routes.BASE_URL_DEVELOPMENT
    }/cfpanelPlacement/exp_managelearner_resume`

    var btch_data = {
      url: url,
      method: 'POST',
      responseType: 'blob',
      headers: {
        'x-access-token': cookies.get('at'),
      },
      data: {
        cid: selectedBatch._id,
        subUsersArray:[],
      },
    }
    Axios(btch_data)
      .then((res) => {
        downloadFile(res)
        window.alert('File Downloaded successfully')
      })
      .catch((err) => {
        alert('Some error occured while downloading the file!')
        console.error(err)
      })
  }

  const downloadFile = (res) => {
    const contentDisposition = res.headers['content-disposition']
    let fileName = contentDisposition.split(';')[1].split('=')[1]

    console.log('File name : ', fileName)
    fileName = fileName.replaceAll('"', '')
    console.log('File name : ', fileName)

    const url = window.URL.createObjectURL(new Blob([res.data]))
    const link = document.createElement('a')
    link.href = url

    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  }

  const singleStudentSelect = async (aid) => {
    console.log(subToUnsubArray)
    const checkboxes = document.querySelectorAll('.btch-dt-inp')
    checkboxes.forEach((checkbox) => {
      if (checkbox.id == aid) {
        if (checkbox.checked) {
          if (subToUnsubArray.indexOf(aid) === -1) {
            setSubToUnsubArray((prev) => {
              prev.push(aid)
              return [...prev]
            })
          }
        } else {
          setSubToUnsubArray((prev) => prev.filter((item) => item !== aid))
        }
        console.log("in here")
        console.log(subToUnsubArray)
      }
    })
  }

  const bulkExtendAccessLearner = async (
    subToUnsubArray,
    formdata
  ) => {
    setIsLoading(true)
    console.log(formdata)
    let resp = await bulkExtendAccess(
      subToUnsubArray,
      selectedCourse._id,
      selectedBatch._id,
      formdata
    )
    if (resp.success) {
      window.alert('Placement Access updated successfully for selected users')
      // props.batchChanged(batchName, cid)
      handleModalClose()
      setIsLoading(false)
      setRefresh(!refresh)
    } else {
      window.alert(resp.message)
      // props.batchChanged(batchName, cid)
      setIsLoading(false)
    }
  }

  const assignSingleAccess = (aid) => {
    setSingleLearnerAccess(aid)
    setOpenSingleGrantAccess(true)
  }

  const revokeSingleAccess = (aid) => {
    // setSelectedLearner(aid)
    setOpenSingleRevokeAccess(true)
    setSingleLearnerRevoke(aid)
    
  }

  const getAllBatches = (crs_pg_id, location_id) => {
    setIsLoading(true)
    const cookies = new Cookies()
    return new Promise((resolve, reject) => {
      let data = {
        crs_pg_id: crs_pg_id,
        a_id: cookies.get('aid'),
      }
      if (location_id) {
        data['location_id'] = location_id
      }
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT}/cfpanelCourse/ls_btch`,
        method: 'POST',
        data: data,
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      Axios(getData)
        .then((res) => {
          let tmp_data =
            res.data.data && res.data.data.courses ? res.data.data.courses : []
          let result = []
          for (let d = 0; d < tmp_data.length; d++) {
            if (tmp_data[d].btch_name) {
              result.push({
                name: tmp_data[d].btch_name,
                _id: tmp_data[d]._id,
              })
            }
          }
          if (!result.length) {
            alert('No batches found at this location')
          }
          // result.unshift({ name: '', _id: null })
          setIsLoading(false)
          setBatches(result)
          resolve(result)
        })
        .catch((err) => {
          console.log('inside get data')
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  const handleResumeModalOpen = (aid) => {

    const selectedResume = data.find((item) => item.aid === aid);

    if (selectedResume) {
      setSelectedLearner(selectedResume)
      setSelectedLearnerResume(selectedResume.learnerResume);
      console.log('Opening Resume Modal');
      console.log('Selected Resume:', selectedResume.learnerResume);
    }
    setOpenResumeView(true)
  };

  const getLearnerData = (crs_pg_id, cid, searchKey,accessStatus,plcStatus,eliType,page,rowsPerPage) => {
    setIsLoading(true)

    const cookies = new Cookies()
    return new Promise((resolve, reject) => {
      let data = {
        crs_pg_id: crs_pg_id,
        cid: cid,
        searchKey: searchKey,
        page:page,
        rowsPerPage:rowsPerPage,
        accessStatus: accessStatus,
        // plcStatus: plcStatus,
        // eliType: eliType,
        a_id: cookies.get('aid'),
      }
      // if (location_id) {
      //   data['location_id'] = location_id
      // }
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT}/cfpanelPlacement/get_learnermanagement_data`,
        method: 'POST',
        data: data,
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      Axios(getData)
        .then((res) => {
          let result = []
          let tmp_data =
            res.data.data && res.data.data.learnerData ? res.data.data.learnerData : []
          // result.unshift({ name: '', _id: null })
          if(res.data.data && res.data.data.totalLearners){
            console.log({totStud:res.data.data.totalLearners})
            setTotalLearners(res.data.data.totalLearners)
            setTotalCount(res.data.data.totalLearners)
          }
          const accessStatusDefined =  accessStatus !== '' ? true : false;
          const plcStatusDefined = plcStatus && plcStatus !== '';
          const eliTypeDefined = eliType && eliType !== '';

          const filteredData = (res.data.data && res.data.data.learnerData ? res.data.data.learnerData : []).filter(singleLearnerData => {
              return (!accessStatusDefined || (accessStatusDefined && (accessStatus === 1 && singleLearnerData.portal_status === accessStatus) ||
              (accessStatus === 0 && singleLearnerData.portal_status === accessStatus) ||
              (accessStatus === 3 && singleLearnerData.etim && singleLearnerData.etim > Date.now()))) &&
                    (!plcStatusDefined || (plcStatusDefined && singleLearnerData.placement_status === plcStatus)) &&
                    (!eliTypeDefined || (eliTypeDefined && singleLearnerData.eligibility_type === eliType));
          });

          setData(filteredData);
          // setData(tmp_data)
          resolve(result)
          setIsLoading(false)
        })
        .catch((err) => {
          console.log('inside get data')
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  const allStudentSelect = async () => {
    // setTotalCheckboxes(0)
    const checkboxes = document.querySelectorAll('.btch-dt-inp')
    if (checkboxes.length < 1) {
      window.alert('No subscribed student found.Please select a student')
      return
    }

    if (isAllSelected) {
      setIsAnyCheckboxChecked(false)
      setIsAllSelected(false)
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false
        // setCheckedCheckboxes(0)
        // setTotalCheckboxes(0)
      })
      setSubToUnsubArray([])
    } else {
      setIsAnyCheckboxChecked(true)
      setIsAllSelected(true)
      // setTotalCheckboxes(props.totalStudents - props.totalUnsubStudents)
      // setCheckedCheckboxes(props.totalStudents - props.totalUnsubStudents)
      checkboxes.forEach((checkbox) => {
        if (!checkbox.checked) {
          checkbox.checked = true
        }
      })
      setSubToUnsubArray(['ALL'])
    }

    // console.log("Sub to Unsub array is ", subToUnsubArray)
  }

  let serialNum = page*rowsPerPage+1
  useEffect(() => {
    const cookies = new Cookies()
       
    if(selectedCourse && Object.keys(selectedCourse).length > 0){
      getAllBatches(selectedCourse._id,locationId)
    }
    
  }, [selectedCourse,locationId,refresh])

  useEffect(() => {
    if (!placementEnable) {
      if (showContentStatus) {
        if (showPassMarks && showWeightage) {
          setColumnSpan(6);
        } else if (showPassMarks && !showWeightage) {
          setColumnSpan(4);
        } else if (!showPassMarks && showWeightage) {
          setColumnSpan(5);
        } else {
          setColumnSpan(3);
        }
      } else {
        if (showPassMarks && showWeightage) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage) {
          setColumnSpan(4);
        } else {
          setColumnSpan(2);
        }
      }
    } else {
      if (showContentStatus) {
        if (showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else if (showPassMarks && !showWeightage && !showPlcStatus) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (!showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(4);
        } else if (!showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else {
          setColumnSpan(3);
        }
      } else {
        if (showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else if (showPassMarks && !showWeightage && !showPlcStatus) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (!showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(4);
        } else if (!showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else {
          setColumnSpan(2);
        }
      }
    }
  }, [placementEnable, showWeightage, showPassMarks, showPlcStatus])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchNameKey);
    }, 500); // Adjust delay as needed (e.g., 300ms)

    // Cleanup the timeout if searchNameKey changes
    return () => {
      clearTimeout(handler);
    };
  }, [searchNameKey]);

  // useEffect(() => {
  //   if (debouncedSearchTerm && selectedBatch && Object.keys(selectedBatch).length > 0) {
  //     getLearnerData(selectedCourse._id,selectedBatch._id ,accessStatus._id,selectedPlcStatus._id,selectedEligibilityStatus._id,page,rowsPerPage)
  //     console.log("Searching for:", debouncedSearchTerm);
  //   }
  // }, [debouncedSearchTerm]);

  useEffect(() => {
    const cookies = new Cookies()   
    if(selectedBatch && Object.keys(selectedBatch).length > 0){
      console.log({accessStatus})
      getLearnerData(selectedCourse._id,selectedBatch._id ,debouncedSearchTerm,accessStatus._id,selectedPlcStatus._id,selectedEligibilityStatus._id,page,rowsPerPage)
    }
  }, [selectedBatch,refresh,page,rowsPerPage,accessStatus._id,debouncedSearchTerm,selectedPlcStatus._id,selectedEligibilityStatus._id,,page,rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleModalClose = () => {
    setOpenGrantAccess(false)
    setOpenSingleGrantAccess(false)
    setOpenSingleRevokeAccess(false)
    setOpenSendReminder(false)
    setFormdata(prevFormdata => ({
      ...prevFormdata,
      valid_till: ''
    }));
  }

  const revokeLearnerAccess = async (
    subToUnsubArray,
    formdata
  ) => {
    setIsLoading(true)
    console.log(formdata)

    let resp = await revokeSingleLearnerAccess(
      subToUnsubArray,
      selectedCourse._id,
      formdata
    )
    if (resp.success) {
      window.alert('Placement Access updated successfully for selected users')
      // props.batchChanged(batchName, cid)
      setIsLoading(false)
      setRefresh(!refresh)
    } else {
      window.alert(resp.message)
      // props.batchChanged(batchName, cid)
      setIsLoading(false)
    }
  }

  const handleResumeModalClose = () => {
    setOpenResumeView(false)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  const updateFormdata = (e) => {
    console.log('updateFormadata is running')
    const newData = {
      ...formdata,
      [e.target.name]: e.target.value,
    }
    setFormdata(newData)
    // setMaxValidTill(new Date(props.finalCourseAccessDate))
  }

  const placementAccessChanged = (status) => {
    setAccessStatus(status)
  }

  const placementStatusChanged = (status) => {
    setSelectedPlcStatus(status)
  }

  const eligibilityStatusChanged = (status) => {
    setSelectedEligibilityStatus(status)
  }

  const locationChanged = (locationName, locationId) => {
    setLocationName(locationName)
    setLocationId(locationId)
    setSelectedBatch('')
    setData([])
  }

  const courseChanged = (course) => {
    if (String(selectedCourse?._id) !== String(course._id)) {
    setSelectedCourse(course)
    setSelectedBatch('')
    setBatches([])
    setLocationName('')
    setData([])

    console.log({batches})
    }
  }
  const dropdownlocationChanged = (locationName, locationId) => {
    setSelectedLocationDropdown(locationName)
    // setLocationId(locationId)
  }
  const batchChanged = (batch) => {
    setData([])
    setSelectedBatch(batch)
  }
  const reviewStatusChanged = (reviewStatus) => {
    setReviewStatus(reviewStatus)
  }

  const handleStatusChange = () => {

    setIsTrack(!isTrack)
    setAccessStatus({_id :'',
      name:'All'})
    setSelectedEligibilityStatus({_id :'',
      name:'All'})
    setSelectedPlcStatus({_id :'',
      name:'All'})
  }

  const handleTotalGradeClick = (cid,aid) => {
    // getLearnerGradeBookData(cid,aid)
    const tmp_data = data.find(item => item.aid === aid);

    console.log({tmp_data})
    if(tmp_data){
      console.log("reached here")
      setGrdData(tmp_data.gradeData.grddata)
      setEnableCategory(tmp_data.gradeData.enableCategory)
      setElecNameMap(tmp_data.gradeData.elecNameMap)
      setGrade(tmp_data.gradeData.tgrade)
      setAdata(tmp_data.gradeData.adata)

      if (tmp_data.courseData && tmp_data.gradeData.grddata) {
        console.log("reached here as well")
        setShowContentStatus(tmp_data.courseData.show_content_status)
        setIsElec(tmp_data.courseData.elec_level_index)
        setShowPassMarks(tmp_data.courseData.show_pass_marks)
        setShowWeightage(tmp_data.courseData.show_weightage)
        setGrdPlsStatus(tmp_data.gradeData.grddata.plcStaus)
        let pls_status = tmp_data.courseData.plac_type ? tmp_data.courseData.plac_type.gradebook_show_plcmnt_stts:false
        setShowPlcStatus(pls_status)

        let passStatus = tmp_data.courseData.plac_type
          ? tmp_data.courseData.plac_type.plc_pass_text
          : "Congratulations! You are eligible for Placement Criteria";
        let failStatus = tmp_data.courseData.plac_type
          ? tmp_data.courseData.plac_type.plc_fail_text
          : "You are not eligible for the Placement.  ";
        let plcpassStatus = tmp_data.courseData.plac_type
          ? tmp_data.courseData.plac_type.plc_pass_status_text
          : "PASS";
        let plcfailStatus = tmp_data.courseData.plac_type
          ? tmp_data.courseData.plac_type.plc_fail_status_text
          : "FAIL";

        setPlcPassStatusText(plcpassStatus);
        setPlcFailStatusText(plcfailStatus);
        setShowPlcFailStatus(failStatus);
        setShowPlcPassStatus(passStatus);
        setPlacementEnable(tmp_data.courseData.plcmnt_enable)
        setGrdTotalPerc(tmp_data.gradeData.grddata.grd_total_perc);
        setStudentGradebook([...tmp_data.gradeData.grddata.placementGradebookCategory])

        setOpenGradebookModal(true)
      }
    }

  }

  const minToTime = (time) => {
    const result =
      Math.floor(time / 60) +
      ':' +
      (time - Math.floor(time / 60) * 60 < 10
        ? '0' + (time - Math.floor(time / 60) * 60)
        : time - Math.floor(time / 60) * 60)

    return result
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <div className='placement-portal' style={{overflow: "hidden"}}>
    <>
      <Sidebar active={6} />
      <div className="labs-main">
        <PlacementHeader  breadCrumbs={[
          { name: "Career Services", link: "/" },
        ]}
        active={6}
        />
        <div className="labs-content">
            <div style={{ borderBottom: '1px solid grey', paddingBottom: '16px' }}>
          <Row>
            {/* Courses */}
            <Col md={2} className="labs-table-header-2">    
            <p style={{ fontSize: '10px', margin: "0px",paddingBottom: "4px" }}>Select Course</p>
            <Autocomplete            
            disableClearable
            value={selectedCourse && selectedCourse.name}
            onChange={(event, course) => {
                courseChanged(course)
            }}
            getOptionLabel={(batch) => {
                return batch.name || batch
            }}
            getOptionSelected={(option, test) => option.name == test}
            id="batchSelector"
            options={courses}
            disabled={courses.length === 0 ? true : false}
            renderInput={(params) => {
                return (
                <TextField
                    // label="Select Course"
                    {...params}
                    variant="outlined"
                    fullWidth
                />
                )
            }}
            />
            </Col>
            <Col md={2} className="labs-table-header-2">
            <p style={{ fontSize: '10px', margin: "0px",paddingBottom: "4px" }}>Select Training Location</p>
            <Autocomplete
            disableClearable
            value={locationName}
            onChange={(event, location) => {
              locationChanged(location.name,location._id)
            }}
            getOptionLabel={(batch) => {
                return batch.name || batch
            }}
            getOptionSelected={(option, test) => option.name == test}
            id="batchSelector"
            options={locations}
            disabled={locations.length === 0 ? true : false}
            renderInput={(params) => {
                return (
                <TextField
                    // label="Select Training Location"
                    {...params}
                    variant="outlined"
                    fullWidth
                />
                )
            }}
            />
            </Col>
            <Col md={2} className="labs-table-header-2">
            <p style={{ fontSize: '10px', margin: "0px",paddingBottom: "4px" }}>Select Batch</p>
            <Autocomplete
            disableClearable
            value={selectedBatch && selectedBatch.name}
            onChange={(event, batch) => {
              batchChanged(batch)
            }}
            getOptionLabel={(batch) => {
                return batch.name || batch
            }}
            getOptionSelected={(option, test) => option.name == test}
            id="batchSelector"
            options={batches}
            disabled={batches.length === 0 && locationName != '' ? true : false}
            renderInput={(params) => {
                return (
                <TextField
                    // label="Select Batch"
                    {...params}
                    variant="outlined"
                    fullWidth
                />
                )
            }}
            />
            </Col>
          </Row>
          <Row style={{ marginTop: '2rem' }}>
            <Col md={12}>
            <div className="toggle-container">
              <button
                className={`job-dt-tog-btn1  ${isTrack ? 'unsubscribed' : 'subscribed'
                  }`}
                onClick={handleStatusChange}
              >
                <span
                  className={` ${isTrack ? 'btch-toggle-unchecked' : 'btch-toggle'
                    }`}
                >
                  Assign/Revoke Placement Access
                </span>
                <span
                  className={` ${isTrack ? 'btch-toggle' : 'btch-toggle-unchecked'
                    }`}
                >
                  Track Learners
                </span>
              </button>
            </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '1rem' }}>
          <Col md={6} className="labs-table-header"> 
            <p>All Learners</p>
          </Col>
          {isTrack && (
              <>
                <Col md={3}>
                  <button
                    onClick={() => { exportData()}}
                    className="bulk-dl-btn"
                  >
                    <img
                      src="https://webcdn.imarticus.org/Placement/material-symbols_upload3.svg"
                    />
                    Bulk Download Gradebook
                  </button>
                </Col>
                <Col md={3}>
                  <button
                    onClick={() => {
                      exportLearnerResume();
                    }}
                    className="bulk-dl-btn"
                  >
                    <img
                      src="https://webcdn.imarticus.org/Placement/material-symbols_upload3.svg"
                    />
                    Bulk Download Resume
                  </button>
                </Col>
              </>
            )}
          </Row>
          </div>
          <Row style={{marginTop: '16px', display: 'flex',justifyContent:'space-between'}}>
            <div className='col-md-6'>
            <div className="btch-search">
              <input
                placeholder="Search Learners"
                value={searchNameKey}
                onChange={(e) => setSearchNameKey(e.target.value)}
                className="form-control as-dl-search"
                ref={(input) =>
                  input && searchNameKey && input.focus()
                }
                style={{height:"40px",width: "414px" }}
              />
            </div>
            <Col md={1}>
              </Col>
            </div>
            
            {!isTrack && (
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <Col md={6} style={{ width: '100%' ,zIndex: '999'}}>
                  <div className="btch-search" style={{ marginRight: '1rem', width: '250px',paddingRight: '10px' }}>
                    <Autocomplete   
                      style={{minWidth: "150px"}}                   
                      disableClearable
                      value={accessStatus.name}
                      onChange={(event, status) => {
                        placementAccessChanged(status)
                      }}
                      getOptionLabel={(batch) => {
                          return batch.name || batch
                      }}
                      getOptionSelected={(option, test) => option.name == test}
                      id="batchSelector"
                      options={searchOptions}
                      disabled={searchOptions.length === 0 ? true : false}
                      renderInput={(params) => {
                          return (
                          <TextField
                              label="Select Placement Access Status"
                              {...params}
                              variant="outlined"
                              fullWidth
                          />
                          )
                      }}
                  />
                  </div>
                </Col>
                <Col md={6} style={{ width: '100%' }}>
                  <button
                    onClick={() => (openGrantAccess ? setOpenGrantAccess(false) : setOpenGrantAccess(true))}
                    className="labs-table-btn-1"
                    disabled={subToUnsubArray.length === 0}
                  >
                    Assign Placement Access
                  </button>
                </Col>
              </div>
            )}

            {isTrack && (
              <div className="col-md-6" style={{ display: 'flex'}}>
                {/* <Col md={12} style={{ width: '100%' ,zIndex: '999'}}> */}
                  <div className="btch-search" style={{ marginRight: '1rem', width: '250px' }}>
                  <Autocomplete 
                  style={{minWidth: "125px"}}                 
                  disableClearable
                  value={selectedPlcStatus.name}
                  onChange={(event, status) => {
                    placementStatusChanged(status)
                  }}
                  getOptionLabel={(batch) => {
                      return batch.name || batch
                  }}
                  getOptionSelected={(option, test) => option.name == test}
                  id="batchSelector"
                  options={placementStatus}
                  disabled={placementStatus.length === 0 ? true : false}
                  renderInput={(params) => {
                      return (
                      <TextField
                          label="Select Placement Status"
                          {...params}
                          variant="outlined"
                          fullWidth
                      />
                      )
                  }}
                  />
                  </div>
                {/* </Col>
                <Col md={3} style={{ width: '100%' ,zIndex: '999'}}> */}
                  <div className="btch-search" style={{ marginRight: '1rem', width: '250px' }}>
                    <Autocomplete
                      style={{minWidth: "125px"}}
                      disableClearable
                      value={selectedEligibilityStatus.name}
                      onChange={(event, eli) => {
                        eligibilityStatusChanged(eli)
                      }}
                      getOptionLabel={(batch) => {
                          return batch.name || batch
                      }}
                      getOptionSelected={(option, test) => option.name == test}
                      id="batchSelector"
                      options={eligibilityStatus}
                      disabled={eligibilityStatus.length === 0 ? true : false}
                      renderInput={(params) => {
                          return (
                          <TextField
                              label="Select Eligibility Type"
                              {...params}
                              variant="outlined"
                              fullWidth
                          />
                          )
                      }}
                      />
                    </div>
                {/* </Col>
                <Col md={4} style={{ width: '100%' }}> */}
                  <button
                     onClick={() => setOpenSendReminder(true)}
                    className="jobs-table-btn-11"
                  >
                    <img
                      src="https://webcdn.imarticus.org/Placement/ic_baseline-schedule.svg"
                      style={{marginBottom: "1px", marginRight: "2px"}}
                    />
                    Send Reminders
                  </button>
                {/* </Col> */}
              </div>
            )}


            
          </Row>
          {data.length > 0 ? (
          <Row style={{ marginTop: '1rem' }}>
            <TableContainer component={Paper} style={{ height: '600px' }}>
                <Table aria-label="custom pagination table" id="batch-data-table">
                <TableHead>
                    <TableRow>
                      <TableCell
                        className='sticky-header-both-zero btch-dt-chk '
                        scope="col">
                          <Input
                            type="checkbox"
                            className="job-dt-chk"
                            // id={dt.aid}
                            onClick={() => {
                              allStudentSelect()
                            }}
                          />

                      </TableCell>
                    <TableCell
                    className=" btch-dt-th sticky-header-both-first"
                    scope="col"
                  >
                    S No.
                    </TableCell>
                    <TableCell
                  className="btch-dt-th sticky-header-both-second"
                  scope="col"
                >
                  UID
                </TableCell>
                <TableCell
                  className="btch-dt-th sticky-header-both-third"
                  scope="col"
                  style={{ cursor: 'pointer' }}
                >
                  Name
                  <span
                    style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                  >
                  </span>
                </TableCell>
                <TableCell
                  className="btch-dt-th sticky-header"
                  scope="col"
                  style={{ cursor: 'pointer' }}
                >
                  Email Id
                  <span
                    style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                  >
                  </span>
                </TableCell>
                <TableCell
                  className="btch-dt-th sticky-header"
                  scope="col"
                  style={{ cursor: 'pointer' }}
                >
                  Mobile No.
                  <span
                    style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                  >
                  </span>
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                 Profile Completion (%)
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                Resume
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                Resume Approval Status
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                 Gradebook Grades
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                 Gradebook Tier
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                 Course Completion %
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                    Eligibility Type
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                    Access End Time
                </TableCell>
                {!isTrack && (
                  <>
                  <TableCell className="btch-dt-th sticky-header" scope="col">
                  Action
                  </TableCell>
                  </>)}
                  
                {isTrack && (
                  <> 
                    <TableCell className="btch-dt-th sticky-header" scope="col">
                    No. of Jobs Given
                    </TableCell>
                    <TableCell className="btch-dt-th sticky-header" scope="col">
                    No. of Jobs Applied
                    </TableCell>
                    <TableCell className="btch-dt-th sticky-header" scope="col">
                    No. of Jobs Missed
                    </TableCell>
                    <TableCell className="btch-dt-th sticky-header" scope="col">
                      Placement Status
                    </TableCell>
                    <TableCell className="btch-dt-th sticky-header" scope="col">
                      Action
                    </TableCell>
                  </>
                )}
                
                </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                      data.map((dt, key) => {
                        return (
                          <TableRow>
                            <TableCell className="sticky-first btch-dt-chk"
                            scope="row">
                            <Input
                              type="checkbox"
                              className="btch-dt-inp"
                              id={dt.aid}
                              onClick={() => {
                                singleStudentSelect(dt.aid)
                              }}
                            />
                            </TableCell>
                            <TableCell
                              className="btch-dt-td-app sticky-second"
                              scope="row"
                            >{serialNum++}</TableCell>
                            <TableCell
                              className="btch-dt-td-app sticky-third"
                              scope="row"
                            >{dt.uid}</TableCell>
                            <TableCell
                              className="btch-dt-td-app sticky-fourth"
                              scope="row"
                            >{dt.name}</TableCell>
                            <TableCell
                              className="btch-dt-td-app-spl"
                              scope="row"
                            >{dt.ep}</TableCell>
                            <TableCell className="btch-dt-td-app" scope="row">
                              {dt.m || "-"}
                            </TableCell>
                            <TableCell className="btch-dt-td-app" scope="row">
                              {/* {dt.profile_completion} */}
                              <a href={`/career-services/view-learner-profile/${selectedCourse._id}/${dt.aid}`}>
                                {dt.profile_completion}
                              </a>
                            </TableCell>
                            <TableCell className="btch-dt-td-app" scope="row">
                            <a href="/head" className="resume-link" onClick={(e) => {
                              e.preventDefault();
                              handleResumeModalOpen(dt.aid);
                            }}>
                              View Resume
                            </a>
                            </TableCell>
                            <TableCell className="btch-dt-td-app" scope="row">
                            {dt.learnerResume && dt.learnerResume.some(resume => resume.resume_stts === 2) ? "Yes" : "No"}
                            </TableCell>
                            <TableCell className="btch-dt-td-app" scope="row">
                            <span 
                            onClick={() => {handleTotalGradeClick(selectedBatch._id,dt.aid)}} 
                            style={{ cursor: 'pointer' }}>
                              {dt.total_grade ? dt.total_grade + '%' : '-'}
                            </span>
                            </TableCell>
                            <TableCell className="btch-dt-td-app" scope="row">
                              {dt.student_tier ? dt.student_tier : '-'}
                            </TableCell>
                            <TableCell className="btch-dt-td-app" scope="row">
                              {dt.comp_perc? dt.comp_perc : '-'}
                            </TableCell>
                            <TableCell className="btch-dt-td-app" scope="row">
                              {dt.placement_status === 0 ? PLACEMENT_ELIGIBILITY[dt.eligibility_type] : PLACEMENT_ELIGIBILITY[3]}
                            </TableCell>
                            <TableCell className="btch-dt-td-app" scope="row">
                              {dt.etim ? moment(dt.etim).format("DD-MM-YYYY HH:mm") : '-'}
                            </TableCell>
                            {!isTrack && (
                              <>
                            <TableCell className="btch-dt-td-app-4" scope="row">
                            {dt.portal_status !== 1 ? (
                              <button
                              onClick={() => assignSingleAccess(dt.aid)}
                                className="labs-table-btn-3"
                              >
                                Assign Access
                              </button>
                            ) : (
                              <div className='row'>
                                {dt.etim > Date.now() && (
                                <div className='col-md-6'>
                                  <button
                                  onClick={ () => revokeSingleAccess(dt.aid)}
                                  className="jobs-table-btn-8"
                                  >
                                    Revoke Access
                                  </button>
                                </div>
                                )}
                                <div className='col-md-6'>
                                  <button
                                    onClick={() => assignSingleAccess(dt.aid)}
                                    className="jobs-table-btn-9"
                                  >
                                    Extend Access
                                  </button> 
                                </div>
                            </div>
                            )}
                            </TableCell>
                            </> )}

                            {isTrack && (
                              <>
                                <TableCell className="btch-dt-td-app" scope="row">
                                {dt.num_jobs}
                                </TableCell>
                                <TableCell className="btch-dt-td-app" scope="row">
                                {dt.jobs_applied}
                                </TableCell>
                                <TableCell className="btch-dt-td-app" scope="row">
                                {dt.jobs_missed}
                                </TableCell>
                                <TableCell className="btch-dt-td-app" scope="row">
                                {PLACEMENT_STATUS[dt.placement_status]}
                                </TableCell>
                                <TableCell className="btch-dt-td-app" scope="row">
                                {dt.placement_status === 1 ? (
                                  <button
                                    onClick={() => window.open(dt.placement_proof, '_blank')}
                                    className="labs-table-btn-3"
                                  >
                                    Placement Proof
                                  </button>
                                )  : dt.placement_status === 2 ? (
                                  <button
                                    onClick={() => setRefresh(prevRefresh => !prevRefresh)}
                                    className="labs-table-btn-4"
                                  >
                                    View Request
                                  </button>
                                ): (
                                  // <button
                                  //   onClick={() => setRefresh(prevRefresh => !prevRefresh)}
                                  //   className="jobs-table-btn-10"
                                  // >
                                  //   Mark Self Placed
                                  // </button>
                                  'NA'
                                )}

                                </TableCell>
                              </>
                            )}

                          </TableRow>
                        )
                      })}
                  </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginRight: '256px' }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Row>
          ) : 
          <div className="job-not-found">
            <img src='https://webcdn.imarticus.org/demo/Group4606.svg'></img>
            <div style={{ marginBottom: '1rem' }}> 
            <br></br>
            <h5>It's empty here!</h5>
            </div>
            <div>
            <p style={{ marginLeft: '1rem' }}>You will view learners here when you select batch from above</p>
            {/* <p>"Create a Job" to view jobs here</p> */}
            </div>
          </div>
          }
        </div>
      </div>
      <Modal className="jobs-grantaccessmodal" isOpen={openGrantAccess} centered>
        <ModalHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          Assign Access to Selected Learners
          </div>
        </ModalHeader>
        <ModalBody
          className="thumbModal"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <li className="row">
            <div className="col-md-12">
              <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                Access End Date
                <span style={{ color: 'red', fontSize: '18px' }}>*</span>
              </h5>
              <FormGroup>
                <input
                    type="datetime-local"
                    className="labs-create-new-form-text_input"
                    name="valid_till"
                    value={formdata.valid_till}
                    required={true}
                    onChange={updateFormdata}
                    // min={currentFormattedDate}
                    // max={finalFormattedDate}
                />
              </FormGroup>
              {/* <p>opportunity id already exist</p> */}
            </div>
          </li>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => handleModalClose()}
          >
            Cancel
          </Button>
          <Button
            color="success"
            onClick={() => {
              // exportData(props.batchId, isGrade)
              bulkExtendAccessLearner(subToUnsubArray, formdata)
              setOpenGrantAccess(false)
            }}
          >
            Done
          </Button>
        </ModalFooter>
      </Modal>
      <Modal className="jobs-revokeaccessmodal" isOpen={openSingleRevokeAccess} centered>
        <ModalBody
          className="thumbModal"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <li className="row">
            <div className="col-md-12">
              <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                Are you sure you want to Revoke access of the selected Learners?
              </h5>
            </div>
          </li>
          <li className="row">
            <div className="col-md-12">
              <p className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                By unassigning the access the access to Career Services Portal will be revoked
                <span style={{ color: 'red', fontSize: '18px' }}>*</span>
              </p>
            </div>
          </li>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => handleModalClose()}
          >
            No
          </Button>
          <Button
            color="success"
            onClick={() => {
              // exportData(props.batchId, isGrade)
              let singleLearner = [singleLearnerRevoke]
              revokeLearnerAccess(singleLearner, formdata)
              setOpenSingleRevokeAccess(false)
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
      
      <Modal className="jobs-grantaccessmodal" isOpen={openSingleGrantAccess} centered>
        <ModalHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          Assign Access to Selected Learner
          </div>
        </ModalHeader>
        <ModalBody
          className="thumbModal"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <li className="row">
            <div className="col-md-12">
              <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                Access End Date
                <span style={{ color: 'red', fontSize: '18px' }}>*</span>
              </h5>
              <FormGroup>
                <input
                    type="datetime-local"
                    className="labs-create-new-form-text_input"
                    name="valid_till"
                    value={formdata.valid_till}
                    required={true}
                    onChange={updateFormdata}
                    // min={currentFormattedDate}
                    // max={finalFormattedDate}
                />
              </FormGroup>
              {/* <p>opportunity id already exist</p> */}
            </div>
          </li>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => handleModalClose()}
          >
            Cancel
          </Button>
          <Button
            color="success"
            onClick={() => {
              let singleLearner = [singleLearnerAccess]
              bulkExtendAccessLearner(singleLearner, formdata)
              setOpenSingleGrantAccess(false)
            }}
          >
            Done
          </Button>
        </ModalFooter>
      </Modal>

      <Modal className="jobs-viewresumemodal" isOpen={openResumeView} centered>
        {/* <ModalHeader> */}
       
        {/* </ModalHeader> */}
        
        <ModalBody
          className="thumbModal"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
           <li className="row">
            <div className="viewres-txt">
              <div style={{ display: 'flex', alignItems: 'center' , fontSize: '20px', fontWeight: '700', marginLeft:"32px"}}>
              {selectedLearner ? `${selectedLearner.name}'s Resume` : "Learner's Resume"}
              </div>
              {selectedLearner && selectedLearnerResume && selectedLearnerResume.length > 0 && (
              <div className='res-dl-btn ' style={{marginRight:"32px"}} onClick={ () => {handleBulkDownload()}}>
                  Download Resumes
              </div>)}
            </div>
            
            </li>
          {selectedLearner && selectedLearnerResume && selectedLearnerResume.length == 0 && (
            <div>No resume found</div>
          )}
          {selectedLearnerResume
            .filter((file) => file.resume_type === 2)
            .map((file, index) => (
              <div key={index}>
                <div className="resume-div drop-zone">
                <li className="row">
                  <div className="col-md-2" style={{display: "flex", justifyContent: "center"}}>
                  <img
                    className="resume-icon-2"
                    src="https://webcdn.imarticus.org/Placement/fluent_document-pdf-24-filled.svg"
                  />
                  </div>
                  <div className="col-md-4">
                    <h5 className="resume-heading">
                        Resume {index + 1}
                    </h5>
                  </div>
                  <div className='col-md-3'>
                    <p>{moment(file.createdAt).format("DD MMMM YYYY")}</p>
                  </div>
                  <div className="col-md-1">
                  </div>
                  <div className="col-md-2">
                  <img
                    src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                    className="as-dl-logo-job-2"
                    onClick={() => previewResumeClick(file.resume_url)}
                  />
                  </div>
                </li>
                </div>
                <br />
              </div>
            ))}

            {selectedLearnerResume
            .filter((file) => file.resume_type === 1)
            .map((file, index) => (
              <div key={index}>
                <div className="resume-div drop-zone">
                <li className="row">
                  <div className="col-md-2">
                  <img
                    className="resume-icon-2"
                    src="https://webcdn.imarticus.org/Placement/mingcute_video-fill.svg"
                  />
                  </div>
                  <div className="col-md-4">
                    <h5 className="resume-heading">
                       Video Resume {index + 1}
                    </h5>
                  </div>
                  <div className='col-md-3'>
                    <p>{moment(file.createdAt).format("DD-MM-YYYY")}</p>
                  </div>
                  <div className="col-md-1">
                  </div>
                  <div className="col-md-2">
                  <a
                    href={file.resume_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                      className="as-dl-logo-job-2"
                      alt="View Resume"
                    />
                  </a>
                  </div>
                </li>
                </div>
                <br />
              </div>
            ))}
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => handleResumeModalClose()}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal className="resume-preview-modal" isOpen={togglePDFDoc} size={"md"} style={{ minwidth: "400px" }} centered>
        <ModalHeader
          className="resume-prev"
          style={{ paddingRight: "7px", width: "100%" }}
        >
          <div className="row" style={{ justifyContent: "space-between",marginLeft: "12px",marginRight: "12px" }}>
            <div>Resume Preview </div>
            <div>
              <img
                src="https://webcdn.imarticus.org/Placement/Group926.svg"
                style={{ paddingRight: "7px" }}
                onClick={() => setTogglePDFDoc(false)}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody
          style={{
            maxHeight: "750px",
            overflowY: "scroll",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <iframe
            src={resumeURL}
            style={{ width: "100%", height: "100%", border: "none" ,minHeight: "500px"}}
            frameBorder="0"
          />
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}></ModalFooter>
      </Modal>

      <Modal className="jobs-grantaccessmodal" isOpen={openSendReminder} style={{ maxWidth: "250px" }} centered>
            <ModalHeader>
              <div style={{ display: 'flex', alignItems: 'center' }}>
              Send Reminder
              </div>
            </ModalHeader>
            <ModalBody
              className="thumbModal"
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <li className="row">
                <div className="col-md-12">
                  <h5 className="job-apply-reminder" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                    Type of Reminder
                  </h5>
                  <FormGroup>
                  <Autocomplete
                      disableClearable
                      value={selectedReminder.name}
                      onChange={(event, remind)  => {
                        setSelectedReminder(remind)
                      }}
                      getOptionLabel={(batch) => {
                        return batch.name || batch
                      }}
                      getOptionSelected={(option, test) => option.name == test}
                      id="batchSelector"
                      options={reminders}
                      placeholder="Select Round"
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                          />
                        )
                      }}
                    />
                  </FormGroup>
                </div>
                <div className='apply-reminder-txt'>
                <p >Note: The reminder will only be sent to all those learners whose either Profile is not completed or Resume is not approved or both</p>
                </div>
                
              </li>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                onClick={() => handleModalClose()}
              >
                Cancel
              </Button>
              <Button
                style={{background: "#014644"}}
                onClick={() => {
                  sendProfileCompletionReminder()
                }}
              >
                Send
              </Button>
            </ModalFooter>
      </Modal>

      <Modal
          style={{ height: 'fit-content', margin: 'auto', marginTop: 'auto' }}
          isOpen={openGradebookModal}
          centered
        >
          <ModalHeader>Gradebook</ModalHeader>
          <ModalBody
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >


          {studentGradebook &&
            enableCategory &&
            studentGradebook.map((grd, index) => {
              return (
                <div key={index}>
                  {grd.length == 0 ? (
                    <div className="rvm-v1-cont-ctnr bottomRowGradebook">
                      <div
                        className="row no-margin gradeBlock"
                        style={{
                          display: "block",
                          width: "100vw",
                          paddingTop: "8vh",
                          flex: "0 1 100vw",
                          maxWidth: "100vw",
                        }}
                      >
                        <div className="col-12" style={{ padding: 0 }}>
                          <h2
                            style={{
                              color: "#055646",
                              fontWeight: 600,
                              fontSize: "20px",
                              lineHeight: "24px",
                            }}
                          >
                            <span style={{ borderBottom: "2px solid #055646" }}>
                              {isElec
                                ? elecNameMap[grd.elec_id]
                                  ? elecNameMap[grd.elec_id]
                                  : "Core Track"
                                : ""}
                            </span>
                          </h2>

                          <span className="my-grade">
                            {!grd.elec_id && "My Grades"}
                          </span>
                        </div>
                        <hr />
                        <div style={{ textAlign: "center" }}>
                          <img
                            src="http://cdn.pegasus.imarticus.org/images/gradebook_empty.svg"
                            alt="Empty Gradebook"
                          />
                          <br />
                          <br />
                          <span>
                            There are no quizzes and assignments to show at this
                            moment.
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={`rvm-v1-cont-ctnr bottomRowGradebook`}>
                      <div
                        className="row no-margin gradeBlock gradeBlockPadding"
                        style={{ display: "block" }}
                      >
                       
                        <div className="col-12" style={{ padding: 0 }}>
                          <h2
                            style={{
                              color: "#055646",
                              fontWeight: 600,
                              fontSize: "20px",
                              lineHeight: "24px",
                            }}
                          >
                            <span style={{ borderBottom: "2px solid #055646" }}>
                              {isElec
                                ? elecNameMap[grd.elec_id]
                                  ? elecNameMap[grd.elec_id]
                                  : "Core Track"
                                : ""}
                            </span>
                      
                          </h2>

                          <span className="my-grade">
                            {!isElec && !grd.elec_id && "My Grades"}
                          </span>
                        </div>
                        <hr />

                        <div className="flexRow">
                          <table
                            className="table table-bordered gradeTooltip"
                            style={{ minWidth: "700px" }}
                          >
                            <thead className="th-bg-clr">
                              <tr>
                                <th className="t-center th-fnt">Category</th>
                                <th className="t-center th-fnt">
                                  Marks Scored
                                </th>
                                <th className="t-center th-fnt">
                                  Total Marks{" "}
                                </th>
                                {showPlcStatus && placementEnable && (
                                  <th className="t-center th-fnt">
                                    Placement Eligibility Cutoff &nbsp;
                                    <TooltipItem
                                      style={{ backgroundColor: "none" }}
                                      id={"namePopup1" + index}
                                      batch="Sum of scores of all the assessment  should be greater than or equal to the Placement cut off score to be eligible for placement."
                                    />
                                  </th>
                                )}
                                {showPassMarks && !placementEnable && (
                                  <th className="t-center th-fnt">
                                    Passing Marks
                                  </th>
                                )}
                                {showPassMarks &&
                                  placementEnable &&
                                  !showPlcStatus && (
                                    <th className="t-center th-fnt">
                                      Passing Marks
                                    </th>
                                  )}
                                {showWeightage && (
                                  <th className="t-center th-fnt">
                                    Assigned Weightage %
                                  </th>
                                )}
                                {showWeightage && (
                                  <th className="t-center th-fnt">
                                    Weighted % Scored
                                  </th>
                                )}
                                {placementEnable && showPlcStatus && (
                                  <th className="t-center th-fnt">
                                    Placement Status
                                  </th>
                                )}
                                {!placementEnable && showContentStatus && (
                                  <th className="t-center th-fnt">Status</th>
                                )}
                                {placementEnable &&
                                  showContentStatus &&
                                  !showPlcStatus && (
                                    <th className="t-center th-fnt">Status</th>
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {grd.catg &&
                                grd.catg.map((category, index) => {
                                  return (
                                    <Fragment key={`${index}${category.name}`}>
                                      {category.dids &&
                                        category.dids.length > 0 && (
                                          <>
                                            {" "}
                                            <tr
                                              style={{ cursor: "pointer" }}
                                              // onClick={() =>
                                              //   handleClick(
                                              //     index,
                                              //     `${index}${category.name}`
                                              //   )
                                              // }
                                            >
                                              <td className="bg-white-clr tr-fnt">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    flexDirection: "row",
                                                  }}
                                                >
                                                  {" "}
                                                  <div>
                                                    {category.name
                                                      ? category.name
                                                      : "-"}
                                                  </div>{" "}
                                                  <div>
                                                    {" "}
                                                    <img
                                                      src={
                                                        collapseRow[
                                                          `${index}${category.name}`
                                                        ]
                                                          ? "https://webcdn.imarticus.org/Pegasus/uparrow.svg"
                                                          : "https://cdn.pegasus.imarticus.org/Gradebook/dropdown.svg"
                                                      }
                                                    ></img>
                                                  </div>
                                                </div>

                                              </td>

                                              <td className="bg-white-clr tr-fnt">
                                                {category.marks_scored || category.marks_scored == 0
                                                  ? category.marks_scored
                                                  : "-"}
                                              </td>
                                              <td className="bg-white-clr tr-fnt">
                                                {category.total_marks || category.total_marks == 0
                                                  ? category.total_marks
                                                  : "-"}
                                              </td>
                                              {showPlcStatus &&
                                                placementEnable && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.plac_cutoff || category.plac_cutoff == 0
                                                      ? category.plac_cutoff
                                                      : "-"}
                                                  </td>
                                                )}
                                              {showPassMarks &&
                                                !placementEnable && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.pass_marks || category.pass_marks == 0
                                                      ? category.pass_marks
                                                      : "-"}
                                                  </td>
                                                )}
                                              {showPassMarks &&
                                                placementEnable &&
                                                !showPlcStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    -
                                                  </td>
                                                )}
                                              {showWeightage && (
                                                <td className="bg-white-clr tr-fnt">
                                                  {category.weight || category.weight == 0
                                                    ? category.weight
                                                    : "-"}
                                                </td>
                                              )}
                                              {showWeightage && (
                                                <td className="bg-white-clr tr-fnt">
                                                  {category.weightedPView || category.weightedPView == 0
                                                    ? category.weightedPView
                                                    : "-"}
                                                </td>
                                              )}

                                              {placementEnable &&
                                                showPlcStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.status
                                                      ? category.status
                                                      : "-"}
                                                  </td>
                                                )}
                                              {!placementEnable &&
                                                showContentStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.all_cagetory_content_pass_status
                                                      ? category.all_cagetory_content_pass_status
                                                      : "-"}
                                                  </td>
                                                )}
                                              {placementEnable &&
                                                showContentStatus &&
                                                !showPlcStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.all_cagetory_content_pass_status
                                                      ? category.all_cagetory_content_pass_status
                                                      : "-"}
                                                  </td>
                                                )}
                                            </tr>
                                            {collapseRow[
                                              `${index}${category.name}`
                                            ] && category.dids ? (
                                              <>
                                                <tr>
                                                  <td
                                                    className="bg-blue-clr"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: 600,
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    My Assessments
                                                  </td>
                                                  <td
                                                    className="bg-blue-clr"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: 600,
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Marks Scored
                                                  </td>
                                                  <td
                                                    className="bg-blue-clr"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: 600,
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Total Marks
                                                  </td>
                                                  {showPassMarks && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Pass Marks
                                                    </td>
                                                  )}
                                                  {showWeightage && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Assigned Weight%
                                                    </td>
                                                  )}
                                                  {showWeightage && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Weighted % Scored
                                                    </td>
                                                  )}

                                                  {showContentStatus && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Assessment Status
                                                    </td>
                                                  )}
                                                </tr>
                                                {category.dids.map((did) => {
                                                  return (
                                                    <tr>
                                                      <td className="bg-blue-clr tr-fnt">
                                                        {did.name
                                                          ? did.name
                                                          : "-"}
                                                      </td>
                                                      {/* <td className="bg-blue-clr tr-fnt">
                                                  {did.marks ? did.marks:'-'}
                                                </td> */}
                                                      <td className="bg-blue-clr tr-fnt">
                                                        {/* {did.status
                                                          ? did.status === 1
                                                            ? `${did.marks}`
                                                            : did.status === 2
                                                              ? "Under Evaluation"
                                                              : "Absent"
                                                          : did.marks !== "-"
                                                            ? `${did.marks}`
                                                            : "Absent"} */}
                                                        {did.marks || did.marks == 0
                                                          ? did.marks
                                                          : "-"}
                                                      </td>
                                                      <td className="bg-blue-clr tr-fnt">
                                                        {did.total_marks || did.total_marks == 0
                                                          ? did.total_marks
                                                          : "-"}
                                                      </td>

                                                      {showPassMarks && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.pasmrk || did.pasmrk == 0
                                                            ? did.pasmrk
                                                            : "-"}
                                                        </td>
                                                      )}
                                                      {showWeightage && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.weight || did.weight == 0
                                                            ? did.weight
                                                            : "-"}
                                                        </td>
                                                      )}
                                                      {showWeightage && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.weight || did.weight == 0
                                                            ? did.weightedPView
                                                            : "-"}
                                                        </td>
                                                      )}

                                                      {showContentStatus && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.showStatus
                                                            ? did.showStatus
                                                            : "-"}
                                                        </td>
                                                      )}
                                                    </tr>
                                                  );
                                                })}
                                              </>
                                            ) : null}
                                          </>
                                        )}
                                    </Fragment>
                                  );
                                })}
                              {/* Render assignment data */}

                              <tr>
                                <th
                                  className="t-center th-fnt"
                                  colSpan={columnSpan} // ----------------------
                                  style={{ textAlign: "left" }}
                                >
                                  Total Grade (%)
                                </th>
                                <th
                                  className="t-center th-fnt total"
                                  colSpan="1"
                                  style={{ position: "relative" }}
                                >
                                  {grdTotalPerc[grd.elec_id]
                                    ? grdTotalPerc[grd.elec_id].toFixed(2)
                                    : grdTotalPerc["FOUNDATION"]
                                    ? grdTotalPerc["FOUNDATION"].toFixed(2)
                                    : "-"}
                                  % &nbsp;&nbsp;
                                  {totalWeightAssignedP < 100 && (
                                    <TooltipItem
                                      id="namePopupGrade2"
                                      batch=" You will be able to see your final
                                 Grade for the course once all your
                                 assessment scores have been
                                 published."
                                    />
                                  )}
                                </th>
                              </tr>
                              {showPlcStatus &&
                                placementEnable &&
                                allAssessmentPublished && (
                                  <>
                                    {" "}
                                    <tr style={{ backgroundColor: "white" }}>
                                      <th
                                        className="t-center th-fnt"
                                        colSpan={
                                          // showWeightage &&
                                          // showPassMarks &&
                                          // placementEnable
                                          //   ? 5
                                          //   : (!showWeightage && showPassMarks) ||
                                          //     (showWeightage && !showPassMarks)
                                          //   ? 4
                                          //   : placementEnable
                                          //   ? 3
                                          //   : 2
                                          columnSpan
                                        } // ----------------------
                                        style={{
                                          textAlign: "left",
                                          weight: 60,
                                          size: "20px",
                                        }}
                                      >
                                        Placement Status
                                      </th>
                                      <th
                                        className="t-center th-fnt total"
                                        colSpan="1"
                                        style={{ position: "relative" }}
                                      >
                                        {/* {grdPlsStatus[grd.elec_id]
                                    ? (grdPlsStatus[grd.elec_id] ? 'PASS' :'FAIL')
                                    : grdPlsStatus["FOUNDATION"]
                                    ? (grdPlsStatus["FOUNDATION"]?'PASS' :'FAIL')
                                    : "-"} */}
                                        {grd.elec_id &&
                                          (grdPlsStatus[grd.elec_id]
                                            ? plcPassStatusText
                                            : plcFailStatusText)}
                                        {!grd.elec_id &&
                                          (grdPlsStatus["FOUNDATION"]
                                            ? plcPassStatusText
                                            : plcFailStatusText)}{" "}
                                      </th>
                                    </tr>
                                    <tr style={{ backgroundColor: "white" }}>
                                      <th
                                        className="t-center th-fnt"
                                        colSpan={
                                          // showWeightage && showPassMarks
                                          //   ? 6
                                          //   : (!showWeightage && showPassMarks) ||
                                          //     (showWeightage && !showPassMarks)
                                          //   ? 5
                                          //   : 4
                                          columnSpan + 1
                                        } // ----------------------
                                        style={{ textAlign: "left" }}
                                      >
                                        {" "}
                                        {grd.elec_id &&
                                          (grdPlsStatus[grd.elec_id]
                                            ? `${showPlcPassStatus}`
                                            : `${showPlcFailStatus}`)}
                                        {!grd.elec_id &&
                                          (grdPlsStatus["FOUNDATION"]
                                            ? `${showPlcPassStatus}`
                                            : `${showPlcFailStatus}`)}
                                      </th>
                                    </tr>
                                  </>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                     
                   
                        
                    </div>
                  )}
                </div>
              );
            })}

            {grddata && grddata.contentElecMap &&
              !enableCategory &&
              Object.keys(grddata.contentElecMap).map((grd, index) => {
                return (
                  <div key={index}>
                    {grddata.contentElecMap[grd] && grddata.contentElecMap[grd].length == 0 ? (
                      <div className="rvm-v1-cont-ctnr bottomRowGradebook">
                        <div
                          className="row no-margin gradeBlock"
                          style={{
                            display: 'block',
                            width: '100vw',
                            paddingTop: '8vh',
                            flex: '0 1 100vw',
                            maxWidth: '100vw',
                          }}
                        >
                          <div className="col-12" style={{ padding: 0 }}>
                            <h2
                              style={{
                                color: '#055646',
                                fontWeight: 600,
                                fontSize: '20px',
                                lineHeight: '24px',
                              }}
                            >
                              {/* {elecNameMap[grd] &&  <> <span style={{ borderBottom: "2px solid #055646" }}>
                           
                           </span>
                           </>} */}
                              <span className="my-grade">{'My Grades'}</span>
                            </h2>
                          </div>
                          <hr />
                          <div style={{ textAlign: 'center' }}>
                            <img
                              src="http://cdn.pegasus.imarticus.org/images/gradebook_empty.svg"
                              alt="Empty Gradebook"
                            />
                            <br />
                            <br />
                            <span>
                              There are no quizzes and assignments to show at this
                              moment.
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className={`rvm-v1-cont-ctnr bottomRowGradebook`}>

                          <div
                            className="row no-margin gradeBlock gradeBlockPadding"
                            style={{ display: 'block' }}
                          >
                            <div className="col-12" style={{ padding: 0 }}>
                              <h2
                                style={{
                                  color: '#055646',
                                  fontWeight: 600,
                                  fontSize: '20px',
                                  lineHeight: '24px',
                                }}
                              >
                                <span
                                  style={{ borderBottom: '2px solid #055646' }}
                                >
                                  {isElec
                                    ? elecNameMap[grd]
                                      ? elecNameMap[grd]
                                      : 'Core Track'
                                    : ''}
                                </span>
                              </h2>

                              {!grddata.elec_enable && (
                                <span className="my-grade">{'My Grades'}</span>
                              )}
                            </div>
                            <hr />

                            <div className="flexRow">
                              <table
                                className="table table-bordered gradeTooltip"
                                style={{ minWidth: '700px' }}
                              >
                                <thead className="th-bg-clr">
                                  <tr>
                                    <th className="t-center th-fnt">
                                      Assessment/Quiz
                                    </th>
                                    <th className="t-center th-fnt">
                                      Actual Marks
                                    </th>
                                    <th className="t-center th-fnt">
                                      Total Marks
                                    </th>

                                    {showWeightage && (
                                      <th className="t-center th-fnt">
                                        Assigned Weightage%
                                      </th>
                                    )}
                                    {showPassMarks && (
                                      <th className="t-center th-fnt">
                                        Passing Marks
                                      </th>
                                    )}
                                    {showWeightage && (
                                      <th className="t-center th-fnt">
                                        Weighted % Scored

                                      </th>
                                    )}
                                    {showContentStatus && <th className="t-center th-fnt">  Assessment Status</th>}

                                  </tr>
                                </thead>
                                <tbody>
                                  {/* Render assignment data */}
                                  {grddata.contentElecMap[grd].map((ag) => (
                                    <tr key={ag.nm}>
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.name ? ag.name : '-'}
                                      </td>
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.marks || ag.marks == 0 ? ag.marks : "-"}
                                      </td>
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.total_marks || ag.total_marks == 0? ag.total_marks : ag.maxmrk}
                                      </td>

                                      {showWeightage && (
                                        <td className="bg-white-clr tr-fnt">
                                          {ag.weight || ag.weight == 0 ? ag.weight : '-'}%
                                        </td>
                                      )}
                                      {showPassMarks && (
                                        <td className="bg-white-clr tr-fnt">
                                          {ag.pasmrk || ag.pasmrk == 0 ? ag.pasmrk : '-'}
                                        </td>
                                      )}
                                      {showWeightage && (
                                        <td className="bg-white-clr tr-fnt">
                                          {ag.weightedPView || ag.weightedPView == 0 ? ag.weightedPView : '-'}%
                                        </td>
                                      )}
                                      {showContentStatus && <td className="bg-white-clr tr-fnt">
                                      {ag.showStatus || ag.showStatus == 0 ? ag.showStatus : "-"}

                                      </td>}
                                    </tr>
                                  ))}

                                  <tr>
                                    <th
                                      className="t-center th-fnt"
                                      // colSpan={showWeightage ? "3" : "2"}
                                      colSpan={showContentStatus ?
                                        showWeightage && showPassMarks
                                          ? 6
                                          : showWeightage && !showPassMarks
                                            ? 5
                                            : !showWeightage && showPassMarks
                                              ? 4
                                              : 3 :
                                        showWeightage && showPassMarks
                                          ? 5
                                          : showWeightage && !showPassMarks
                                            ? 4
                                            : !showWeightage && showPassMarks
                                              ? 3 : 2
                                      }// ----------------------
                                      style={{ textAlign: 'left' }}
                                    >
                                      Total Grade (%)
                                    </th>
                                    <th
                                      className="t-center  total"
                                      colSpan="1"
                                      style={{ position: 'relative' }}
                                    >
                                       {grdTotalPerc[grd]
                                      ? grdTotalPerc[grd].toFixed(2)
                                      : "-"} %&nbsp;&nbsp;
                                      {/* {totalWeightAssignedP < 100 && (
                                        <TooltipItem
                                          id="namePopupGrade25"
                                          batch=" You will be able to see your final
                                    Grade for the course once all your
                                    assessment scores have been
                                    published."
                                        />
                                      )} */}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}

            <div>
              You will be able to see the final Grades for the course once all
              the assessment scores have been published.
            </div>

          </ModalBody>
          <ModalFooter>
            <Button
              color="red"
              onClick={(e) => { e.stopPropagation(); setOpenGradebookModal(false) }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
    </>
    </div>
  )

}


export default ManageLearners